import React, { useContext, useState } from 'react';
import { Button, Link } from "@material-ui/core";
import { deleteProfile } from "../../services/user-data-service";
import ProfileContext from "../../context/profile-context";

export default function UserAccount() {
  const { state } = useContext(ProfileContext);
  const { id } = state;
  const [isDeleting, setIsDeleting] = useState(false);

  return (
    <section id="user-account">
      <div className="textFormControl">
        <h3>Kontoinnstillinger</h3>
        <p>Les om hva vi lagrer og hva du kan forvente av tjenesten i <Link
            href="https://www.arv.no/vilkar" target="_blank">brukeravtalen</Link> og <Link
            href="https://www.arv.no/personvernsvilkar" target="_blank">personvernerklæringen</Link>.</p>
      </div>
      <div className="textFormControl">
        <hr/>
      </div>
      <div className="textFormControl">
        <p>Velger du å slette konto vil all informasjon om deg og ditt testament fjernes og du kan ikke lenger logge det inn i tjenesten. </p>
      </div>

      <div className="formControl">
        {
          isDeleting === false && (
            <Button className="secondaryButton" onClick={() => setIsDeleting(true)}>
              Slett konto
            </Button>
          )
        }
        {
          isDeleting && (
            <>
              <h3>Er du helt sikker på at du ønsker å fjerne all data?</h3>
              <Button className="safeButton" onClick={() => setIsDeleting(false)}>Nei</Button>
              <Button className="alertButton" onClick={onDeleteAccount}>Ja</Button>
            </>
          )
        }
      </div>
    </section>
  );

  async function onDeleteAccount() {
    await deleteProfile({ id }); // Todo create reducer and use dispatch({type: DELETE_PROFILE, data: { id }})
    // )
    window.location.href = 'https://www.arv.no'; // Redirect out of site.
  }
}
