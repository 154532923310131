import React, { useReducer } from "react";
import { createMuiTheme, MuiThemeProvider } from "@material-ui/core/styles";
import { Route, BrowserRouter as Router } from "react-router-dom";
import FormPage from "./page/form-page";
import MinsidePage from "./page/minside-page";
import UIContext, { uiReducer, uiState } from "./context/ui-context";
import "./App.scss";
import AuditPage from "./page/audit-page";
import TestamentTypePage from "./page/testament-type-page";
import StatisticsPage from "./page/statistics-page";
import AuthenticatedRoute from "./components/authenticated-route/AuthenticatedRoute";
import ScreeningPage from "./page/screening-page";
import OrganisationStatisticsPage from "./page/org-statistics-page";
import PrintPage from "./page/print-page";

const theme = createMuiTheme({
  typography: {
    useNextVariants: true,
  },
  palette: {
    primary: {
      main: "#55338A",
    },
    secondary: {
      main: "#511e7e",
    },
  },
  checkboxLabel: {
    fontSize: "16px",
  },
});

export default function App() {
  const [state, dispatch] = useReducer(uiReducer, uiState);
  return (
    <UIContext.Provider value={{ state, dispatch }}>
      <MuiThemeProvider theme={theme}>
        <Router>
          <div className="app">
            <Route
              path="/form"
              component={() => (
                <AuthenticatedRoute>
                  <FormPage />
                </AuthenticatedRoute>
              )}
            />
            <Route
              exact
              path="/skriv"
              component={() => (
                <AuthenticatedRoute>
                  <FormPage />
                </AuthenticatedRoute>
              )}
            />
            <Route
              path="/skriv/org"
              component={() => (
                <AuthenticatedRoute>
                  <FormPage />
                </AuthenticatedRoute>
              )}
            />
            <Route
              exact
              path="/skriv/:testamentId"
              component={() => (
                <AuthenticatedRoute>
                  <FormPage />
                </AuthenticatedRoute>
              )}
            />
            <Route
              exact
              path="/gjensidig"
              component={() => (
                <AuthenticatedRoute>
                  <FormPage />
                </AuthenticatedRoute>
              )}
            />
            <Route
              path="/gjensidig/org"
              component={() => (
                <AuthenticatedRoute>
                  <FormPage />
                </AuthenticatedRoute>
              )}
            />
            <Route
              exact
              path="/gjensidig/:testamentId"
              component={() => (
                <AuthenticatedRoute>
                  <FormPage />
                </AuthenticatedRoute>
              )}
            />
            <Route
              exact
              path="/gjensidig-testament/:testamentId"
              component={() => (
                <AuthenticatedRoute>
                  <FormPage />
                </AuthenticatedRoute>
              )}
            />
            <Route
              exact
              path="/skriv-fremtidsfullmakt"
              component={() => (
                <AuthenticatedRoute>
                  <FormPage />
                </AuthenticatedRoute>
              )}
            />
            <Route
              exact
              path="/skriv-fremtidsfullmakt/:testamentId"
              component={() => (
                <AuthenticatedRoute>
                  <FormPage />
                </AuthenticatedRoute>
              )}
            />
            <Route
              exact
              path="/fremtidsfullmakt/:testamentId"
              component={() => (
                <AuthenticatedRoute>
                  <FormPage />
                </AuthenticatedRoute>
              )}
            />
            <Route
              exact
              path="/skriv-gjensidig-fremtidsfullmakt"
              component={() => (
                <AuthenticatedRoute>
                  <FormPage />
                </AuthenticatedRoute>
              )}
            />
            <Route
              exact
              path="/skriv-gjensidig-fremtidsfullmakt/:testamentId"
              component={() => (
                <AuthenticatedRoute>
                  <FormPage />
                </AuthenticatedRoute>
              )}
            />
            <Route
              exact
              path="/gjensidig-fremtidsfullmakt/:testamentId"
              component={() => (
                <AuthenticatedRoute>
                  <FormPage />
                </AuthenticatedRoute>
              )}
            />
            <Route
              exact
              path="/testament/:testamentId"
              component={() => (
                <AuthenticatedRoute>
                  <FormPage />
                </AuthenticatedRoute>
              )}
            />
            <Route
              exact
              path="/minside"
              component={() => (
                <AuthenticatedRoute>
                  <MinsidePage />
                </AuthenticatedRoute>
              )}
            />
            <Route
              path="/testament-type"
              component={() => (
                <AuthenticatedRoute>
                  <TestamentTypePage />
                </AuthenticatedRoute>
              )}
            />
            <Route
              path="/kvalitetssikring/:testamentId"
              component={() => (
                <AuthenticatedRoute>
                  <AuditPage />
                </AuthenticatedRoute>
              )}
            />
            <Route exact path="/trenger-du-testament" component={() => <ScreeningPage />} />
            <Route exact path="/trenger-du-testament/resultat" component={() => <ScreeningPage isResults={true} />} />
            <Route
              exact
              path="/stats"
              component={() => (
                <AuthenticatedRoute>
                  <StatisticsPage />
                </AuthenticatedRoute>
              )}
            />
            <Route
              path="/print/:printId"
              component={() => (
                <AuthenticatedRoute>
                  <PrintPage />
                </AuthenticatedRoute>
              )}
            />
            <Route
              exact
              path="/org-statistikk"
              component={() => (
                <AuthenticatedRoute>
                  <OrganisationStatisticsPage />
                </AuthenticatedRoute>
              )}
            />
            <Route
              path="/org"
              component={() => (
                <AuthenticatedRoute>
                  <TestamentTypePage />
                </AuthenticatedRoute>
              )}
            />
            <Route
              exact
              path="/"
              component={() => (
                <AuthenticatedRoute>
                  <TestamentTypePage />
                </AuthenticatedRoute>
              )}
            />
          </div>
        </Router>
      </MuiThemeProvider>
    </UIContext.Provider>
  );
}
