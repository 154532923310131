import React, { useContext } from "react";
import UIContext from "../../../../context/ui-context";
import ENUMS from "../../../../enums";
import { getAnswerById, isTrueByIdAndAnswerValue } from "../../../../util/answers/answers-helper";
import { RECIPROCAL_LIFEWILL_QUESTION_IDS, RECIPROCAL_LIFEWILL_VALUE_IDS } from "../QUESTION_MAP";
import formatCurrency from "../../../../util/format-currency";

export default function PreviewReciprocalLifeWill({ answers }) {
  const {
    state: { mode },
  } = useContext(UIContext);
  const className = mode === ENUMS.UI_STATE.FORM ? "hidden-sm" : "";

  const { person = {}, partner = {} } = getAnswerById(RECIPROCAL_LIFEWILL_QUESTION_IDS.USER, answers) || {};
  const { name = null, identification = "" } = person;
  const { name: partner_name = null, identification: partner_identification = "" } = partner;

  const proxyAnswers = getAnswerById(RECIPROCAL_LIFEWILL_QUESTION_IDS.PROXY_GROUP, answers) || [];
  const proxy = {
    name: getAnswerById(RECIPROCAL_LIFEWILL_QUESTION_IDS.PROXY_NAME, proxyAnswers),
    identification: getAnswerById(RECIPROCAL_LIFEWILL_QUESTION_IDS.PROXY_IDENTIFICATION, proxyAnswers),
    relation: getAnswerById(RECIPROCAL_LIFEWILL_QUESTION_IDS.PROXY_RELATION, proxyAnswers),
  };

  const reverveProxyAnswers = getAnswerById(RECIPROCAL_LIFEWILL_QUESTION_IDS.RESERVE_PROXY_GROUP, answers) || [];
  const reserveProxy = {
    yes: getAnswerById(RECIPROCAL_LIFEWILL_QUESTION_IDS.RESERVE_PROXY, answers),
    name: getAnswerById(RECIPROCAL_LIFEWILL_QUESTION_IDS.RESERVE_PROXY_NAME, reverveProxyAnswers),
    identification: getAnswerById(RECIPROCAL_LIFEWILL_QUESTION_IDS.RESERVE_PROXY_IDENTIFICATION, reverveProxyAnswers),
    relation: getAnswerById(RECIPROCAL_LIFEWILL_QUESTION_IDS.RESERVE_PROXY_RELATION, reverveProxyAnswers),
  };

  const input = {
    living: getAnswerById(RECIPROCAL_LIFEWILL_QUESTION_IDS.LIVING_INPUT, answers),
    gifts: getAnswerById(RECIPROCAL_LIFEWILL_QUESTION_IDS.GIFTS_INPUT, answers),
    inheritance: getAnswerById(RECIPROCAL_LIFEWILL_QUESTION_IDS.INHERITANCE_INPUT, answers),
    some: isTrueByIdAndAnswerValue(RECIPROCAL_LIFEWILL_QUESTION_IDS.SOSIAL_MEDIA, RECIPROCAL_LIFEWILL_VALUE_IDS.SOSIAL_MEDIA_YES, answers),
    other: getAnswerById(RECIPROCAL_LIFEWILL_QUESTION_IDS.OTHER_INPUT, answers),
  };

  const donations = getAnswerById(RECIPROCAL_LIFEWILL_QUESTION_IDS.DONATIONS, answers);
  const relation = getAnswerById(RECIPROCAL_LIFEWILL_QUESTION_IDS.SIVILSTATUS, answers);
  const hasChildern = getAnswerById(RECIPROCAL_LIFEWILL_QUESTION_IDS.HAS_CHILDREN, answers);

  return (
    <div className={`section testament-page ${className}`}>
      <div className="sectionContent" onmousedown='return false;' onselectstart='return false;'>
        <div className="will life-will-draft">
          <div className="preview-logo">
            <div className="will-identity">
              <img src="/images/arv-logo.svg" alt="arv.no" className="logo" />
              <div className="will-logo-text">arv.no</div>
            </div>
          </div>
          <h2>
            {name} og {partner_name}s gjensidige fremtidsfullmakt
          </h2>
          <p>
            Vi, {name} ({identification}) og {partner_name} ({partner_identification}), gir herved hverandre, heretter kalt fullmektigen,
            fullmakt til å handle på hverandres vegne og ta beslutninger når vi ikke lenger er i stand til å gjøre det selv.
          </p>

          {reserveProxy.yes && (
            <p>
              {reserveProxy.name} ({reserveProxy.identification}) skal være fullmektig dersom {proxy.name} ikke lenger kan utføre sine oppgaver.
            </p>
          )}

          <h3>Fullmakten trer i kraft</h3>
          <p>
            Fullmakten gjelder fra det tidspunktet en av oss ikke lenger kan ivareta egne interesser, heretter kalt den andre. Det skal være
            bekreftet med erklæring fra lege. Fullmektigen har rett til å be om erklæring fra lege angående den andres helsetilstand.
          </p>

          <h3>Informasjon til oss og våre nære</h3>
          <p>
            Når fremtidsfullmakten slår inn, skal fullmektigen informere den andre og våre nærmeste, dvs.{" "}
            {getRelationText(relation, hasChildern === RECIPROCAL_LIFEWILL_VALUE_IDS.HAS_CHILDREN)}. Vi skal sammen gå gjennom innholdet i denne
            fullmakten og hva det betyr i praksis.
          </p>

          <h3>Fullmektigens oppgaver</h3>
          <p>Fullmektigen skal handle i den andres beste interesse, og i samsvar med dennes ønsker og verdier så langt som mulig.</p>
          <p>Fullmektigen skal så langt det lar seg gjøre høre med den andre før det tas viktige beslutninger på vegne av den andre.</p>

          <h4>Økonomiske forhold</h4>
          <p>Fullmektigen skal ivareta den andres økonomiske interesser. Det inkluderer, men er ikke begrenset til følgende:</p>
          <ul>
            <li>Betale den andres utgifter</li>
            <li>Håndtere den andres gjeld</li>
            {isTrueByIdAndAnswerValue(RECIPROCAL_LIFEWILL_QUESTION_IDS.ADMIN_INSURANCE, true, answers) && (
              <li>Administrere den andres forsikringsavtaler</li>
            )}
            {isTrueByIdAndAnswerValue(RECIPROCAL_LIFEWILL_QUESTION_IDS.ADMIN_ECONOMIC_PROPERTY, true, answers) && (
              <li>Håndtere den andres eventuelle andre finansielle eiendeler</li>
            )}
            {isTrueByIdAndAnswerValue(RECIPROCAL_LIFEWILL_QUESTION_IDS.ADMIN_SELL, true, answers) && (
              <li>Selge løsøre den andre ikke lenger trenger</li>
            )}
            {isTrueByIdAndAnswerValue(RECIPROCAL_LIFEWILL_QUESTION_IDS.ADMIN_SPENDING, true, answers) && (
              <li>Sørge for at den andre har nødvendige penger til personlige forbruk</li>
            )}
          </ul>

          <p>{getAnswerById(RECIPROCAL_LIFEWILL_QUESTION_IDS.ECONOMIC_INPUT, answers)}</p>

          {input.inheritance && (
            <>
              <h5>Forskudd på arv</h5>
              <p>
                Vi ønsker at arvinger skal få forskudd på arv. Fordelingen av forskuddet følger det som er oppgitt i eventuelt testament. Dersom
                det ikke finnes et testament så følger fordelingen av forskuddet arveloven.
              </p>
              <p>Den andre skal sitte igjen med {formatCurrency(input.inheritance)} kroner på konto etter forskuddet er utbetalt.</p>
            </>
          )}
          <h4>Bolig og annen eiendom</h4>
          <p>
            Fullmektigen har fullmakt til å ta beslutninger om den andres bolig. Det inkluderer salg eller utleie av bolig dersom det er
            hensiktsmessig. Det inntrer i så fall når den andre har annen fast sykehjemsplass eller varig botilbud og hatt dette i 6 måneder.
          </p>
          <p>
            Fullmakten gjelder alle sider ved evt. salg og utleie, og omfatter blant annet retten til å aksepter bud og skrive under på kontrakt
            og skjøte.
          </p>
          <p>{getAnswerById(RECIPROCAL_LIFEWILL_QUESTION_IDS.HOME_PROPERTIES_INPUT, answers)}</p>

          <h4>Disposisjonsrett overfor bank og offentlige instanser</h4>
          <p>
            Fullmektigen har rett til å disponere den andres bankkontoer. Det inkluderer å inngå og signere avtaler om bruk av nettbank og andre
            betalingstjenester knyttet til kontoene.
          </p>
          <p>Fullmektigen har rett til å representere den andre overfor offentlige instanser.</p>
          <p>{getAnswerById(RECIPROCAL_LIFEWILL_QUESTION_IDS.BANK_INPUT, answers)}</p>

          <h4>Personlige forhold </h4>
          <p>
            Fullmektigen kan handle på den andres vegne i alle personlige saker som gjelder ivaretakelse av den andres rettigheter og interesser
            utover økonomiske forhold. Det inkluderer, men er ikke begrenset til følgende:
          </p>
          <h5>Helse</h5>
          <p>Fullmektigen kan blant annet:</p>
          <ul>
            <li>Inngå avtaler med helse- og omsorgstjenester</li>
            <li>Delta i råd og beslutninger som vedrører den andres helse</li>
          </ul>
          <p>{getAnswerById(RECIPROCAL_LIFEWILL_QUESTION_IDS.HEALTH_INPUT, answers)}</p>
          {input.living && (
            <>
              <h5>Boforhold</h5>
              <p>{input.living}</p>
            </>
          )}

          {donations && donations.length > 0 && (
            <>
              <h5>Gaver til gode formål</h5>
              {donations.map((d) => getDonationFromValue(d.value))}
            </>
          )}

          {input.gifts && (
            <>
              <h5>Gaver til nære</h5>
              <p>{input.gifts}</p>
            </>
          )}

          {input.some && (
            <>
              <h5>Sosiale medier </h5>
              <p>Fullmektigen gis fullmakt til å administrere den andres kontoer/brukere på sosiale medier.</p>
            </>
          )}

          {input.other && (
            <>
              <h5>Andre ønsker</h5>
              <p>{input.other}</p>
            </>
          )}

          <h3>Nødvendige utgifter</h3>
          {getAnswerById(RECIPROCAL_LIFEWILL_QUESTION_IDS.PAYED_PROXY, answers) === RECIPROCAL_LIFEWILL_VALUE_IDS.PAYED_PROXY_NO ? (
            <p>
              Fullmektigen har krav på å få dekket sine nødvendige utgifter i forbindelse med utøvelse av fullmakten, men fullmektigen har ikke
              krav på å beregne seg et vederlag for sitt arbeid som fullmektig
            </p>
          ) : (
            <p>
              Min fullmektig har krav på å få dekket sine nødvendige utgifter i forbindelse med ivaretakelse av mine interesser av mine midler
              {getAnswerById(RECIPROCAL_LIFEWILL_QUESTION_IDS.PAYED_PROXY, answers) && (
                <>
                  , og skal hvert år kunne beregne seg et rimelig vederlag for sitt arbeid som fullmektig, begrenset oppad til kr{" "}
                  {getAnswerById(RECIPROCAL_LIFEWILL_QUESTION_IDS.PAYED_PROXY_AMOUNT, answers)} per år.
                </>
              )}
            </p>
          )}
          <div className="grid-x grid-padding-x">
            <div className="cell small-5 signaturePlace">
              {getSignature(answers, name)}
              {getSignature(answers, partner?.name)}
            </div>
          </div>
          <div className="grid-x grid-padding-x">
            <div className="cell small-5 left">
              <div className="signatures">
                {getWitnessSignature(answers, 0)}
                {getWitnessSignature(answers, 1)}
              </div>
            </div>
          </div>
          <div className="will-footer">
            <div className="copyright">
              <img alt="Copyright" src="/images/arv-logo.png" />
              <span>arv.no</span>
            </div>
            <div className="appname">
              <a href="https://www.sealedit.no" target="_blank" rel="noopener noreferrer">
                Sealedit
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

function renderInput(input, isAnswered = false) {
  const className = isAnswered ? "input-answered" : "input-placeholder";
  return <span className={className}>{input}</span>;
}

function getPlaceData(answers) {
  const place = getAnswerById(RECIPROCAL_LIFEWILL_QUESTION_IDS.SIGNING_PLACE, answers);
  if (!place) {
    return null;
  }
  const placeCity = getAnswerById(RECIPROCAL_LIFEWILL_QUESTION_IDS.PLACE_CITY, place);
  const placeDate = getAnswerById(RECIPROCAL_LIFEWILL_QUESTION_IDS.PLACE_DATE, place);
  return {
    placeCity,
    placeDate,
  };
}

function getSignature(answers, name) {
  const place = getPlaceData(answers) ?? {};
  const { placeCity, placeDate } = place;
  const nameUppercase = name ? name.toUpperCase() : "";
  return (
    <div className="signature">
      <dl>
        {placeCity && placeDate ? (
          <dd>
            {placeCity}, {placeDate}
          </dd>
        ) : (
          <>
            <dd>Sted/dato:</dd>
            <dd className="empty"></dd>
          </>
        )}
        <dd className="empty"></dd>
        <dd>{renderInput(name ? nameUppercase : "[NAVN]", !!name)}</dd>
      </dl>
    </div>
  );
}

function getWitnessSignature(answers, witnessIndex) {
  const witness = getAnswerById(
    witnessIndex === 0 ? RECIPROCAL_LIFEWILL_QUESTION_IDS.WITNESS_1 : RECIPROCAL_LIFEWILL_QUESTION_IDS.WITNESS_2,
    answers
  );
  let nameAnswer = "",
    addressAnswer = {},
    professionAnswer = "";
  if (witness) {
    if (witnessIndex === 0) {
      nameAnswer = getAnswerById(RECIPROCAL_LIFEWILL_QUESTION_IDS.WITNESS_NAME_1, witness);
      addressAnswer = getAnswerById(RECIPROCAL_LIFEWILL_QUESTION_IDS.WITNESS_ADDRESS_1, witness) || {};
      professionAnswer = getAnswerById(RECIPROCAL_LIFEWILL_QUESTION_IDS.WITNESS_PROFESSION_1, witness);
    } else {
      nameAnswer = getAnswerById(RECIPROCAL_LIFEWILL_QUESTION_IDS.WITNESS_NAME_2, witness);
      addressAnswer = getAnswerById(RECIPROCAL_LIFEWILL_QUESTION_IDS.WITNESS_ADDRESS_2, witness) || {};
      professionAnswer = getAnswerById(RECIPROCAL_LIFEWILL_QUESTION_IDS.WITNESS_PROFESSION_2, witness);
    }
  }
  const { street, postalCode, city } = addressAnswer;
  const nameStr = nameAnswer ? nameAnswer.toUpperCase() : "";
  return (
    <div className="signature">
      <dl>
        <dd>Sted/dato:</dd>
        <dd className="empty"></dd>
        <dd>Underskrift:</dd>
        <dd className="empty"></dd>
        {nameStr ? (
          <dd>{nameStr}</dd>
        ) : (
          <>
            <dt>Navn med blokkbokstaver:</dt>
            <dd className="empty"></dd>
          </>
        )}
        {professionAnswer ? (
          <dd>{professionAnswer}</dd>
        ) : (
          <>
            <dt>Yrke/stilling:</dt>
            <dd className="empty"></dd>
          </>
        )}
        {street || postalCode || city ? (
          <dd>
            {street ?? ""},{" "}
            <span>
              {postalCode ?? ""} {city ?? ""}
            </span>
          </dd>
        ) : (
          <>
            <dt>Adresse:</dt>
            <dd className="empty"></dd>
          </>
        )}
      </dl>
    </div>
  );
}

function getDonationFromValue(donationValue) {
  if (donationValue) {
    var donation = {
      orgName: donationValue[0]?.value?.value?.Name,
      orgNumber: donationValue[0]?.value?.value?.Value,
      type: donationValue[1]?.value.id,
      sum: donationValue[2]?.value,
    };
  } else {
    return;
  }

  if (donation.type === RECIPROCAL_LIFEWILL_VALUE_IDS.DONATION_TYPE_ONCE) {
    return (
      <p key={donation.orgNumber}>
        {donation.orgName} ({donation.orgNumber}) skal motta et engangsbeløp på {formatCurrency(donation.sum)}. Beløpet utbetales når
        fremtidsfullmakten trer i kraft.
      </p>
    );
  }
  if (donation.type === RECIPROCAL_LIFEWILL_VALUE_IDS.DONATION_TYPE_YEARLY)
    return (
      <p key={donation.orgNumber}>
        {donation.orgName} ({donation.orgNumber}) skal motta {formatCurrency(donation.sum)}. Beløpet utbetales hvert år og første gang når
        fremtidsfullmakten trer i kraft.
      </p>
    );

  return (
    <p key={donation.orgNumber}>
      {donation.orgName} ({donation.orgNumber}) skal motta {formatCurrency(donation.sum)}.
    </p>
  );
}

function getRelationText(sivilStatus, hasChildren) {
  let text = "";

  if (hasChildren && sivilStatus !== RECIPROCAL_LIFEWILL_VALUE_IDS.PARTNER && sivilStatus !== RECIPROCAL_LIFEWILL_VALUE_IDS.MARRIED) {
    return "våre barn";
  }

  if (sivilStatus === RECIPROCAL_LIFEWILL_VALUE_IDS.PARTNER) {
    text = "min samboer";
  }

  if (sivilStatus === RECIPROCAL_LIFEWILL_VALUE_IDS.MARRIED) {
    text = "min ektefelle";
  }

  if (hasChildren) {
    text = text + " og våre barn";
  }
  return text;
}
