import React, { useContext, useState } from "react";
import { Button, TextField } from "@material-ui/core";
import ProfileContext from "../../context/profile-context";
import ENUMS from "../../enums";
import { postProfileData } from "../../services/user-data-service";
import Snackbar from "@material-ui/core/Snackbar/Snackbar";
import { Alert } from "@material-ui/lab";

export default function Profile() {
  const { state: profile, dispatch } = useContext(ProfileContext);
  const [ isSaving, setIsSaving ] = useState(false);
  const [ isUpdated, setIsUpdated ] = useState(false);
  const [ error, setError ] = useState(null);
  const { id, name, email, phoneNumber, newsletter, canBeContacted } = profile;

  return (
    <section id="user-profile" className="profile">
      <div className="textFormControl">
        <h2>Din profil</h2>
      </div>
      <div className="formControls">
        <label className="questionLabel">Navn</label>
        <TextField
          id="user-name"
          required
          label=""
          name="name"
          onChange={onChangeInput}
          type="text"
          variant="outlined"
          helperText="Skriv ditt fulle navn."
          defaultValue={name}
          fullWidth
          margin="normal"
        />
        <label className="questionLabel">E-post</label>
        <TextField
          id="user-mail"
          label=""
          name="email"
          onChange={onChangeInput}
          defaultValue={email}
          type="text"
          variant="outlined"
          fullWidth
          margin="normal"
        />

        <label className="questionLabel">Telefon</label>
        <TextField
          id="user-phone"
          label=""
          name="phoneNumber"
          onChange={onChangeInput}
          defaultValue={phoneNumber}
          type="tel"
          variant="outlined"
          className="textField"
          margin="normal"
        />
      </div>

      <div className="formControl">
        {
          isSaving && (
            <div>Lagrer...</div>
          )
        }
        {
          isSaving === false && (
            <Button className="secondaryButton" onClick={updateProfile}>
              Oppdater
            </Button>
          )
        }
        {
          error !== null && (
            <div className="error">
              {error}
            </div>
          )
        }
      </div>
      <Snackbar open={isUpdated} autoHideDuration={6000} onClose={handleSuccessClose}>
        <Alert  severity="success" onClose={handleSuccessClose}>
          Profilen er oppdatert
        </Alert>
      </Snackbar>
    </section>
  );

  function onChangeInput({ target }) {
    dispatch({
      type: ENUMS.PROFILE_ACTIONS.UPDATE,
      data: {
        ...profile,
        [target.name]: target.value
      }
    });
  }

  function updateProfile() {
    setIsSaving(true);
    postProfileData({
      id,
      name,
      email,
      phoneNumber,
      newsletter,
      canBeContacted
    }).then(() => {
      setIsSaving(false);
      dispatch({
        type: ENUMS.PROFILE_ACTIONS.UPDATE,
        data: profile
      });
      setIsUpdated(true);
    }).catch((e) => {
      setIsSaving(false);
      setError(e);
    });
  }

  function handleSuccessClose() {
    setIsUpdated(false);
  }
}
