import React from "react";
import { Button } from "@material-ui/core";
import { FormContextConsumer } from "../../context/form-context";

export default function FormAction(props) {
  const { element = {} } = props;
  const { text = "", action = "", endpoint = "", size = "large", variant = "contained", className = "secondaryButton", color = "secondary" } = element;

  return (
    <FormContextConsumer>
      {({ getSubmitFn, isFormValid }) => (
        <div className="formControl">
          <Button
            size={size}
            variant={variant}
            className={className}
            color={color}
            onClick={getSubmitFn(action, endpoint)}
            // disabled={!isFormValid}
          >
            {text}
          </Button>
        </div>
      )}
    </FormContextConsumer>
  );
}
