import http, { createRequest, createUrl } from "../util/http";

const USER_ENDPOINT = "/user";

export default function getProfileData() {
    return http(
        createUrl(USER_ENDPOINT),
        createRequest()
    );
}

export function postProfileData({ name, email, phoneNumber, newsletter, canBeContacted }) {
    return http(
        createUrl(USER_ENDPOINT),
        createRequest({
            method: 'POST',
            body: JSON.stringify({
                phoneNumber,
                name,
                email,
                newsletter,
                canBeContacted
            })
        }),
        false,
        true
    )
}

export function deleteProfile({ id }) {
    return http(
        createUrl(USER_ENDPOINT),
        createRequest({
            method: 'DELETE',
            body: JSON.stringify({ id })
        })
    );
}

export function changePassword({ oldPassword, newPassword }) {
    return http(
        createUrl(`${USER_ENDPOINT}/password`),
        createRequest({
            method: 'POST',
            body: JSON.stringify({
                oldPassword,
                newPassword
            })
        })
    );
}

export function postBid(bid) {
    return http(
        createUrl(`${USER_ENDPOINT}/bid`),
        createRequest({
            method: 'POST',
            body: JSON.stringify({
                bid
            })
        })
    );
}