import React from "react";
import { Grid, TextField } from "@material-ui/core";

export default function Person(props) {
  const { id, type, answer = {}, setStateValues, questionLabel = "Navn", helperText } = props;
  const classList = {
    "spacing-xs-4": "12px",
  };

  const variant = "outlined";

  function onChange(e) {
    const validValue = (e.target.value ?? '')
      .replace(/"/gi,'')
      .replace(/'/gi, '');

    setStateValues(id, type, { ...answer, [e.target.name.replace('_', '')]: validValue });
  }

  return (
    <>
      <Grid container spacing={2} classes={classList}>
        <Grid item xs={12} sm={12} md={7}>
          <label className="questionLabel">{questionLabel}</label>
          <TextField
            fullWidth
            type="text"
            name="_name"
            onBlur={onChange}
            variant={variant}
            defaultValue={answer && answer.name}
            required
            helperText={helperText ? helperText : "Ditt fulle navn her"}
            placeholder=""
            // autoComplete='off'
          />
        </Grid>

        <Grid item xs={12} sm={5}>
          <label className="questionLabel">Fødselsnummer</label>
          <TextField
            fullWidth
            type="tel"
            inputProps={{maxLength: 11}}
            name="_identification"
            onBlur={onChange}
            variant={variant}
            defaultValue={answer && answer.identification}
            required
            helperText="11 siffer"
          />
        </Grid>
      </Grid>
    </>
  );
}
