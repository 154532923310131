import React from "react";
import Loader from "react-loader-spinner";
import './loader-spinner.scss';

import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";

export default function LoaderSpinner() {
    return (
        <div className="loader-spinner container">
            <Loader
                type="ThreeDots"
                color="#39134c"
                height={100}
                width={100}
                timeout={10000} //ca 10 secs
            />
        </div>
    );
}
