import React from "react";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import { InputLabel, FormControl } from "@material-ui/core";
import "../../App.scss";

const months = ["Januar", "Februar", "Mars", "April", "Mai", "Juni", "Juli", "August", "September", "Oktober", "Novemnber", "Desember"];

const years = [];
for (let y = 1940; y < new Date().getFullYear() + 1; y++) {
  years.push(y);
}

const styles = {
  width: "200px",
  labelWidth: 0
};

export default function DateSelector(props) {
  const { id, type, question, setStateValues, answer = {} } = props;
    const { value = new Date() } = answer;

  const handleChange = event => {
    value.setDate(1);
    if (event.target.name === "month") {
      value.setMonth(event.target.value);
    } else {
      value.setFullYear(event.target.value);
    }
    setStateValues(id, type, value);
  };

  return (
    <div className="formControl">
      <label className="questionLabel">{question}</label>
      <div>
        <FormControl variant="outlined">
          <InputLabel>Month</InputLabel>
          <Select
            style={styles}
            value={value.getMonth()}
            onChange={handleChange}
            input={<OutlinedInput labelWidth={styles.labelWidth} name="month" id="month" />}
          >
            {months.map((month, index) => (
              <MenuItem key={month} value={index}>
                {month}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <FormControl variant="outlined">
          <InputLabel>Year</InputLabel>
          <Select
            style={styles}
            value={value.getFullYear()}
            onChange={handleChange}
            input={<OutlinedInput labelWidth={styles.labelWidth} name="year" id="year" />}
          >
            {years.map(year => (
              <MenuItem key={year} value={year}>
                {year}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </div>
    </div>
  );
}
