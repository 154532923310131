import React from "react";
import { Button, TextField, FormControlLabel } from "@material-ui/core";
import FractionInput from "./fraction-input";

import "./distribution-list-rest.scss";
import { Checkbox } from "@material-ui/core";
import ENUMS from "../../../enums";
import { getFormLimitingLabel } from "../../section/preview-section/text-helper";
import { getFamilyState } from "../../../util/answers/answers-helper";
import { gDate, getGValue } from "../../../util/g-value";

const heirRelations = [ENUMS.RELATION.CHILD, ENUMS.RELATION.MARRIED];

export default function DistributionListRest({ answer, answers, id, setStateValues, linkIds, partnerDistribution, ownDistribution, sharedDistribution }) {
  const isReciprocal = partnerDistribution || ownDistribution || sharedDistribution;

  let persons = mergeAnswers();
  const sortOrder = [ENUMS.RELATION.MARRIED, ENUMS.RELATION.PARTNER, ENUMS.RELATION.CHILD, ENUMS.RELATION.PARENT, ENUMS.RELATION.SIBLING, ENUMS.RELATION.GRANDCHILDREN, ENUMS.RELATION.GREATGRANDCHILDREN, ENUMS.RELATION.OTHERFAMILY, ENUMS.RELATION.OTHER, ENUMS.RELATION.ORGANISATION];

  persons.sort((a, b) => sortOrder.indexOf(a.relation) - sortOrder.indexOf(b.relation));


  function mergeAnswers() {
    const linkedAnswres = answers?.filter((v) => linkIds?.includes(v.id));
    const peopleAnswers = linkedAnswres?.filter(v => v.contentType === ENUMS.CONTENT_TYPE.PERSON_LIST)
      ?.flat()
      .map((a) => a.value)
      .flat();

    if (ownDistribution || partnerDistribution) {
      const partnerInformation = linkedAnswres.find(v => v.contentType === ENUMS.CONTENT_TYPE.PARTNERS_INFORMATION)?.value;
      const personInformation = id === ENUMS.DISTIBUTIONOWNER.PERSON ? partnerInformation?.partner : partnerInformation?.person;

      if (personInformation) {
        const personRelation = answers.find(a => a.id === ENUMS.RELATIONSHIP_STATUS_CONTENTID)?.value == 1001 ? ENUMS.RELATION.MARRIED : ENUMS.RELATION.PARTNER;
        peopleAnswers.push({ ...personInformation, id: 1, relation: personRelation });
      }
    }

    if (sharedDistribution) { //THIS IS A HACK, but it work :/
      const partnerInformation = linkedAnswres.find(v => v.contentType === ENUMS.CONTENT_TYPE.PARTNERS_INFORMATION)?.value;
      const personInformation = partnerInformation?.person;

      if (personInformation) {
        const personRelation = answers.find(a => a.id === ENUMS.RELATIONSHIP_STATUS_CONTENTID)?.value == 1001 ? ENUMS.RELATION.MARRIED : ENUMS.RELATION.PARTNER;
        peopleAnswers.push({ ...personInformation, id: 1, name: "Lengstlevende", relation: personRelation });
      }
    }

    if (answer) {
      return peopleAnswers
        .map((peopleAnswer) => {
          const valueById = answer.value.find(({ id }) => peopleAnswer.id === id);

          if (valueById && JSON.stringify(valueById) !== JSON.stringify(peopleAnswer)) {
            return {
              ...peopleAnswer,
              value: valueById.value,
              isLimited: valueById.isLimited,
              isSeparateProperty: valueById.isSeparateProperty
            };
          }
          return peopleAnswer;
        })
        .flat();
    } else {
      return peopleAnswers;
    }
  }

  const setPersons = (personList) => {
    setStateValues(id, ENUMS.CONTENT_TYPE.DISTRIBUTION_LIST, personList);
  };

  return (
    <div className="distribution-list">{persons.length > 1 &&
      <div className="distribution-list-actions">
        {persons.some((p) => p.relation === ENUMS.RELATION.CHILD) && (
          <Button className="distribution-list-action" onClick={() => onDistributeToRelation(ENUMS.RELATION.CHILD)}>
            Prioriter barna
          </Button>
        )}
        {persons.some((p) => p.relation === ENUMS.RELATION.MARRIED) && (
          <Button className="distribution-list-action" onClick={() => onDistributeToRelation(ENUMS.RELATION.MARRIED)}>
            {id === ENUMS.DISTIBUTIONOWNER.SHARED ? "Prioriter lengslevende" : "Prioriter ektefelle"}
          </Button>
        )}
        {persons.some((p) => p.relation === ENUMS.RELATION.PARTNER) && (
          <Button className="distribution-list-action" onClick={() => onDistributeToRelation(ENUMS.RELATION.PARTNER)}>
            {id === ENUMS.DISTIBUTIONOWNER.SHARED ? "Prioriter lengslevende" : "Prioriter samboer"}
          </Button>
        )}
        <Button className="distribution-list-action" onClick={onReset}>
          Nullstill
        </Button>
      </div>
    }
      <div className="distribution-list-items">
        {persons.map(({ id, name, relation, value, isSeparateProperty = false, isLimited = false }, index) => (
          <div key={id} className="distribution-person-item">
            <div className="distribution-person-item-heir">
              <label className="questionLabel">{getHeirLabel(relation)}</label>
              <TextField
                type="text"
                fullWidth
                InputProps={{
                  readOnly: true,
                }}
                className="distribution-person-name"
                variant="outlined"
                value={name}
                helperText="Mottaker av formue" />
            </div>

            <FractionInput value={value} isLimited={isLimited} onChange={getHandleOnChange(id, value)}
              captionText={getFractionCaption(relation, isLimited)} />

            {hasLimitingRights(relation) && (
              <FormControlLabel
                className="distribution-person-item-checkbox"
                key={`${id}-${index}-0`}
                control={<Checkbox color="secondary" onChange={getHandleIsLimited(id, relation)} checked={isLimited} />}
                label={getFormLimitingLabel(relation, persons, answers, !!partnerDistribution, isReciprocal)}
              />
            )}
            {(relation !== ENUMS.RELATION.ORGANISATION && relation !== ENUMS.RELATION.MARRIED && relation !== ENUMS.RELATION.PARTNER) && (
              <FormControlLabel
                className="distribution-person-item-checkbox"
                key={`${id}-${index}-1`}
                control={<Checkbox color="secondary" onChange={getHandleIsSeparateProperty(id)}
                  checked={isSeparateProperty} />}
                label="Dette skal tilfalle arving som særeie (relevant hvis arving er gift)" 
              />
            )}
          </div>
        ))}
      </div>
    </div>
  );

  function getHeirLabel(relation) {
    switch (relation) {
      case ENUMS.RELATION.MARRIED:
        return "Ektefelle";
      case ENUMS.RELATION.PARTNER:
        return "Samboer";
      case ENUMS.RELATION.CHILD:
        return "Barn";
      default:
        return "Arving";
    }
  }

  function hasLimitingRights(relation) {
    if (heirRelations.includes(relation)) {
      return true;
    }

    if (relation === ENUMS.RELATION.PARTNER) {
      const { hasCommonChildren, partnerOverFiveYears, hasOwnChildren, hasPartnerChildren } = getFamilyState(answers, isReciprocal);

      if (ownDistribution) {
        return hasCommonChildren || (partnerOverFiveYears && hasOwnChildren);
      }
      if (partnerDistribution) {
        return hasCommonChildren || (partnerOverFiveYears && hasPartnerChildren);
      }

      return hasCommonChildren || (partnerOverFiveYears && hasOwnChildren);
    }
    return false;
  }

  function onDistributeToRelation(distributeRelation) {
    const lifePersons = persons.filter(({ relation }) => relation === distributeRelation);
    const value = lifePersons.length > 1 ? `1/${lifePersons.length}` : "Resten";

    setPersons(
      persons.map(({ id, isLimited, relation, ...rest }) => {
        const lifePerson = lifePersons.find((p) => p.id === id);
        return {
          id,
          relation,
          ...rest,
          isLimited: !lifePerson,
          value: lifePerson ? value : getValue(relation),
        };
      })
    );
  }

  function getValue(relation) {
    if (!hasLimitingRights(relation)) {
      return ""
    }
    switch (relation) {
      case ENUMS.RELATION.MARRIED:
      case ENUMS.RELATION.PARTNER:
        return "Minstearv";
      case ENUMS.RELATION.CHILD:
        return "Pliktarv";
      default:
        return "";
    }
  }

  function onReset() {
    setPersons(
      persons.map(({ ...rest }) => ({
        ...rest,
        isLimited: false,
        isIncluded: false,
        value: "",
      }))
    );
  }

  function getHandleIsSeparateProperty(id) {
    return (event) => changePersonPropery(persons, id, "isSeparateProperty", event.target.checked);
  }

  function getHandleIsLimited(id, relation) {
    return (event) => {
      const text = [
        ENUMS.RELATION.PARTNER,
        ENUMS.RELATION.MARRIED
      ].indexOf(relation) > -1 ? "Minstearv" : "Pliktarv";
      changePersonPropery(persons, id, "isLimited", event.target.checked);
      changePersonPropery(persons, id, "value", event.target.checked ? text : "");
    };
  }

  function getFractionCaption(relation, isLimited,) {
    if (!isLimited) {
      return "For eksempel 1/3 eller \"Resten\"";
    }

    const isPartner = !!partnerDistribution; // force boolean

    switch (relation) {
      case ENUMS.RELATION.MARRIED:
        const { hasCommonChildren, hasOwnChildren, hasPartnerChildren } = getFamilyState(answers, isReciprocal);
        return ((!isReciprocal && !hasCommonChildren) ||
          (!hasCommonChildren && !isPartner && !hasOwnChildren) ||
          (!hasCommonChildren && isPartner && !hasPartnerChildren)) ?
          `${getGValue(6)} per ${gDate}` : `${getGValue(4)} per ${gDate}`

      case ENUMS.RELATION.PARTNER:
        return `${getGValue(4)} per ${gDate}`;
      case ENUMS.RELATION.CHILD:
        return `${getGValue(15)} per ${gDate}`;
      default:
        return "For eksempel 1/3 eller \"Resten\"";
    }
  }

  function getHandleOnChange(id) {
    return (value) => changePersonPropery(persons, id, "value", value);
  }

  function changePersonPropery(persons, id, property, value) {
    const personIndex = getPersonIndex(persons, id);
    const person = persons[personIndex];
    person[property] = value;
    persons[personIndex] = person;
    setPersons([...persons]);
  }

  function getPersonIndex(persons, id) {
    return persons.findIndex(({ id: personId }) => personId === id);
  }
}

export function round(percentFloat, decimalPlaces = 2) {
  return Math.floor(percentFloat * Math.pow(10, decimalPlaces)) / Math.pow(10, decimalPlaces);
}

export function getTotalValue(persons) {
  const reducer = (memo, { value }) => parseFloat(value || 0) + memo;
  return persons.reduce(reducer, 0);
}
