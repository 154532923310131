import React from 'react';
import './footer.scss';

export default function Footer() {
  return (
    <footer>
      <div className="footer-inner">
        <div className="pull-right">
          Powered by <a href="https://www.sealedit.no" rel="noopener noreferrer" target="_blank">Sealedit</a>
        </div>
      </div>
    </footer>
  );
}
