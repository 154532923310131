import React, { useEffect, useReducer, useState } from 'react';
import getProfileData from "../../../services/user-data-service";
import ENUMS from "../../../enums";
import ProfileContext, { profileReducer, profileState } from "../../../context/profile-context";
import UserSettings from "../../user-settings/user-settings";

export default function ReceiptSummary() {
    const [state, dispatch] = useReducer(profileReducer, profileState);
    const [isLoading, setIsLoading] = useState(true);
    useEffect(initialDataFetch, []); // Empty array makes this call only run on mount

    if (isLoading) {
        return <>Vennligst vent, laster status...</>;
    }

    return (
        <ProfileContext.Provider value={{ state, dispatch }}>
            <UserSettings />
        </ProfileContext.Provider>
    );

    function initialDataFetch() {
        getProfileData().then((profileData) => {
            dispatch({
                type: ENUMS.PROFILE_ACTIONS.UPDATE,
                data: profileData
            });
            setIsLoading(false);
        });
    }
}
