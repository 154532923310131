import React from "react";
import { Button } from "@material-ui/core";

export default function ScreeningInfoSection(props) {
  const { className } = props;
  return (
    <div className={`section screening-page frontPage ${className}`}>
      <div className="sectionContent">
        <h1>Trenger du testament? </h1>
        <p className="ingress">Trenger du å skrive testament? Hva kan du bestemme over? Hva sier loven? Ta testamenttesten og se.</p>
        <p>
          Svar på noen få spørsmål og se om din livssituasjon og dine ønsker gjør at du bør skrive testament eller ikke. Du må være over 18 år for å skrive
          testament.
        </p>
        <div className="hidden-lg hidden-md">
          <Button size="large" variant="contained" className="primaryButton" color="secondary" onClick={handleStartClick}>
            Ta testen
          </Button>
        </div>
        <div className="screening-image-wrapper">
          <img className="screening-image" alt="Tegning av en stor familie som er glade" src="/images/arv-family.png" />
        </div>
      </div>
    </div>
  );

  function handleStartClick() {
    const { setStartPage } = props;
    setStartPage(false);
  }
}
