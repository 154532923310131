import { FormControl, MenuItem, Select } from "@material-ui/core";
import { format, getYear, isThisYear, parse } from "date-fns";
import React, { useEffect, useState } from "react";
import Header from "../components/header/header";
import LoaderSpinner from "../components/loader/loader";
import ComposedBarLineChart from "../components/statistics/composed-bar-line-chart";
import KeyValues from "../components/statistics/keyValues";
import ENUMS from "../enums";
import { getOrganisationStatisticsData } from "../services/statistics-data-service";
import formatCurrency from "../util/format-currency";

const monthSeries = ["jan", "feb", "mar", "apr", "mai", "jun", "jul", "aug", "sep", "okt", "nov", "des"];

const testamentDonationCountSeries = [
  { name: "Beløp", type: ENUMS.CHARTSERIESTYPE.BAR, color: "#4E876D", stackId: "a" },
  { name: "Andel", type: ENUMS.CHARTSERIESTYPE.BAR, color: "#373E59", stackId: "a" },
  { name: "Årlig", type: ENUMS.CHARTSERIESTYPE.BAR, color: "#EF5350", stackId: "a" },
  { name: "Aggregert", type: ENUMS.CHARTSERIESTYPE.LINE, color: "#96232F", stackId: "b" },
];

const testamentDonationSumSeries = [
  { name: "Beløp", type: ENUMS.CHARTSERIESTYPE.BAR, color: "#4E876D", stackId: "a" },
  { name: "Årlig", type: ENUMS.CHARTSERIESTYPE.BAR, color: "#EF5350", stackId: "a" },
  { name: "Aggregert", type: ENUMS.CHARTSERIESTYPE.LINE, color: "#96232F" },
];

export default function OrganisationStatisticsPage() {
  const [isLoading, setIsLoading] = useState(true);
  const [listLength, setListLength] = useState(5);
  const [organisationName, setOrganisationName] = useState("");
  const [donationData, setDonationsData] = useState([]);
  const [selectedCountYear, setSelectedCountYear] = useState(2023);
  const [selectedSumYear, setSelectedSumYear] = useState(2023);
  const [sortedRawData, setSortedRawData] = useState([]);
  const [donationCountData, setDonationCountData] = useState([]);
  const [donationSumData, setDonationSumData] = useState([]);
  const [donationKeyValues, setDonationKeyValues] = useState([]);

  useEffect(() => {
    const searchParams = new URLSearchParams(document.location.search);
    getOrganisationStatisticsData(searchParams.get("org")).then((data) => {
      if (data && data.organisationDonations && data.organisationDonations.length > 0) {
        setOrganisationName(data.name);
        setDonationsData(data.organisationDonations);

        const sortedData = transformAndSortDonationData(data.organisationDonations);
        setSortedRawData(sortedData);

        const keyValues = getKeyValues(sortedData);
        setDonationKeyValues(keyValues);

        const filteredData = filterChartDataByYear(sortedData, selectedCountYear);

        setDonationCountData(groupChartCountDataByMonth(filteredData));
        setDonationSumData(groupChartDataByMonth(filteredData));
        setIsLoading(false);
      } else {
        setDonationCountData([]);
        setDonationSumData([]);
        setIsLoading(false);
      }
    });
  }, []);

  const getKeyValues = (data) => {
    let totalDonationSum = 0;
    let totalDonationSumThisYear = 0;
    let numberOfGifts = 0;
    let numberOfGiftsThisYear = 0;

    data.forEach((d) => {
      if (d.type === ENUMS.STATSDONATIONTYPE.PROSENTAGE) {
        numberOfGifts++;

        if (isThisYear(d.date)) {
          numberOfGiftsThisYear++;
        }
      } else {
        var value = parseInt(d.value.replace(" ", ""));
        totalDonationSum = totalDonationSum + value;

        if (isThisYear(d.date)) {
          totalDonationSumThisYear = totalDonationSumThisYear + value;
        }
      }
    });

    const donations = data.filter((d) => d.type !== ENUMS.STATSDONATIONTYPE.PROSENTAGE);
    const largetsDonation = Math.max(...donations.map((d) => parseInt(d.value.replace(" ", ""))));

    return [
      { name: "Gaver totalt", value: totalDonationSum, type: ENUMS.STATSDONATIONTYPE.MONEY },
      { name: "Gaver i år", value: totalDonationSumThisYear, type: ENUMS.STATSDONATIONTYPE.MONEY },
      { name: "Andel som gave totalt (Testament)", value: numberOfGifts, type: ENUMS.STATSDONATIONTYPE.PROSENTAGE },
      { name: "Andel som gave i år (Testament)", value: numberOfGiftsThisYear, type: ENUMS.STATSDONATIONTYPE.PROSENTAGE },
      { name: "Årlig beløp totalt (Fremtidsfullmakt)", value: largetsDonation, type: ENUMS.STATSDONATIONTYPE.MONEY },
      { name: "Årlig beløp i år (Fremtidsfullmakt)", value: largetsDonation, type: ENUMS.STATSDONATIONTYPE.MONEY },
      { name: "Største gave", value: largetsDonation, type: ENUMS.STATSDONATIONTYPE.MONEY },
      {
        name: "Siste gave",
        value: donations.length > 0 ? parseInt(donations[0].value.replace(" ", "")) : 0,
        type: ENUMS.STATSDONATIONTYPE.MONEY,
      },
    ];
  };

  const transformAndSortDonationData = (data) => {
    return data
      .map((t) => {
        const date = parseDate(t.date);
        return { date: date, name: monthSeries[date.getMonth()], type: t.type, value: t.value };
      })
      .sort((a, b) => (a.date < b.date ? 1 : -1));
  };

  const filterChartDataByYear = (data, year) => {
    const filteredData = data.filter((d) => getYear(d.date) === year);
    return filteredData;
  };

  const groupChartCountDataByMonth = (data) => {
    const grouped = [];
    monthSeries.forEach((m) => (grouped[m] = { name: m, Beløp: 0, Andel: 0, Årlig: 0, Aggregert: 0 }));

    data.forEach((d) => {
      const groupItem = grouped[d.name];

      if (d.type === ENUMS.STATSDONATIONTYPE.PROSENTAGE) {
        groupItem.Andel++;
      }
      if (d.type === ENUMS.STATSDONATIONTYPE.MONEY || d.type === ENUMS.STATSDONATIONTYPE.ONCE) {
        groupItem.Beløp++;
      }
      if (d.type === ENUMS.STATSDONATIONTYPE.RECURRING) {
        groupItem.Årlig++;
      }

      grouped[d.name] = groupItem;
    });

    let Aggregert = 0;
    return Object.values(grouped).map((d) => {
      Aggregert = Aggregert + d.Beløp;
      return { ...d, Aggregert: Aggregert };
    });
  };

  const groupChartDataByMonth = (data) => {
    const grouped = [];
    monthSeries.forEach((m) => (grouped[m] = { name: m, Beløp: 0, Aggregert: 0 }));

    data
      .filter((d) => d.type !== ENUMS.STATSDONATIONTYPE.PROSENTAGE)
      .forEach((d) => {
        const groupItem = grouped[d.name];
        var value = parseInt(d.value.replace(" ", ""));
        groupItem.Beløp = groupItem.Beløp + value;

        grouped[d.name] = groupItem;
      });

    return padAggregigateValue(Object.values(grouped));
  };

  const padAggregigateValue = (data) => {
    let Aggregert = 0;
    return data.map((d) => {
      Aggregert = Aggregert + d.Beløp;
      return { ...d, Aggregert: Aggregert };
    });
  };

  const parseDate = (dateString) => {
    if (dateString.includes("/")) {
      return parse(dateString, "MM/dd/yyyy", new Date());
    } else {
      return parse(dateString, "dd.MM.yyyy", new Date());
    }
  };

  const handleCountYearChange = (event) => {
    setSelectedCountYear(event.target.value);
    const filteredData = filterChartDataByYear(sortedRawData, event.target.value);
    setDonationCountData(groupChartCountDataByMonth(filteredData));
  };

  const handleSumYearChange = (event) => {
    setSelectedSumYear(event.target.value);
    const filteredData = filterChartDataByYear(sortedRawData, event.target.value);
    setDonationSumData(groupChartDataByMonth(filteredData));
  };

  if (isLoading) {
    return (
      <>
        <Header hasPreview={false} />
        <main className="org-stats">
          <div className="statistics-loading-data">
            <LoaderSpinner />
          </div>
        </main>
      </>
    );
  }

  return (
    <>
      <Header hasPreview={false} />
      <main className="org-stats">
        <h2>{organisationName} — Testamentariske gaver</h2>

        <div className="key-value-section">
          <KeyValues data={donationKeyValues}></KeyValues>
        </div>

        <div className="graph-section">
          <div className="graph">
            <FormControl className="year-dropdown" sx={{ m: 1, minWidth: 120 }}>
              <Select
                labelId="countSelectLabel"
                id="countSelect"
                variant="outlined"
                value={selectedCountYear}
                onChange={handleCountYearChange}
                inputProps={{ "aria-label": "Without label" }}
              >
                <MenuItem value={2021}>2021</MenuItem>
                <MenuItem value={2022}>2022</MenuItem>
                <MenuItem value={2023}>2023</MenuItem>
              </Select>
            </FormControl>

            <ComposedBarLineChart
              title={"Antall med gave"}
              series={testamentDonationCountSeries}
              chartData={donationCountData}
            ></ComposedBarLineChart>
          </div>

          <div className="graph">
            <FormControl className="year-dropdown" variant="outlined" sx={{ m: 1, minWidth: 120 }}>
              <Select
                labelId="sumSelectLabel"
                id="sumSelect"
                value={selectedSumYear}
                onChange={handleSumYearChange}
                inputProps={{ "aria-label": "Without label" }}
              >
                <MenuItem value={2021}>2021</MenuItem>
                <MenuItem value={2022}>2022</MenuItem>
                <MenuItem value={2023}>2023</MenuItem>
              </Select>
            </FormControl>
            <ComposedBarLineChart title={"Sum gaver"} series={testamentDonationSumSeries} chartData={donationSumData}></ComposedBarLineChart>
          </div>
        </div>
        <div className="list-section">
          <h3>Siste gaver</h3>
          {(!sortedRawData || (sortedRawData && sortedRawData.length === 0)) && (
            <ul>
              <li>
                <span>Ingen gaver så langt</span>
              </li>
            </ul>
          )}
          {sortedRawData && sortedRawData.length > 0 && (
            <ul>
              {sortedRawData.slice(0, listLength).map((o, index) => (
                <li key={index}>
                  <span>{format(o.date, "dd.MM.yyyy")}</span>
                  {o.type === ENUMS.STATSDONATIONTYPE.PROSENTAGE && (
                    <>
                      <span>Testament</span>
                      <span>Andel som gave</span>
                    </>
                  )}
                  {(o.type === ENUMS.STATSDONATIONTYPE.MONEY || o.type === ENUMS.STATSDONATIONTYPE.ONCE) && (
                    <>
                      {o.type === ENUMS.STATSDONATIONTYPE.MONEY && <span>Testament</span>}
                      {o.type === ENUMS.STATSDONATIONTYPE.ONCE && <span>Fremtidsfullmakt</span>}
                      <span>Engangs beløp som gave</span>
                      <span>{formatCurrency(o.value)}</span>
                    </>
                  )}
                  {o.type === ENUMS.STATSDONATIONTYPE.RECURRING && (
                    <>
                      <span>Fremtidsfullmakt</span>
                      <span>Årlig beløp som gave</span>
                      <span>{formatCurrency(o.value)}</span>
                    </>
                  )}
                </li>
              ))}
              {listLength < sortedRawData.length && (
                <li>
                  <button
                    className="show-more"
                    onClick={() => {
                      setListLength(listLength + 5);
                    }}
                  >
                    Vis flere
                  </button>
                </li>
              )}
            </ul>
          )}
        </div>
      </main>
    </>
  );
}
