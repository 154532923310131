import React, { useState } from 'react';
import { CardElement, useStripe, useElements } from '@stripe/react-stripe-js';
import ENUMS from '../../enums';
import { Button, TextField } from '@material-ui/core';
import './payment.scss';
import getPaymentIntentId, { completed } from '../../services/payment-data-service';
import { useEffect } from 'react';
import { downloadForm } from '../../services/form-data-service';

const CARD_OPTIONS = {
  hidePostalCode: true,
  iconStyle: 'solid',
  style: {
    base: {
      iconColor: '#39134c',
      fontWeight: 500,
      fontFamily: 'Roboto, Open Sans, Segoe UI, sans-serif',
      fontSize: '16px',
      fontSmoothing: 'antialiased',
      ':-webkit-autofill': {
        color: '#39134c',
      },
      '::placeholder': {
        color: '#39134c',
      },
    },
    invalid: {
      iconColor: '#FF0000',
      color: '#FF0000',
    },
  },
};

const initialState = {
  isSent: false,
  hasError: false,
  error: null
};

export default function PaymentCheckout(props) {
  const stripe = useStripe();
  const elements = useElements();
  const [cardholderName, setCardholderName] = useState("");
  const [paymentState, setPaymentState] = useState(initialState);
  const [isProcessing, setIsProcessing] = useState(false);
  const [clientSecret, setClientSecret] = useState("")
  const { isSent, hasError } = paymentState;
  const { testamentId, closeModal } = props;

  useEffect(() => {
    getPaymentIntentId(testamentId).then(data => {
      var response = JSON.parse(data);
      setClientSecret(response.clientSecret);
    });
  }, [getPaymentIntentId, testamentId])

  if (isSent && !hasError) {
    return (
      <div>Betaling OK, laster ned testamentet...</div>
    );
  }

  return (
    <form onSubmit={handleSubmit}>
      <label className="questionLabel">Navn på korteier</label>
      <TextField
        fullWidth
        variant='outlined'
        type="Text"
        name="cardholderName"
        onChange={(e) => setCardholderName(e.currentTarget.value)}
        required
      />
      <label className="questionLabel">Kort informasjon</label>
      <div className='card-element-container'>
        <CardElement options={CARD_OPTIONS} />
      </div>

      <Button size="large" variant="contained" className="primaryButton" color="secondary" type="submit" disabled={["initial", "succeeded", "error"].includes(paymentState) || !cardholderName || !stripe || isProcessing}>
        {isProcessing && (<>Godkjenner betaling...</>)}
        {!isProcessing && (<>Fullfør betaling</>)}
      </Button>
      {
        isSent && hasError && (
          <div className="error invalid-payment">
            <span>
              Klarte ikke fullføre betalingen, prøv igjen.
            </span>
          </div>
        )
      }
    </form>
  );

  async function handleSubmit(e) {
    e.preventDefault();
    if (!stripe || !elements) {
      return;
    }
    setIsProcessing(true);
    const cardElement = elements.getElement(CardElement);

    // Use your card Element with other Stripe.js APIs
    const { error, paymentIntent } = await stripe.confirmCardPayment(clientSecret, {
      payment_method: {
        card: cardElement,
        billing_details: { name: cardholderName },
      }
    });

    if (error) {
      setPaymentState({
        isSent: true,
        hasError: true,
        error,
      });
      console.log('[error]', error);
    } else {
      const { status } = paymentIntent;
      if (status === ENUMS.STRIPE_STATUS.SUCCEEDED) {
        setPaymentState({
          isSent: true,
          hasError: false
        });
        await completed(paymentIntent.id);
        await downloadForm(testamentId);
        closeModal();
        window.location.reload();
      }
    }
    setIsProcessing(false);
  }
}
