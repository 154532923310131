import React, { Component } from "react";
import FilterHeader from "../components/header/header";
import ScreeningInfoSection from "../components/section/screening-info-section/screening-info-section";
import FormSection from "../components/section/form-section/form-section";
import { getForm } from "../services/form-data-service";
import { FormContextProvider } from "../context/form-context";
import { get } from "../services/local-storage-service";

export default class ScreeningPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      form: null,
      startPage: true,
      isScreening: true,
      isResults: props.isResults ?? false,
    };
    this.getFirstForm = this.getFirstForm.bind(this);
    this.setStartPage = this.setStartPage.bind(this);
  }

  componentDidMount() {
    this.getFirstForm();
    window.scrollTo(0, 0);
  }

  setStartPage(startPage) {
    this.setState({
      ...this.state,
      startPage,
    });
    window.scrollTo(0, 0);
  }

  async getFirstForm() {
    const isScreening =
      window.location.pathname.toLowerCase() === "/trenger-du-testament";
    const form = await getForm(
      isScreening ? "/screening" : "/screening/result",
      false
    );

    form.answers = JSON.stringify({ answers: get() });

    this.setState({ form, isScreening });
  }

  render() {
    const { form = null, startPage = true, isResults = false } = this.state;
    const isStartPage = startPage && isResults === false;
    const frontClass = isStartPage ? "active" : "hidden-sm";
    const formClass = `screening-form ${
      startPage && isResults === false ? "hidden-sm" : "active"
    }`;

    return (
      <main>
        <FilterHeader externalHome={true} isPublic={true} />
        {form && (
          <FormContextProvider form={form}>
            <div className="app container">
              <ScreeningInfoSection
                className={frontClass}
                setStartPage={this.setStartPage}
              />
              <FormSection className={formClass} isScreening={true} />
            </div>
          </FormContextProvider>
        )}
      </main>
    );
  }
}
