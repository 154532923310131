import React from "react";
import Grid from "@material-ui/core/Grid/Grid";
import Person from "./person/person.js";
import Address from "./address";

export default function PartnersInformation(props) {
  const { type, values = {}, element = {}, setStateValues } = props;
  const { text = "" } = element;

  const bottomStyle = {
    marginTop: "12px",
  };

  return (
    <div className="person-information">
      <h3>{text}</h3>
      <Grid container>
        <Grid item xs={12} sm={10}>
          <Person id="person" answer={values.value && values.value.person} setStateValues={onSetValue} type={type} questionLabel="Ditt navn" helperText="Ditt fulle navn" />
        </Grid>
        <Grid item xs={12} sm={10}>
          <Person id="partner" answer={values.value && values.value.partner} setStateValues={onSetValue} type={type} questionLabel="Partners navn" helperText="Partners fulle navn" />
        </Grid>
        <Grid item xs={12} sm={10} style={bottomStyle}>
          <Address id="address" answer={values.value && values.value.address && { value: values.value.address }} setStateValues={onSetValue} type={type} questionLabel="Deres adresse"/>
        </Grid>
      </Grid>
    </div>
  );

  function onSetValue(id, type, value) {
    const itemValue = {
      ...values.value,
      [id]: value,
    };

    setStateValues(element.id, type, itemValue);
  }
}
