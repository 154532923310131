import React from "react";
import Button from "@material-ui/core/Button/Button";
import FormElement from "../../form-element";
import "./dynamic-list.scss";
import ENUMS from "../../../enums";

export default function DynamicList({ id, type, element = {}, answer = {}, setStateValues, checkContentRequirements }) {
  const { content = [], orientation } = element;
  const answerValues = getRenderableAnswers(answer)
  const orientationClass = orientation === 0 ? "horizontal" : "vertical";

  function getRenderableAnswers(answer) {
    if (answer.length === 0 || !answer?.value) {
      return [];
    }
    return answer.value;
  }

  function addElement() {
    setStateValues(id, type, [
      ...answerValues,
      createContentItem(answerValues.length + 1)
    ]);
  }

  function createContentItem(id) {
    return {
      id: id,
      value: content.map(({ type, alternativeText }, i) => ({
          id: `${i + 1 + (id - 1) * content.length}`,
          value: type !== ENUMS.CONTENT_TYPE.CHECKBOX ? "" : [],
          contentType: type,
          alternativeText,
        })
      ),
    };
  }

  function getRemoveFn(id, index, items) {
    return () => {
      setStateValues(
        id,
        type,
        items.filter((v, i) => i !== index)
      );
    };
  }

  const onSetState = (subId, subType, subValue, alternativeText) => {
    const itemToChange = answerValues.find(({ value }) => value.find(({ id }) => id === subId));
    const answers = answerValues.map((answerValue) => {
      if (answerValue.id === itemToChange.id) {
        answerValue.value = answerValue.value.map((v) => {
          if (v.id === subId) {
            v.value = subValue.id ? { id:  subValue.id, value: subValue.value } : subValue;
            v.alternativeText = alternativeText;
            v.contentType = subType;
          }
          return v;
        });
      }
      return answerValue;
    });

    setStateValues(id, type, answers);
  };

  const mapContent = (contentValueId, contentIndex, arrayIndex) => {
    const currentContent = content[contentIndex];
    const values = checkContentRequirements(currentContent.values, arrayIndex);
    return { ...currentContent, values: values, id: contentValueId };
  };

  return (
    <div className={`list dynamic-list ${orientationClass}`}>
      {answerValues &&
      answerValues.map((item, index) => (
        <div className="list-items" key={index}>
          <div className="list-row">
            {item.value.map(
              (contentValue, i) =>
                checkContentRequirements([content[i]], index).length !== 0 && (
                  <div key={i} className="list-row list-item">
                    <FormElement
                      element={mapContent(contentValue.id, i, index)}
                      onSetState={onSetState}
                      answer={contentValue} />
                  </div>
                )
            )}
            <div className="list-remove">
              <Button color="secondary" onClick={getRemoveFn(id, index, answerValues)}>Fjern</Button>
            </div>
          </div>
        </div>
      ))}

      <div className="list-action">
        <Button onClick={addElement} variant="outlined" className="secondaryButton" color="secondary">
          + Legg til
        </Button>
      </div>
    </div>
  );
}
