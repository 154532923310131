import React from "react";
import { FormContextConsumer } from "../context/form-context";
import FormRadio from "./form-elements/form-radio";
import DateSelector from "./form-elements/date-dropdown";
import TextElement from "./form-elements/text-element";
import StaticList from "./form-elements/static-list/static-list";
import DynamicList from "./form-elements/dynamic-list/dynamic-list";
import ENUMS from "../enums";
import FormAction from "./form-elements/form-action";
import FormGroup from "./form-group/form-group";
import PersonInformation from "./form-elements/person-information";
import Address from "./form-elements/address";
import LawyerModal from "./form-elements/lawyer-modal/lawyer-modal";
import PersonList from "./form-elements/person-list/person-list";
import FormInput from "./form-elements/form-input";
import FormCheckbox from "./form-elements/form-checkbox";
import ImageContent from "./form-elements/ImageContent";
import LinkedContent from "./form-elements/linked-content";
import FormDropdown from "./form-elements/form-dropdown";
import DistributionListRest from "./form-elements/distribution-list-rest/distribution-list-rest";
import AutoCompleteDropDown from "./form-elements/autocomplete-dropdown/autocomplete-dropdown";
import ReceiptSummary from "./form-elements/receipt-summary/receipt-summary";
import OrganisationDistribution from "./form-elements/org-dist/organisation-distribution";
import PartnersInformation from "./form-elements/partners-information";
import TitleElement from "./form-elements/title-element";
import TextListElement from "./form-elements/form-text-list";
import PaymentModal from "./payment-checkout/payment-modal";
import Group from "./form-elements/group";
import PrintModal from "./form-elements/print-modal/print-modal";

export default function FormElement(props) {
  const { element = {}, onSetState, answer } = props;
  const { id, type, text, helpText, values } = element;
  const text2 = text; //+ ` - ${id}`; //  Todo: Remove before release, just for testing

  return (
    <FormContextConsumer>
      {({ answers, setStateValues, questions, checkContentRequirements, testamentId }) => (
        <>
          {type === ENUMS.CONTENT_TYPE.RADIO && (
            <FormRadio
              id={id}
              question={text2}
              alternatives={values}
              helpText={helpText}
              setStateValues={setStateValues}
              answer={answers.find((v) => v.id === id)}
              type={type}
            />
          )}
          {type === ENUMS.CONTENT_TYPE.CHECKBOX && (
            <FormCheckbox
              id={id}
              question={text2}
              helpText={helpText}
              alternatives={values}
              setStateValues={onSetState ? onSetState : setStateValues}
              answers={answer ? answer : answers.find((v) => v.id === id)}
              type={type}
            />
          )}
          {type === ENUMS.CONTENT_TYPE.INPUT && (
            <FormInput
              id={id}
              question={text2}
              setStateValues={onSetState ? onSetState : setStateValues}
              value={answer ? answer : answers.find((v) => v.id === id)}
              type={type}
              captionText={element.captionText}
              isMultiline={element.isMultiline}
              inputProps={element.inputProps}
              inputType={element.inputType}
            />
          )}
          {type === ENUMS.CONTENT_TYPE.DATESELECTOR && (
            <DateSelector setStateValues={setStateValues} id={id} question={text2} answer={answers.find((v) => v.id === id)} type={type} />
          )}
          {type === ENUMS.CONTENT_TYPE.DROPDOWN && (
            <FormDropdown
              setStateValues={onSetState ? onSetState : setStateValues}
              id={id}
              question={text2}
              helpText={helpText}
              alternatives={values}
              answer={answer ? answer : answers.find((v) => v.id === id)}
              type={type}
              captionText={element.captionText}
            />
          )}
          {type === ENUMS.CONTENT_TYPE.PERSON_INFORMATION && (
            <PersonInformation element={element} values={answers.find((v) => v.id === id)} setStateValues={setStateValues} type={type} />
          )}
          {type === ENUMS.CONTENT_TYPE.PARTNERS_INFORMATION && (
            <PartnersInformation element={element} values={answers.find((v) => v.id === id)} setStateValues={setStateValues} type={type} />
          )}

          {type === ENUMS.CONTENT_TYPE.ADDRESS && (
            <Address
              id={element.id}
              element={element}
              answer={answer ? answer : answers.find((v) => v.id === id)}
              setStateValues={onSetState ? onSetState : setStateValues}
              type={type}
            />
          )}
          {type === ENUMS.CONTENT_TYPE.PERSON_LIST && (
            <PersonList
              question={text2}
              id={id}
              answer={answers.find((v) => v.id === id) || []}
              setStateValues={setStateValues}
              type={type}
              relation={element.relation}
              min={element.minimum}
              max={element.maximum}
              answers={answers}
            />
          )}

          {type === ENUMS.CONTENT_TYPE.RECEIPT_PAGE && <ReceiptSummary element={element} />}

          {type === ENUMS.CONTENT_TYPE.LINKEDVALUE && (
            <LinkedContent
              id={id}
              linkIds={element.linkIds}
              linkType={element.linkType}
              linkPropertyName={element.linkPropertyName}
              questions={questions}
              linkedElement={questions.find((q) => q.id === element.linkId)}
              element={element.linkedContent}
              answers={answers}
              answer={answer}
              setStateValues={onSetState ? onSetState : setStateValues}
              type={type}
            />
          )}
          {type === ENUMS.CONTENT_TYPE.STATIC_LIST && (
            <StaticList element={element} setStateValues={setStateValues} answers={answers.find((v) => v.id === id) || []} />
          )}
          {type === ENUMS.CONTENT_TYPE.DYNAMIC_LIST && (
            <DynamicList
              id={id}
              type={type}
              element={element}
              answer={answers.find((v) => v.id === id) || []}
              setStateValues={setStateValues}
              checkContentRequirements={checkContentRequirements}
            />
          )}

          {type === ENUMS.CONTENT_TYPE.AUTOCOMPLETE_DROPDOWN && (
            <AutoCompleteDropDown
              answer={answer ? answer : answers.find((v) => v.id === id)}
              element={element}
              label={text2}
              type={type}
              setStateValues={onSetState ? onSetState : setStateValues}
            />
          )}

          {type === ENUMS.CONTENT_TYPE.DISTRIBUTION_LIST && (
            <DistributionListRest
              id={id}
              answers={answers}
              answer={answers.find((a) => a.id === id)}
              element={element}
              linkIds={element.linkIds}
              setStateValues={setStateValues}
              partnerDistribution={id === ENUMS.DISTIBUTIONOWNER.PARTNER}
              ownDistribution={id === ENUMS.DISTIBUTIONOWNER.PERSON}
              sharedDistribution={id === ENUMS.DISTIBUTIONOWNER.SHARED}
            />
          )}

          {type === ENUMS.CONTENT_TYPE.ORGANISATION_DISTRIBUTION && (
            <OrganisationDistribution
              id={id}
              organisations={answers.filter((a) => element.values.map((v) => parseInt(v.value)).includes(a.id))}
              answer={answers.find((a) => a.id === element.id)}
              element={element}
              setStateValues={setStateValues}
            />
          )}

          {type === ENUMS.CONTENT_TYPE.PAYMENT_MODAL && <PaymentModal testamentId={testamentId} element={element} />}

          {/* ----------Static content ----------------*/}
          {type === ENUMS.CONTENT_TYPE.FORM_GROUP && <FormGroup element={element} checkContentRequirements={checkContentRequirements} />}
          {type === ENUMS.CONTENT_TYPE.ACTION && <FormAction element={element} />}
          {type === ENUMS.CONTENT_TYPE.TEXT && element.textType === ENUMS.TEXT_TYPE.TEXT && (
            <TextElement title={text2} text={values} links={element.links} headingSize={element.headingSize} />
          )}
          {type === ENUMS.CONTENT_TYPE.TEXT && element.textType !== ENUMS.TEXT_TYPE.TEXT && (
            <TextListElement title={text2} text={values} links={element.links} textType={element.textType} className={element.className} />
          )}
          {type === ENUMS.CONTENT_TYPE.TITLE && <TitleElement title={text2} size={element.size} />}
          {type === ENUMS.CONTENT_TYPE.LAWYERMODAL && <LawyerModal element={element} testamentId={testamentId} />}
          {type === ENUMS.CONTENT_TYPE.PRINT_MODAL && <PrintModal element={element} testamentId={testamentId} />}
          {type === ENUMS.CONTENT_TYPE.IMAGE && <ImageContent name={text2} id={id} url={element.url} />}
          {type === ENUMS.CONTENT_TYPE.HORIZONTALLINE && (
            <div className="textFormControl">
              <hr></hr>
            </div>
          )}
          {type === ENUMS.CONTENT_TYPE.GROUP && <Group element={element} checkContentRequirements={checkContentRequirements} />}
        </>
      )}
    </FormContextConsumer>
  );
}
