import ENUMS from "../../../enums";
import { getFamilyState, getRelationshipStatus } from "../../../util/answers/answers-helper";
import { gDate, getGValue } from "../../../util/g-value";

export function getLimitingDisplayText(relation, persons, answers, isPartner = false, isReciprocal = false) {
  const { hasCommonChildren, hasOwnChildren, hasPartnerChildren, partnerOverFiveYears } = getFamilyState(answers, isReciprocal);

  switch (relation) {
    case ENUMS.RELATION.MARRIED:
      return (!isReciprocal && !hasCommonChildren) ||
        (!hasCommonChildren && !isPartner && !hasOwnChildren) ||
        (!hasCommonChildren && isPartner && !hasPartnerChildren)
        ? `Begrenset til minstearv, maksimalt 6G (${getGValue(6)} per ${gDate}).`
        : `Begrenset til minstearv, maksimalt 4G (${getGValue(4)} per ${gDate}).`;

    case ENUMS.RELATION.PARTNER:
      const text = !hasCommonChildren && partnerOverFiveYears && (hasOwnChildren || hasPartnerChildren) ? `Rett til minstearv, ` : "Begrenset til minstearv, ";

      return `${text} 4G (${getGValue(4)} per ${gDate}).`;

    case ENUMS.RELATION.CHILD:
      const relationShipStatus = getRelationshipStatus(answers);
      const affectedRelationsShips = [ENUMS.RELATIONSHIP_STATUS_IDS.WIDOWER, ENUMS.RELATIONSHIP_STATUS_IDS.UNMARRIED, ENUMS.RELATIONSHIP_STATUS_IDS.DIVORCED];
      if (affectedRelationsShips.indexOf(relationShipStatus) > -1) {
        return `Begrenset til pliktarv, maks. 15G (${getGValue(15)} per ${gDate}).`;
      }
      return `Begrenset til pliktarv, maksimalt 15G (${getGValue(15)} per ${gDate}).`;

    default:
      return "";
  }
}

/***
 * Function that returns the correct label for the checkboxes used in distribution
 * @param relation
 * @param persons
 * @param answers
 * @param isPartner
 * @param isReciprocal
 * @returns {string}
 */
export function getFormLimitingLabel(relation, persons, answers, isPartner = false, isReciprocal = false) {
  const { hasCommonChildren, hasOwnChildren, hasPartnerChildren, partnerOverFiveYears } = getFamilyState(answers, isReciprocal);

  switch (relation) {
    case ENUMS.RELATION.MARRIED:
      return (!isReciprocal && !hasCommonChildren) ||
        (!hasCommonChildren && !isPartner && !hasOwnChildren) ||
        (!hasCommonChildren && isPartner && !hasPartnerChildren)
        ? `Begrens til minstearv (maks. 6G)`
        : `Begrens til minstearv (maks. 4G)`;

    case ENUMS.RELATION.PARTNER:
      const text = !hasCommonChildren && partnerOverFiveYears && (hasOwnChildren || hasPartnerChildren) ? `Gi rett til minstearv` : "Begrens til minstearv";

      return `${text} (4G)`;

    case ENUMS.RELATION.CHILD:
      return "Begrens til pliktarv (maks. 15G)";

    default:
      return "";
  }
}

export function getNonLimitedLabel(relation, answers, isPartner = false, isReciprocal = false) {
  const { hasCommonChildren, hasOwnChildren, hasPartnerChildren } = getFamilyState(answers, isReciprocal);

  switch (relation) {
    case ENUMS.RELATION.CHILD:
      return ` Rett til pliktarv.`;

    case ENUMS.RELATION.MARRIED:
      return (!isReciprocal && !(hasOwnChildren || hasCommonChildren)) ||
        (isReciprocal && !hasCommonChildren && !isPartner && !hasOwnChildren) ||
        (isReciprocal && !hasCommonChildren && isPartner && !hasPartnerChildren)
        ? ` Minst 6G (${getGValue(6)} per ${gDate}).`
        : ` Rett til minstearv, 4G (${getGValue(4)} per ${gDate}).`;

    case ENUMS.RELATION.PARTNER:
      return hasCommonChildren ? `Rett til minstearv, 4G (${getGValue(4)} per ${gDate}).` : "";

    case ENUMS.RELATION.ORGANISATION:
    case ENUMS.RELATION.OTHER:
      return ``;

    default:
      console && console.warn && console.warn("Issue creating non limited text for relation: ", { relation });
      return "";
  }
}
