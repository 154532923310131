import React from "react";
import TextField from "@material-ui/core/TextField/TextField";
import Grid from "@material-ui/core/Grid/Grid";

export default function Address(props) {
  const { id, type, answer = {}, element = {}, setStateValues, questionLabel = "Adresse" } = props;
  const { text = "" } = element;

  const variant = "outlined";

  function onChange(e) {
    const validValue = (e.target.value ?? '')
      .replace(/"/gi,'')
      .replace(/'/gi, '');
    setStateValues(id, type, { ...answer.value, [e.target.name.replace('_', '')]: validValue });
  }

  return (
    <div className="address">
      <h3>{text}</h3>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12}>
          <label className="questionLabel">{questionLabel}</label>
          <TextField
            fullWidth
            type="text"
            name="_street"
            onBlur={onChange}
            variant={variant}
            defaultValue={answer && answer.value && answer.value.street}
            required
            autoComplete='new-password'
          />
        </Grid>

        <Grid item xs={12} sm={12} md={4} lg={4}>
          <label className="questionLabel">Postnummer</label>
          <TextField
            autoComplete='new-password'
            fullWidth
            type="text"
            name="_postalCode"
            onBlur={onChange}
            variant={variant}
            defaultValue={answer && answer.value && answer.value.postalCode}
            required
          />
        </Grid>

        <Grid item xs={12} sm={12} md={8} lg={8}>
          <label className="questionLabel">Sted</label>
          <TextField autoComplete='new-password'fullWidth tyoe="text" name="_city" onBlur={onChange} variant={variant} defaultValue={answer && answer.value && answer.value.city} required />
        </Grid>
      </Grid>
    </div>
  );
}
