import React from "react";
import FormElement from "./form-element";
import { FormContextConsumer } from "../context/form-context";

export default function FormElementList() {
  return (
    <FormContextConsumer>
      {
        ({ liveQuestions }) => (
          <div className="form-element-list">
            {
              liveQuestions && liveQuestions.map((element) => (
                <FormElement element={element} key={element.id}/>
              ))
            }
          </div>
        )}
    </FormContextConsumer>
  );
}
