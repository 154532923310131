import { getAnswerById, getFamilyState, getHeirs, getRelationshipStatus, hasChildren } from "../../../../util/answers/answers-helper";
import QUESTIONS from "../QUESTION_MAP";
import ENUMS, { VESTIGE_RELATIONS } from "../../../../enums";
import ANSWERS, { HEIR_TYPE, INHERITENCE_TYPE } from "../ANSWERS_MAP";
import { getLimitingDisplayText, getNonLimitedLabel } from "../text-helper";
import formatCurrency from "../../../../util/format-currency";
import React, { useContext } from "react";
import UIContext from "../../../../context/ui-context";

const PLACEHOLDERS = {
  NAME_TOP: "[Navns]",
  NAME: "[Navn]",
  SSN: "[Fødselsnummer]",
  RELATIONSHIP_STATUS: "[livssituasjon]",
  PARTNER: "[partner]",
  CHILD: "[Barn]",
  HEIR: "[Arving]",
  RELATION: "[Relasjon]",
  INHERITANCE: "[Arv]",
};

export default function PreviewWill({ answers }) {
  const {
    state: { mode },
  } = useContext(UIContext);
  const className = mode === ENUMS.UI_STATE.FORM ? "hidden-sm" : "";

  const { person = {} } = getAnswerById(QUESTIONS.USER, answers) || {};
  const { name = null, identification = "" } = person;

  const topName = name ?? PLACEHOLDERS.NAME_TOP;
  const singularName = name ?? PLACEHOLDERS.NAME;

  return (
    <div className={`section testament-page ${className}`}>
      <div className="sectionContent" onmousedown='return false;' onselectstart='return false;'>
        <div className="will will-draft">
          <div className="preview-logo">
            <div className="will-identity">
              <img src="/images/arv-logo.svg" alt="arv.no" className="logo" />
              <div className="will-logo-text">arv.no</div>
            </div>
          </div>

          <h2>{renderInput(topName, !!name)}s testament</h2>

          <p>
            Jeg, <span className="name">{renderInput(singularName, !!name)}&nbsp;</span>(f.nr.&nbsp;{identification}),&nbsp;
            {getRelationShipText(answers)}
            {getChildrenText(answers)}
            &nbsp;Dette er min siste vilje.
          </p>

          <p>Jeg ønsker at mine eiendeler skal fordeles på følgende måte:</p>

          <table>
            <thead>
              <tr>
                <th width="35%">Arving</th>
                <th width="15%">Relasjon</th>
                <th width="50%">Eiendeler</th>
              </tr>
            </thead>
            <tbody>
              {getInheritance(answers).map(({ name, relation, identification, identificationId, inheritance, address }, index, persons) => (
                <tr key={`${identification ?? identificationId ?? relation}-${relation}-${index}`}>
                  <td>
                    <span className="name">{name}</span>
                    {(relation === ENUMS.RELATION.OTHER || relation === ENUMS.RELATION.OTHERFAMILY) && (
                      <>
                        <br />
                        <span>
                          {address?.street ?? null}
                          <br />
                          {address?.postalCode ?? null} {address?.city ?? null}
                        </span>
                      </>
                    )}
                    {relation === ENUMS.RELATION.ORGANISATION && (
                      <>
                        <br />
                        {(identificationId || identification) && "Org.nr. "}
                        {identificationId ?? identification ?? ""}{" "}
                      </>
                    )}
                  </td>
                  <td>
                    <span className="relation">{getRelationText(relation)}</span>
                  </td>
                  <td>
                    <div className="belongings">
                      {relation !== ENUMS.RELATION.ORGANISATION && isFollowLaw(answers) && (!inheritance || inheritance.length === 0) && (
                        <>Fordeles ut i fra arveloven.</>
                      )}

                      {(relation === ENUMS.RELATION.ORGANISATION || !isFollowLaw(answers) || inheritance || inheritance.length !== 0) &&
                        inheritance &&
                        inheritance.map(
                          ({ inheritanceType, heritage = "", inheritsDebt, isLimited, separateProperty, hasZeroVestige }, index) => (
                            <div className="heritage-item" key={`vestige-item_${relation}_${inheritanceType}_${index}`}>
                              {inheritanceType === INHERITENCE_TYPE.PROPERTY && heritage}
                              {inheritanceType === INHERITENCE_TYPE.VESTIGE && isLimited && getLimitingDisplayText(relation, persons, answers)}
                              {inheritanceType === INHERITENCE_TYPE.VESTIGE &&
                                !isLimited &&
                                !hasZeroVestige &&
                                getNonLimitedText(relation, heritage, persons, answers)}
                              {inheritanceType === INHERITENCE_TYPE.MONEY && formatCurrency(heritage) + "."}
                              {inheritanceType === INHERITENCE_TYPE.PROPERTY && inheritsDebt ? " (inkl. evt. gjeld)." : ""}
                              {separateProperty ? " Arves som særeie." : ""}
                            </div>
                          )
                        )}
                    </div>
                    {index === answers.length - 1 && <br />}
                  </td>
                </tr>
              ))}
            </tbody>

            <tfoot>
              <tr>
                <td colSpan={3}>{hasLegalHeirs(answers) ? <>Grunnbeløpet (G) justeres hver år med virkning fra 1. mai.</> : <></>}</td>
              </tr>
            </tfoot>
          </table>

          {getDistributeAllVestige(answers)}
          {getPartnerRightsText(answers)}
          {getResponsible(answers)}
          {getRetractPreviousWill(answers)}
          {getFuneralWishes(answers)}

          <div className="grid-x grid-padding-x">
            <div className="cell small-5 signaturePlace">{getSignature(answers, name)}</div>
          </div>

          <p>
            Vi som er særskilt tilkalte vitner og begge er over 18 år, erklærer at {renderInput(singularName, !!name)} i vårt nærvær har
            undertegnet eller vedkjenner seg underskriften av dette testamente. {renderInput(singularName, !!name)} erklærte at testamentet er
            gjort av fri vilje og {renderInput(singularName, !!name)} var ved sans og samling.{" "}
          </p>

          <div className="grid-x grid-padding-x">
            <div className="cell small-5 left">
              <div className="signatures">
                {getWitnessSignature(answers, 0)}
                {getWitnessSignature(answers, 1)}
              </div>
            </div>
          </div>

          <div className="will-footer">
            <div className="copyright">
              <img alt="Copyright" src="/images/arv-logo.png" />
              <span>arv.no</span>
            </div>
            <div className="appname">
              <a href="https://www.sealedit.no" target="_blank" rel="noopener noreferrer">
                Sealedit
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

function hasLegalHeirs(answers) {
  const { hasAnyChildren } = getFamilyState(answers);
  const heirs = getHeirs(answers);
  const hasPartner = heirs.some(({ relation }) => relation === ENUMS.RELATION.PARTNER);

  const isMarried = heirs.some(({ relation }) => relation === ENUMS.RELATION.MARRIED);

  if (isMarried) {
    return true;
  }

  if (hasPartner && hasAnyChildren) {
    return true;
  }

  return hasAnyChildren;
}

function getPartnerRightsText(answers) {
  const partnerPlaceAnswer = answers.find(({ id }) => id === QUESTIONS.PARTNER_USKIFTET_BO);
  if (!partnerPlaceAnswer) {
    return null;
  }

  const [partnerPlaceElm] = partnerPlaceAnswer.value;
  const partnerPlace = partnerPlaceElm?.checked ?? false;
  const heirs = getHeirs(answers);
  const partner = heirs.find(({ relation }) => relation === ENUMS.RELATION.PARTNER);

  if (partner && partnerPlace) {
    return (
      <p>
        Min samboer har rett til å sitte i uskifte med felles bolig og innbo, bil og fritidseiendom som tjente til felles bruk for oss. Min
        samboer har også rett til å sitte i uskifte med øvrige eiendeler (utvidet uskifte)
      </p>
    );
  }
  return null;
}

function getInheritance(answers) {
  const listItem = getAnswerById(QUESTIONS.INHERITANCE, answers) ?? [];
  const heirs = getHeirs(answers);

  listItem.forEach(({ value = [] }) => {
    const [person, inheritance, amount, property, inheritsDebtAnswer, separatePropertyAnswer] = value;
    const inheritanceTypeText = inheritance && inheritance.alternativeText ? inheritance.alternativeText.toLowerCase() : "";
    const inheritanceType = inheritanceTypeText === "pengebeløp" ? INHERITENCE_TYPE.MONEY : INHERITENCE_TYPE.PROPERTY;

    const [inheritsDebt] = inheritsDebtAnswer?.value ?? [];
    const [separateProperty] = separatePropertyAnswer?.value ?? [];

    const item = {
      inheritanceType,
      heritage: inheritanceType === INHERITENCE_TYPE.MONEY ? amount?.value : property?.value,
      inheritsDebt: inheritsDebt?.checked ?? false,
      separateProperty: separateProperty?.checked ?? false,
    };

    const heir = heirs.find(({ name }) => name === person?.alternativeText);

    if (heir) {
      heir.inheritance.push(item);
    }
  });

  const sortOrder = [
    ENUMS.RELATION.MARRIED,
    ENUMS.RELATION.PARTNER,
    ENUMS.RELATION.CHILD,
    ENUMS.RELATION.PARENT,
    ENUMS.RELATION.GRANDCHILDREN,
    ENUMS.RELATION.GREATGRANDCHILDREN,
    ENUMS.RELATION.OTHERFAMILY,
    ENUMS.RELATION.OTHER,
    ENUMS.RELATION.ORGANISATION,
  ];
  const orgs = getOrganisationHeirs(answers);
  return getVestige(answers, heirs.concat(orgs)) // getVestige mutates heirs array because stress.
    .sort((a, b) => {
      const aIndex = sortOrder.indexOf(a?.relation);
      const bIndex = sortOrder.indexOf(b?.relation);
      return aIndex - bIndex;
    });
}

/***
 * Adds vestige in same pattern as above. THIS FUNCTION MUTATES "heirs" array.
 * @param answers
 * @param heirs
 * @returns {*}
 */
function getVestige(answers, heirs) {
  const vestige = getAnswerById(QUESTIONS.VESTIGE, answers) ?? [];
  if (!vestige) {
    return heirs;
  }

  vestige.forEach(({ name, value, identification, relation, isSeparateProperty = false, isLimited = false }) => {
    const hasZeroVestige = !value || /^0\%?$/.test(value);
    const isVestigeable = VESTIGE_RELATIONS.indexOf(relation) > -1;
    const heritage = hasZeroVestige && !isVestigeable ? "" : value + " av resterende formue.";

    const inheritance = {
      inheritanceType: INHERITENCE_TYPE.VESTIGE,
      heritage,
      inheritsDebt: false,
      separateProperty: isSeparateProperty,
      isLimited,
      hasZeroVestige,
    };

    const heir = heirs.find(({ identification: id, name: heirName }) => (id && id === identification) || heirName === name);

    if (heir) {
      heir.inheritance.push(inheritance);
    } else {
      heirs.push({
        name,
        type: relation === ENUMS.RELATION.ORGANISATION ? HEIR_TYPE.ORGANIZATION : HEIR_TYPE.PERSON,
        relation: relation,
        identificationId: identification,
        inheritance: [inheritance],
      });
    }
  });
  return heirs;
}

function getDistributeAllVestige(answers) {
  const isVestige = getAnswerById(QUESTIONS.DISTRIBUTE_ALL_VESTIGE, answers);
  if (!isVestige || (isVestige !== 1027 && isVestige !== "1027")) return null;

  const vestigePeople = getAnswerById(QUESTIONS.ALL_VESTIGE, answers);

  if (!vestigePeople) return null;

  const alternativeText = vestigePeople.length > 1 ? vestigePeople.map((v) => v.value).join(", ") : vestigePeople[0].value;

  return (
    <p>
      <span className="name">{alternativeText.replace(/,([^,]*)$/, " og " + "$1")}</span> skal motta det som er igjen av formue, innbo og løsøre
      etter at arv er fordelt.
    </p>
  );
}

function getOrganisationHeirs(answers) {
  const donations = getAnswerById(QUESTIONS.ORGANISATION_DONATION, answers);
  if (!donations) {
    return [];
  }

  return donations.map(({ id, name, organisationId: identificationId, value: heritage, type }) => ({
    id,
    name,
    identificationId,
    relation: ENUMS.RELATION.ORGANISATION,
    inheritance: [
      {
        inheritanceType: type === ENUMS.DONATIONTYPE.MONEY ? INHERITENCE_TYPE.MONEY : INHERITENCE_TYPE.VESTIGE,
        heritage: formatHeritage(heritage, type),
        inheritsDebt: false,
        separateProperty: false,
      },
    ],
  }));
}

function formatHeritage(value, type) {
  if (!value) return "";

  return type === ENUMS.DONATIONTYPE.MONEY ? value : value + " av resterende formue.";
}

function getNonLimitedText(relation, heritage, persons, answers) {
  return `${heritage} ${getNonLimitedLabel(relation, answers)}`;
}

export function getRelationText(relationId) {
  switch (relationId) {
    case ENUMS.RELATION.CHILD:
      return "Barn";
    case ENUMS.RELATION.MARRIED:
      return "Gift";
    case ENUMS.RELATION.PARTNER:
      return "Samboer";
    case ENUMS.RELATION.PARENT:
      return "Forelder";
    case ENUMS.RELATION.SIBLING:
      return "Søsken";
    case ENUMS.RELATION.OTHER:
      return "Annen";
    case ENUMS.RELATION.ORGANISATION:
      return "Organisasjon";
    case ENUMS.RELATION.GRANDCHILDREN:
      return "Barnebarn";
    case ENUMS.RELATION.GREATGRANDCHILDREN:
      return "Oldebarn";
    case ENUMS.RELATION.OTHERFAMILY:
      return "Annen famile";
    default:
      console.log("Unable to find relation for relationId " + relationId);
      return "ukjent relasjon";
  }
}

function renderInput(input, isAnswered = false) {
  const className = isAnswered ? "input-answered" : "input-placeholder";
  return <span className={className}>{input}</span>;
}

function getWitnessSignature(answers, witnessIndex) {
  const witness = getAnswerById(witnessIndex === 0 ? QUESTIONS.WITNESS_1 : QUESTIONS.WITNESS_2, answers);
  let nameAnswer = "",
    addressAnswer = {},
    professionAnswer = "";
  if (witness) {
    if (witnessIndex === 0) {
      nameAnswer = getAnswerById(QUESTIONS.WITNESS_NAME_1, witness);
      addressAnswer = getAnswerById(QUESTIONS.WITNESS_ADDRESS_1, witness) || {};
      professionAnswer = getAnswerById(QUESTIONS.WITNESS_PROFESSION_1, witness);
    } else {
      nameAnswer = getAnswerById(QUESTIONS.WITNESS_NAME_2, witness);
      addressAnswer = getAnswerById(QUESTIONS.WITNESS_ADDRESS_2, witness) || {};
      professionAnswer = getAnswerById(QUESTIONS.WITNESS_PROFESSION_2, witness);
    }
  }

  const { street, postalCode, city } = addressAnswer;
  const nameStr = nameAnswer ? nameAnswer.toUpperCase() : "";

  return (
    <div className="signature">
      <dl>
        <dd>Sted/dato:</dd>
        <dd className="empty"></dd>
        <dd>Underskrift:</dd>
        <dd className="empty"></dd>

        {nameStr ? (
          <dd>{nameStr}</dd>
        ) : (
          <>
            <dt>Navn med blokkbokstaver:</dt>
            <dd className="empty"></dd>
          </>
        )}

        {professionAnswer ? (
          <dd>{professionAnswer}</dd>
        ) : (
          <>
            <dt>Yrke/stilling:</dt>
            <dd className="empty"></dd>
          </>
        )}

        {street || postalCode || city ? (
          <dd>
            {street ?? ""},{" "}
            <span>
              {postalCode ?? ""} {city ?? ""}
            </span>
          </dd>
        ) : (
          <>
            <dt>Adresse:</dt>
            <dd className="empty"></dd>
          </>
        )}
      </dl>
    </div>
  );
}

function getPlaceData(answers) {
  const place = getAnswerById(QUESTIONS.SIGNING_PLACE, answers);
  if (!place) {
    return null;
  }
  const placeCity = getAnswerById(QUESTIONS.PLACE_CITY, place);
  const placeDate = getAnswerById(QUESTIONS.PLACE_DATE, place);
  return {
    placeCity,
    placeDate,
  };
}

function getSignature(answers, name) {
  const place = getPlaceData(answers) ?? {};
  const { placeCity, placeDate } = place;
  const nameUppercase = name ? name.toUpperCase() : "";
  return (
    <div className="signature">
      <dl>
        {placeCity && placeDate ? (
          <dd>
            {placeCity}, {placeDate}
          </dd>
        ) : (
          <>
            <dd>Sted/dato:</dd>
            <dd className="empty"></dd>
          </>
        )}
        <dd className="empty"></dd>
        <dd>{renderInput(name ? nameUppercase : "[NAVN]", !!name)}</dd>
      </dl>
    </div>
  );
}

function getRetractPreviousWill(answers) {
  const [retractPreviousWill] = getAnswerById(QUESTIONS.RETRACT_PREVIOUS_WILL, answers) ?? [];
  if (!retractPreviousWill?.checked) {
    return null;
  }

  return <p>Dette testament erstatter alle mine tidligere testament.</p>;
}

function getFuneralWishes(answers) {
  const [hasFuneralWishesAnswer] = getAnswerById(QUESTIONS.HAS_FUNERAL_WISHES, answers) ?? [];
  const funeralFunds = getAnswerById(QUESTIONS.FUNERAL_MONEY, answers);
  const funeralWishes = getAnswerById(QUESTIONS.FUNERAL_WISHES, answers);
  const funeralFundsFormatted = formatCurrency(funeralFunds);

  const { checked: hasFuneralWishes = false } = hasFuneralWishesAnswer ?? {};
  return (
    <>
      {(hasFuneralWishes || !!funeralWishes) && <h3>Andre ønsker</h3>}
      {hasFuneralWishes && funeralFunds && <p>Det skal settes av {funeralFundsFormatted} til gravlegat som trekkes før arv fordeles.</p>}
      {!!funeralWishes && !!funeralWishes.length && <p>{funeralWishes}</p>}
    </>
  );
}

function getResponsible(answers) {
  const responsibleAnswer = getAnswerById(QUESTIONS.WILL_RESPONSIBLE, answers);
  if (!responsibleAnswer) {
    return null;
  }

  const {
    fullValue: { identification: responsibleId },
  } = responsibleAnswer ?? {};

  const responsible = getHeirs(answers).find(({ identification }) => identification === responsibleId) || {};

  const { name, identification, relation } = responsible;
  const isOrg = relation === ENUMS.RELATION.ORGANISATION;
  return (
    <p>
      <span className="name">{renderInput(name ?? PLACEHOLDERS.NAME, !!name)}&nbsp;</span>
      {!!identification && (
        <>
          ({isOrg && !!identification && <>Org.nr.&nbsp;</>}
          {<>{identification}</>})
        </>
      )}{" "}
      er testamentfullbyrder.
    </p>
  );
}

function getChildrenText(answers) {
  const hasKids = hasChildren(answers);
  if (!hasKids) {
    return <>, og har ikke barn.</>;
  }
  const children = getHeirs(answers).filter(({ relation }) => relation === ENUMS.RELATION.CHILD);

  let out = `, og har ${children.length} barn, `;
  for (let i = 0; i < children.length; i++) {
    const { name, identification } = children[i];
    const nameStr = `${name} (f. ${identification})`;
    if (i === 0) {
      out += `${nameStr}`;
    } else {
      out += `${i < children.length - 1 ? ", " : " og "} ${nameStr}`;
    }
  }
  return out + ".";
}

function getRelationShipText(answers) {
  const relationshipStatus = getRelationshipStatus(answers);
  const heirs = getHeirs(answers);

  switch (relationshipStatus) {
    case ENUMS.RELATIONSHIP_STATUS_IDS.UNMARRIED:
      return "er ugift";

    case ENUMS.RELATIONSHIP_STATUS_IDS.PARTNER:
      const { name: partnerName = "", identification: partnerId = "" } = heirs.find(({ relation }) => relation === ENUMS.RELATION.PARTNER) || {};

      return (
        <>
          er samboer med <span className="name">{partnerName}</span> (f.&nbsp;{partnerId})
        </>
      );
    case ENUMS.RELATIONSHIP_STATUS_IDS.MARRIED:
      const { name = "", identification = "" } = heirs.find(({ relation }) => relation === ENUMS.RELATION.MARRIED) || {};
      return (
        <>
          er gift med <span className="name">{name}</span> (f.&nbsp;{identification})
        </>
      );

    case ENUMS.RELATIONSHIP_STATUS_IDS.DIVORCED:
      return "er skilt";

    case ENUMS.RELATIONSHIP_STATUS_IDS.WIDOWER:
      return "er enke/enkemannn";

    default:
      return (
        <>
          {renderInput(PLACEHOLDERS.RELATIONSHIP_STATUS, false)}
          {renderInput(PLACEHOLDERS.PARTNER, false)}
        </>
      );
  }
}

function isFollowLaw(answers) {
  return answers.find(({ id, value }) => {
    if (typeof id === "string" && !isNaN(id)) {
      id = parseInt(id);
    }
    if (typeof value === "string" && !isNaN(value)) {
      value = parseInt(value);
    }

    return id === QUESTIONS.DISTRIBUTE_SELF && value === ANSWERS.NO_FOLLOW_LAW_ORDER;
  });
}
