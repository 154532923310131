import {
  createHeirsList,
  getAnswerById,
  // getHeirs,
  // getRelationshipStatus,
} from "../../../../util/answers/answers-helper";
import QUESTIONS, { RECIPROCAL_QUESTION_IDS } from "../QUESTION_MAP";
import ENUMS, { VESTIGE_RELATIONS } from "../../../../enums";
import { INHERITENCE_TYPE, ANSWERS_RECIPROCAL } from "../ANSWERS_MAP";
import React, { useContext } from "react";
import UIContext from "../../../../context/ui-context";
import InheritanceTable from "./inheritance-table";

const PLACEHOLDERS = {
  NAME_TOP: "[Navns]",
  NAME: "[Navn]",
  SSN: "[Fødselsnummer]",
  RELATIONSHIP_STATUS: "[livssituasjon]",
  PARTNER: "[partner]",
  CHILD: "[Barn]",
  HEIR: "[Arving]",
  RELATION: "[Relasjon]",
  INHERITANCE: "[Arv]",
};

export default function PreviewWillReciprocale({ answers }) {
  const {
    state: { mode },
  } = useContext(UIContext);
  const className = mode === ENUMS.UI_STATE.FORM ? "hidden-sm" : "";

  const { person = {}, partner = {} } = getAnswerById(QUESTIONS.USER, answers) || {};
  const { name = null, identification = "" } = person;

  const topName = name ?? PLACEHOLDERS.NAME_TOP;
  const topNamePartner = partner?.name ?? PLACEHOLDERS.NAME_TOP;

  const { hasCommonChildren, hasOwnChildren, hasPartnerChildren } = getFamilyState(answers);

  const showCommon = hasCommonChildren && hasOwnChildren === false && hasPartnerChildren === false;

  return (
    <div className={`section testament-page ${className}`}>
      <div className="sectionContent" onmousedown='return false;' onselectstart='return false;'>
        <div className="will will-draft">
          <div className="preview-logo">
            <div className="will-identity">
              <img src="/images/arv-logo.svg" alt="arv.no" className="logo" />
              <div className="will-logo-text">arv.no</div>
            </div>
          </div>

          <h2>
            {renderInput(topName, !!name)} og {renderInput(topNamePartner, !!partner?.name)}s gjensidige testament
          </h2>

          <p>
            Vi, <span className="name">{name}</span> ({identification}) og <span className="name">{partner?.name ?? ""}</span> (
            {partner?.identification ?? ""}),&nbsp;
            {getRelationShipText(answers)}
            {getChildrenText(answers, name, partner)}
            &nbsp;Dette er vår siste vilje.
          </p>

          <InheritanceTable
            inheritance={getInheritanceOwn(answers)}
            answers={answers}
            label={`Dersom ${name} dør først skal eiendelene fordeles på følgende måte:`}
            isPartner={false}
          />

          <InheritanceTable
            inheritance={getInheritancePartner(answers)}
            answers={answers}
            label={`Dersom ${partner?.name ?? ""} dør først skal eiendelene fordeles på følgende måte:`}
            isPartner={true}
          />

          {showCommon && (
            <InheritanceTable
              inheritance={getInheritanceCommon(answers)}
              answers={answers}
              label={"Vi ønsker at våre eiendeler skal fordeles på følgende måte:"}
              isPartner={false}
            />
          )}

          {/*@if (Model.LongestLiving)*/}
          {getLongestLiving(answers) && (
            <p>Den av oss som lever lengst kan fritt disponere arven i levende live og kan opprette eget testament.</p>
          )}

          {/*@if (Model.LongetLivingDead)*/}
          {getLongestLivingDead(answers) && (
            <p>Ved lengstlevendes død skal dødsboet tilfalle lengstlevendes arvinger etter arveloven eller testament.</p>
          )}

          {/*@if (Model.RetractWill)*/}
          {getRetractWill(answers) && (
            <p>Dette gjensidige testamentet skal ikke gjelde dersom vi flytter fra hverandre av andre grunner enn sykdom eller alder.</p>
          )}

          {/*@if (Model.RetractOwnPreviousWills)*/}
          {getRetractOwnPreviousWills(answers) && <p>Dette testament erstatter alle {name}s tidligere testament.</p>}

          {/*@if (Model.RetractPartnerPreviousWills)*/}
          {getRetractPartnerPreviousWills(answers) && <p>Dette testament erstatter alle {partner?.name ?? ""}s tidligere testament.</p>}

          {/*@if (Model.NotChangeLiving)*/}
          {getNotChangeLiving(answers) && (
            <p>
              Den av oss som lever lengst har rett til å sitte i uskifte med felles bolig og innbo, bil og fritidseiendom som tjente til felles
              bruk for oss. Lengstlevende har også rett til å sitte i uskifte med øvrige eiendeler (utvidet uskifte).
            </p>
          )}

          {/*@if (Model.OtherWishes)*/}
          {getOtherWishes(answers)}

          {
            // getDistributeAllVestige(answers)
          }

          {
            // getPartnerRightsText(answers)
          }

          {/*{getResponsible(answers)}*/}
          {/*{getRetractPreviousWill(answers)}*/}
          {/*{getFuneralWishes(answers)}*/}

          <div className="grid-x grid-padding-x">
            <div className="cell small-5 signaturePlace">
              {getSignature(answers, name)}
              {getSignature(answers, partner?.name)}
            </div>
          </div>

          <p>
            Som særskilte tilkalte vitner bekrefter vi at ovenstående testament i dag, mens vi begge var tilstede samtidig, ble undertegnet av{" "}
            <span className="name">{name}</span> og <span className="name">{partner.name}</span>, som vedkjente seg det som deres siste vilje. Vi
            underskriver etter deres ønske, og mens de selv er tilstede, liksom vi bekrefter at de hele tiden har hatt full, normal dømmekraft.
          </p>

          <div className="grid-x grid-padding-x">
            <div className="cell small-5 left">
              <div className="signatures">
                {getWitnessSignature(answers, 0)}
                {getWitnessSignature(answers, 1)}
              </div>
            </div>
          </div>

          <div className="will-footer">
            <div className="copyright">
              <img alt="Copyright" src="/images/arv-logo.png" />
              <span>arv.no</span>
            </div>
            <div className="appname">
              <a href="https://www.sealedit.no" target="_blank" rel="noopener noreferrer">
                Sealedit
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

function getHeirsOwn(answers) {
  const personIds = [RECIPROCAL_QUESTION_IDS.PEOPLE_OWN_CHILDREN, RECIPROCAL_QUESTION_IDS.PEOPLE_OWN_OTHER];
  return createHeirsList(answers, personIds);
}

function getHeirsPartner(answers) {
  const personIds = [RECIPROCAL_QUESTION_IDS.PEOPLE_PARTNER_CHILDREN, RECIPROCAL_QUESTION_IDS.PEOPLE_PARTNER_OTHER];
  return createHeirsList(answers, personIds);
}

function getHeirsCommon(answers) {
  const personIds = [RECIPROCAL_QUESTION_IDS.PEOPLE_COMMON_CHILDREN, RECIPROCAL_QUESTION_IDS.PEOPLE_COMMON_OTHERS];
  return createHeirsList(answers, personIds);
}

function getHeirs(answers) {
  const { person = {}, partner = {} } = getAnswerById(QUESTIONS.USER, answers) || {};
  return [person, partner].concat(getHeirsOwn(answers)).concat(getHeirsPartner(answers)).concat(getHeirsCommon(answers));
}

function getInheritanceOwn(answers) {
  const listItem = getAnswerById(RECIPROCAL_QUESTION_IDS.OWN_INHERITANCE, answers) ?? [];
  const organizations = getDonationOrgs(RECIPROCAL_QUESTION_IDS.OWN_ORGANIZATION_DONATION, answers);

  return getInheritance(listItem, answers, organizations);
}

function getInheritancePartner(answers) {
  const listItem = getAnswerById(RECIPROCAL_QUESTION_IDS.PARTNER_INHERITANCE, answers) ?? [];
  const organizations = getDonationOrgs(RECIPROCAL_QUESTION_IDS.PARTNER_ORGANIZATION_DONATION, answers);

  return getInheritance(listItem, answers, organizations);
}

function getInheritanceCommon(answers) {
  const listItem = getAnswerById(RECIPROCAL_QUESTION_IDS.COMMON_INHERITANCE, answers) ?? [];
  const organizations = getDonationOrgs(RECIPROCAL_QUESTION_IDS.COMMON_ORGANIZATION_DONATION, answers);

  return getInheritance(listItem, answers, organizations);
}

function getInheritance(inheritance, answers, organizations) {
  const heirs = getHeirs(answers);

  const inheritanceMap = {};
  inheritance.forEach((heritageItem) => {
    const { id, identification, relation, name, value = "", isSeparateProperty, isLimited, adresse: friendAddress } = heritageItem;

    const hasZeroVestige = !value || /^0\%?$/.test(value);
    const isVestigeable = VESTIGE_RELATIONS.indexOf(relation) > -1;
    const heritage = hasZeroVestige && !isVestigeable ? "" : value + " av resterende formue.";

    const item = {
      inheritanceType: INHERITENCE_TYPE.VESTIGE,
      heritage: heritage, // heritage swapped
      separateProperty: isSeparateProperty,
      isLimited,
    };

    const heir = heirs.find(({ identification: heirId, relation, ...rest }) => {
      if (relation === ENUMS.RELATION.OTHER) {
        const { adresse } = rest;
        const adrStr = JSON.stringify(adresse);
        return friendAddress && JSON.stringify(friendAddress) === adrStr;
      }
      return heirId === identification;
    });

    if (!inheritanceMap[id]) {
      inheritanceMap[id] = {
        heir: {
          ...heir,
          name,
          relation,
        },
        inheritance: [item],
      };
    } else {
      inheritanceMap[id].inheritance.push(item);
    }
  });

  const inheritanceList = Object.keys(inheritanceMap).map((key) => {
    const { heir, inheritance } = inheritanceMap[key];
    return {
      ...heir,
      inheritance,
    };
  });

  const sortOrder = [
    ENUMS.RELATION.MARRIED,
    ENUMS.RELATION.PARTNER,
    ENUMS.RELATION.CHILD,
    ENUMS.RELATION.PARENT,
    ENUMS.RELATION.GRANDCHILDREN,
    ENUMS.RELATION.GREATGRANDCHILDREN,
    ENUMS.RELATION.OTHERFAMILY,
    ENUMS.RELATION.OTHER,
    ENUMS.RELATION.ORGANISATION,
  ];

  const organizationInheritance = getOrganisationHeirs(organizations);
  return inheritanceList.concat(organizationInheritance).sort((a, b) => {
    const aIndex = sortOrder.indexOf(a?.relation);
    const bIndex = sortOrder.indexOf(b?.relation);
    return aIndex - bIndex;
  });
}

function getDonationOrgs(id, answers) {
  const donations = getAnswerById(id, answers);
  if (!donations) {
    return [];
  }
  return donations;
}

function getOrganisationHeirs(donations) {
  return donations.map(({ id, name, organisationId: identificationId, value: heritage, type }) => ({
    id,
    name,
    identificationId,
    relation: ENUMS.RELATION.ORGANISATION,
    inheritance: [
      {
        inheritanceType: type === ENUMS.DONATIONTYPE.MONEY ? INHERITENCE_TYPE.MONEY : INHERITENCE_TYPE.VESTIGE,
        heritage: type === ENUMS.DONATIONTYPE.PROSENTAGE ? heritage + " av resterende formue." : heritage,
        inheritsDebt: false,
        separateProperty: false,
      },
    ],
  }));
}

function renderInput(input, isAnswered = false) {
  const className = isAnswered ? "input-answered" : "input-placeholder";
  return <span className={className}>{input}</span>;
}

function getWitnessSignature(answers, witnessIndex) {
  const witness = getAnswerById(witnessIndex === 0 ? RECIPROCAL_QUESTION_IDS.WITNESS_1 : RECIPROCAL_QUESTION_IDS.WITNESS_2, answers);
  let nameAnswer = "",
    addressAnswer = {},
    professionAnswer = "";

  if (witness) {
    if (witnessIndex === 0) {
      nameAnswer = getAnswerById(RECIPROCAL_QUESTION_IDS.WITNESS_NAME_1, witness);
      addressAnswer = getAnswerById(RECIPROCAL_QUESTION_IDS.WITNESS_ADDRESS_1, witness) || {};
      professionAnswer = getAnswerById(RECIPROCAL_QUESTION_IDS.WITNESS_PROFESSION_1, witness);
    } else {
      nameAnswer = getAnswerById(RECIPROCAL_QUESTION_IDS.WITNESS_NAME_2, witness);
      addressAnswer = getAnswerById(RECIPROCAL_QUESTION_IDS.WITNESS_ADDRESS_2, witness) || {};
      professionAnswer = getAnswerById(RECIPROCAL_QUESTION_IDS.WITNESS_PROFESSION_2, witness);
    }
  }

  const { street, postalCode, city } = addressAnswer;
  const nameStr = nameAnswer ? nameAnswer.toUpperCase() : "";

  return (
    <div className="signature">
      <dl>
        <dd>Sted/dato:</dd>
        <dd className="empty"></dd>
        <dd>Underskrift:</dd>
        <dd className="empty"></dd>

        {nameStr ? (
          <dd>{nameStr}</dd>
        ) : (
          <>
            <dt>Navn med blokkbokstaver:</dt>
            <dd className="empty"></dd>
          </>
        )}

        {professionAnswer ? (
          <dd>{professionAnswer}</dd>
        ) : (
          <>
            <dt>Yrke/stilling:</dt>
            <dd className="empty"></dd>
          </>
        )}

        {street || postalCode || city ? (
          <dd>
            {street ?? ""},{" "}
            <span>
              {postalCode ?? ""} {city ?? ""}
            </span>
          </dd>
        ) : (
          <>
            <dt>Adresse:</dt>
            <dd className="empty"></dd>
          </>
        )}
      </dl>
    </div>
  );
}

function getPlaceData(answers) {
  const place = getAnswerById(RECIPROCAL_QUESTION_IDS.SIGNING_PLACE, answers);
  if (!place) {
    return null;
  }
  const placeCity = getAnswerById(RECIPROCAL_QUESTION_IDS.PLACE_CITY, place);
  const placeDate = getAnswerById(RECIPROCAL_QUESTION_IDS.PLACE_DATE, place);
  return {
    placeCity,
    placeDate,
  };
}

function getSignature(answers, name) {
  const place = getPlaceData(answers) ?? {};
  const { placeCity, placeDate } = place;
  const nameUppercase = name ? name.toUpperCase() : "";
  return (
    <div className="signature">
      <dl>
        {placeCity && placeDate ? (
          <dd>
            {placeCity}, {placeDate}
          </dd>
        ) : (
          <>
            <dd>Sted/dato:</dd>
            <dd className="empty"></dd>
          </>
        )}
        <dd className="empty"></dd>
        <dd>{renderInput(name ? nameUppercase : "[NAVN]", !!name)}</dd>
      </dl>
    </div>
  );
}

function getChildrenText(answers, name, partner) {
  const { hasAnyChildren, commonChildren, ownChildren, partnerChildren } = getFamilyState(answers);

  if (!hasAnyChildren) {
    return <> Vi har ingen barn.</>;
  }

  return (
    <>
      {commonChildren.length > 0 && <> Vi har {commonChildren.length} felles barn.</>}
      {ownChildren.length > 0 && (
        <>
          {" "}
          Jeg, <span className="name">{name}</span> har {ownChildren.length} barn.
        </>
      )}
      {partnerChildren.length > 0 && (
        <>
          {" "}
          <span className="name">{partner?.name ?? ""}</span> har {partnerChildren.length} barn.
        </>
      )}
    </>
  );
}

export function getRelationShipText(answers) {
  const relationshipStatus = getRelationshipStatus(answers);

  switch (relationshipStatus) {
    case ANSWERS_RECIPROCAL.PARTNER:
      return <>er samboere.</>;

    case ANSWERS_RECIPROCAL.MARRIED:
      return <>er gift.</>;

    default:
      return (
        <>
          {renderInput(PLACEHOLDERS.RELATIONSHIP_STATUS, false)}
          {renderInput(PLACEHOLDERS.PARTNER, false)}
        </>
      );
  }
}

function getRelationshipStatus(answers) {
  let relationshipStatus = getAnswerById(RECIPROCAL_QUESTION_IDS.RELATIONSHIP_STATUS, answers);
  if (relationshipStatus && typeof relationshipStatus === "string") {
    relationshipStatus = parseInt(relationshipStatus);
  }
  return relationshipStatus;
}

function getFamilyState(answers) {
  const commonChildren = getHeirsCommon(answers).filter(filterChildren);
  const ownChildren = getHeirsOwn(answers).filter(filterChildren);
  const partnerChildren = getHeirsPartner(answers).filter(filterChildren);

  const hasCommonChildren = commonChildren.length > 0;
  const hasOwnChildren = ownChildren.length > 0;
  const hasPartnerChildren = partnerChildren.length > 0;

  const partnerOverFiveYears = answers.find(
    (a) => a.id === ENUMS.HAS_LIVED_TOGHETER_OVER_FIVE_YEARS.QUESTIONID && a.value === ENUMS.HAS_LIVED_TOGHETER_OVER_FIVE_YEARS.YES
  );

  const hasAnyChildren = hasCommonChildren || hasOwnChildren || hasPartnerChildren;
  const hasOnlyOwn = !hasCommonChildren && hasOwnChildren;

  return {
    hasCommonChildren,
    hasOwnChildren,
    hasPartnerChildren,

    // partnerOverFiveYears,
    hasAnyChildren,
    hasOnlyOwn,

    commonChildren,
    ownChildren,
    partnerChildren,
  };

  function filterChildren({ relation }) {
    return relation === ENUMS.RELATION.CHILD;
  }
}

function getLongestLiving(answers) {
  const [answer = null] = getAnswerById(RECIPROCAL_QUESTION_IDS.LONGEST_LIVING, answers) ?? [];
  if (!answer) {
    return false;
  }
  const { checked = false } = answer;
  return checked;
}

function getLongestLivingDead(answers) {
  const [answer = null] = getAnswerById(RECIPROCAL_QUESTION_IDS.LONGEST_LIVING_DEAD, answers) ?? [];
  if (!answer) {
    return false;
  }
  const { checked = false } = answer;
  return checked;
}

function getRetractWill(answers) {
  const [answer = null] = getAnswerById(RECIPROCAL_QUESTION_IDS.RETRACT_PREVIOUS_WILL, answers) ?? [];
  if (!answer) {
    return false;
  }
  const { checked = false } = answer;
  return checked;
}

function getRetractOwnPreviousWills(answers) {
  const [answer = null] = getAnswerById(RECIPROCAL_QUESTION_IDS.RETRACT_PREVIOUS_WILL_OWN, answers) ?? [];
  if (!answer) {
    return false;
  }
  const { checked = false } = answer;
  return checked;
}

function getRetractPartnerPreviousWills(answers) {
  const [answer = null] = getAnswerById(RECIPROCAL_QUESTION_IDS.RETRACT_PREVIOUS_WILL_PARTNER, answers) ?? [];
  if (!answer) {
    return false;
  }
  const { checked = false } = answer;
  return checked;
}

function getNotChangeLiving(answers) {
  const [answer = null] = getAnswerById(RECIPROCAL_QUESTION_IDS.USKIFTE, answers) ?? [];
  if (!answer) {
    return false;
  }
  const { checked = false } = answer;
  return checked;
}

function getOtherWishes(answers) {
  const funeralWishes = getAnswerById(RECIPROCAL_QUESTION_IDS.OTHER_WISES, answers);

  return (
    <>
      {!!funeralWishes && !!funeralWishes.length && (
        <>
          <h3>Andre ønsker</h3>
          <p>{funeralWishes}</p>
        </>
      )}
    </>
  );
}
