const ANSWERS = {
  UTLANDET: 1,
  FELLES_BARN_JA : 1007,
  FELLES_BARN_NEI : 1008,
  SAERKULLSBARN_JA : 1011,
  SAERKULLSBARN_NEI : 1012,
  SamboerUnderFemÅr : 1014,
  SamboerOverFemÅr : 1015,
  UskiftetBo_Ja : 1016,
  UskiftetBo_Nei : 1017,
  HarTestamentet_Ja : 1019,
  HarTestamentet_Nei : 1020,
  Arvepakt_Ja : 1021,
  Arvepakt_Nei : 1022,

  NO_FOLLOW_LAW_ORDER: 1025, // qid: 130
  NO_FOLLOW_LAW: 1028, // qid: 132

  DISTRIBUTE_SELF_YES: 1026,
  FordelAltJegEierUnderEtt : 1031,
  FordelEnkelteEiendelerEllerBeløp : 1032,
  FordelEiendelerOgRest : 1033,
  Beløp : 1037,
  Eiendel : 1038,
  FordelingAvRestTilEnPerson : 1048,
  FordelingAvRestTilFlere : 1049,
  Demo_Alt_1 : 9014,
  Demo_Alt_3 : 9016,
};

export const INHERITENCE_TYPE = {
  MONEY: 0,
  PROPERTY: 1,
  VESTIGE: 2
};

export const HEIR_TYPE = {
  PERSON: 0,
  ORGANIZATION: 1
}

export const ANSWERS_RECIPROCAL = {
  MARRIED: 1001,
  PARTNER: 1002,

  FELLES_BARN_JA:  1003,
  FELLES_BARN_NEI:  1004,

  SAERKULLSBARN_JA: 1005,
  SAERKULLSBARN_NEI: 1006,

  PARTNER_SAERKULLSBARN_JA: 1007,
  PARTNER_SAERKULLSBARN_NEI: 1008,

  PARTNER_UNDER_FIVE_YEARS_YES: 1012,
  PARTNER_UNDER_FIVE_YEARS_NO: 1013,

  HarTestamentet_Ja: 1011,
  EgenArvepakt_Ja: 1013,

  PartnerTestamentet_Ja: 1015,
  PartnerArvepakt_Ja: 1017,
}

export default ANSWERS;
