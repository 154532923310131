import { Checkbox, FormGroup } from "@material-ui/core";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import React from "react";
import "../../App.scss";

export default function FormCheckbox(props) {
  const { id, type, question, alternatives, setStateValues, answers } = props;

  function onSetValue({ target: { id: valueId, value, checked } }) {
    let newAnswers = [];

    if (answers && answers.value) {
      newAnswers = answers.value.filter((a) => a.id !== valueId);
    }

    newAnswers.push({ id: valueId, value, checked });
    setStateValues(id, type, newAnswers);
  }

  return (
    <div className="formControl">
      {question && <label className="questionLabel">{question}</label>}
      <FormGroup>
        {alternatives.map(({ id, value }, index) => (
          <FormControlLabel
            key={`${id}-${index}`}
            value={`${id}`}
            control={
              <Checkbox
                color="secondary"
                onChange={onSetValue}
                id={id.toString()}
                value={value}
                checked={answers?.value?.find((a) => a.id === id.toString())?.checked ?? null}
              />
            }
            label={<span className="alternative-container">{value}</span>}
          />
        ))}
      </FormGroup>
    </div>
  );
}
