import React, { useContext } from "react";
import "../../../App.scss";
import { FormContextConsumer } from "../../../context/form-context";
import FormElementList from "../../form-element-list";
import ENUMS from "../../../enums";
import UIContext from "../../../context/ui-context";
import Footer from "../../footer/footer";

export default function FormSection({ className = "" }) {
  const {
    state: { mode },
  } = useContext(UIContext);
  const formSectionClassNames = `${className} ${
    mode === ENUMS.UI_STATE.PREVIEW ? "hidden-sm" : ""
  }`;
  return (
    <div className={`section form testament-form ${formSectionClassNames}`}>
      <FormContextConsumer>
        {({ questions = [], text }) => {
          return (
            <div>
              {text && <h1>{text}</h1>}
              <div className="sectionContent">
                <FormElementList questions={questions} />
              </div>
              <Footer />
            </div>
          );
        }}
      </FormContextConsumer>
    </div>
  );
}
