import React, { useState, useEffect } from "react";
import { TextField } from "@material-ui/core";

export default function FractionInput({ onChange, value: percentage = '', isLimited, captionText ="For eksempel 1/3 eller \"Resten\"" }) {
  const [value, setValue] = useState(percentage);

  useEffect(() => {
    setValue(percentage);
  }, [percentage]);

  return (
    <div className="distribution-person-item-fraction">
      <label className="questionLabel">Andel</label>
      <TextField
        fullWidth type="text"
        InputProps={{
         readOnly: isLimited,
        }}
        onBlur={handleBlur}
        onChange={handleKeyUp}
        variant="outlined"
        value={value}
        required
        placeholder=""
        helperText={captionText}
      />
    </div>
  );

  function handleKeyUp(e) {
    const { target = {} } = e;
    const { value } = target;
    const validValue = value
      .replace(/"/gi,'')
      .replace(/'/gi, '');

    setValue(validValue);
  }

  function handleBlur(event) {
    const textValue = event.target.value;
    onChange(textValue);
  }
}
