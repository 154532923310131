import { Button, FormControl, Grid, MenuItem, Select, TextField } from "@material-ui/core";
import React from "react";
import ENUMS from "../../../enums";
import Address from "../address";
import "./person-list.scss";

export default function PersonList(props) {
  const { answer = {}, question, id, setStateValues, relation, min, max, answers = [] } = props;
  const { value: persons = [] } = answer;
  const peopleContentIds = [
    ENUMS.PEOPLEIDS.WIFE,
    ENUMS.PEOPLEIDS.PARTNER,
    ENUMS.PEOPLEIDS.CHILDREN_1,
    ENUMS.PEOPLEIDS.CHILDREN_2,
    ENUMS.PEOPLEIDS.CHILDREN_3,
    ENUMS.PEOPLEIDS.OTHER,
  ];
  const peopleAnswers = answers
    .filter((a) => peopleContentIds.includes(a.id))
    .map((p) => p.value)
    .flat();
  const variant = "outlined";

  const alternatives = [
    { value: "Annen", id: ENUMS.RELATION.OTHER },
    { value: "Organisasjon", id: ENUMS.RELATION.ORGANISATION },
    { value: "Forelder", id: ENUMS.RELATION.PARENT },
    { value: "Barnebarn", id: ENUMS.RELATION.GRANDCHILDREN },
    { value: "Oldebarn", id: ENUMS.RELATION.GREATGRANDCHILDREN },
    { value: "Annen familie", id: ENUMS.RELATION.OTHERFAMILY },
  ];

  const styles = {
    labelWidth: 0,
    backgroundColor: "white",
  };

  if (min && min !== 0 && persons.length < min) {
    persons.push({
      id: `${id}${relation}${persons.length}`, // SSN or ORGnr
      identification: "",
      name: "",
      relation: relation,
    });
  }

  function addPerson(e) {
    e.preventDefault();
    persons.push({
      id: `${id}${relation}${persons.length}`, // SSN or ORGnr
      identification: "",
      name: "",
      relation: relation,
    });
    setStateValues(id, ENUMS.CONTENT_TYPE.PERSON_LIST, persons);
    return false;
  }

  function getRemoveFn(id, index, persons) {
    return () => {
      setStateValues(
        id,
        ENUMS.CONTENT_TYPE.PERSON_LIST,
        persons.filter((v, i) => i !== index)
      );
    };
  }

  function getUpdatePersonFunction(id, index, persons) {
    return ({ target: { name, value } }) => {
      if (name === "noAutoComplete") {
        name = "name"
      }


      if (typeof value === "string") {
        const validValue = (value ?? '')
          .replace(/"/gi, '')
          .replace(/'/gi, '');
        persons[index][name.replace('_', '')] = validValue;
      }
      else {
        persons[index][name.replace('_', '')] = value;
      }


      setStateValues(id, ENUMS.CONTENT_TYPE.PERSON_LIST, persons);
    };
  }

  function getUpdateAddressFunction(id, index, persons) {
    return (name, type, value) => {
      persons[index][name.replace('_', '')] = { ...persons[index][name], ...value };
      setStateValues(id, type, persons);
    };
  }

  function getPossibleParents(relation) {
    var parentRelation;
    switch (relation) {
      case ENUMS.RELATION.GRANDCHILDREN:
        parentRelation = ENUMS.RELATION.CHILD;
        break;
      case ENUMS.RELATION.GREATGRANDCHILDREN:
        parentRelation = ENUMS.RELATION.GRANDCHILDREN;
        break;
      default:
        console.error(`No parant relation mapping possible for: ${relation}`);
        return [];
    }
    return peopleAnswers.filter((p) => p.relation === parentRelation);
  }

  function getCaptionText(relation) {
    switch (relation) {
      case ENUMS.RELATION.MARRIED:
        return "Skriv ektefelle fulle navn";
      case ENUMS.RELATION.PARTNER:
        return "Skriv samboers fulle navn";
      case ENUMS.RELATION.OTHER:
      case ENUMS.RELATION.PARENT:
      case ENUMS.RELATION.OTHERFAMILY:
        return "Skriv personens fulle navn ";
      case ENUMS.RELATION.ORGANISATION:
        return "Skriv organisasjonens fulle navn";
      case ENUMS.RELATION.CHILD:
      case ENUMS.RELATION.GRANDCHILDREN:
      case ENUMS.RELATION.GREATGRANDCHILDREN:
        return "Skriv barnets fulle navn";
      default:
        return "";
    }
  }

  function getNameLabel(relation) {
    switch (relation) {
      case ENUMS.RELATION.MARRIED:
        return "Ektefelles navn";
      case ENUMS.RELATION.PARTNER:
        return "Samboers navn";
      case ENUMS.RELATION.PARTNER:
      case ENUMS.RELATION.OTHER:
      case ENUMS.RELATION.ORGANISATION:
      case ENUMS.RELATION.OTHERFAMILY:
        return "Navn";
      case ENUMS.RELATION.CHILD:
      case ENUMS.RELATION.GRANDCHILDREN:
      case ENUMS.RELATION.GREATGRANDCHILDREN:
        return "Barnets navn";
      default:
        return "Navn";
    }
  }

  const relationPickerHtml = (index) => {
    const person = persons[index];
    return (
      <Grid item xs={12} sm={4}>
        <FormControl fullWidth variant="outlined">
          <label className="questionLabel">Relasjon</label>
          <Select style={styles} name="relation" value={person.relation} onChange={getUpdatePersonFunction(id, index, persons)} placeholder="Velg...">
            {alternatives.map(({ id: valueId, value }, i) => (
              <MenuItem key={i} value={valueId} name={value}>
                {value}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>
    );
  };

  const identificationHtml = (index) => {
    const person = persons[index];
    const relation = person.relation;
    switch (relation) {
      case ENUMS.RELATION.MARRIED:
      case ENUMS.RELATION.PARTNER:
        return (
          <Grid item xs={12} sm={4}>
            <label className="questionLabel">Født</label>
            <TextField
              fullWidth
              name="identification"
              defaultValue={person?.identification}
              id={`id-${index}`}
              onBlur={getUpdatePersonFunction(id, index, persons)}
              variant={variant}
              helperText="F. eks. 241205"
              placeholder="ddmmåå"
            />
          </Grid>
        );
      case ENUMS.RELATION.ORGANISATION:
        return (
          <Grid item xs={12} sm={6}>
            <label className="questionLabel">Organisasjonsnummer</label>
            <TextField
              fullWidth
              name="identification"
              defaultValue={person?.identification}
              id={`id-${index}`}
              onBlur={getUpdatePersonFunction(id, index, persons)}
              variant={variant}
              helperText="Ni siffer"
            />
          </Grid>
        );
      case ENUMS.RELATION.CHILD:
      case ENUMS.RELATION.GRANDCHILDREN:
      case ENUMS.RELATION.GREATGRANDCHILDREN:
        return (
          <>
            <Grid item xs={12} sm={4}>
              <label className="questionLabel">Født</label>
              <TextField
                fullWidth
                name="identification"
                defaultValue={person?.identification}
                id={`id-${index}`}
                onBlur={getUpdatePersonFunction(id, index, persons)}
                variant={variant}
                helperText="F. eks. 241205"
              />
            </Grid>
            {(relation === ENUMS.RELATION.GRANDCHILDREN || relation === ENUMS.RELATION.GREATGRANDCHILDREN) && (
              <Grid item xs={12} sm={6}>
                <FormControl fullWidth variant="outlined">
                  <label className="questionLabel">Forelder</label>
                  <Select style={styles} name="parent" value={person.parent} onChange={getUpdatePersonFunction(id, index, persons)} placeholder="Velg...">
                    {getPossibleParents(relation).map(({ id: valueId, name }, i) => (
                      <MenuItem key={i} value={valueId} name={name}>
                        {name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
            )}
          </>
        );
      default:
        return (
          <Grid item xs={12} sm={10}>
            <Address
              id="address"
              answer={person && person.address && { value: person.address }}
              setStateValues={getUpdateAddressFunction(id, index, persons)}
              type={ENUMS.CONTENT_TYPE.PERSON_LIST}
            />
          </Grid>
        );
    }
  };

  const isMax = () => max && persons.length === max;
  const isMin = () => (min && min === 0) || persons.length !== min;

  return (
    <div className="person-list">
      {question !== "" && (
        <div className="textFormControl">
          <h3>{question}</h3>
        </div>
      )}
      <div className="person-list-inner">
        {persons.map(({ name, relation }, index) => (
          <Grid className="person-list-container" container spacing={2} key={`${name}-${index}`} direction="row" justify="flex-start" alignItems="flex-start">
            <Grid item xs={12} sm={6}>
              <label className="questionLabel">{getNameLabel(relation)}</label>
              <TextField
                name="noAutoComplete"
                id={`name-${index}`}
                defaultValue={name}
                fullWidth
                onBlur={getUpdatePersonFunction(id, index, persons)}
                variant={variant}
                helperText={getCaptionText(relation)}
              />
            </Grid>
            {relation !== ENUMS.RELATION.MARRIED && relation !== ENUMS.RELATION.PARTNER && relation !== ENUMS.RELATION.CHILD && relationPickerHtml(index)}
            {identificationHtml(index)}
            {isMin() && (
              <Grid item xs={12} sm={2}>
                <Button color="secondary" style={{ marginTop: "26px" }} onClick={getRemoveFn(id, index, persons)}>
                  Fjern
                </Button>
              </Grid>
            )}
          </Grid>
        ))}
      </div>
      {!isMax() && (
        <div className="person-list-add">
          <Button variant="outlined" className="secondaryButton" color="secondary" onClick={addPerson}>
            + Legg til
          </Button>
        </div>
      )}
    </div>
  );
}
