import { parse } from "date-fns";
import React, { useEffect, useState } from "react";
import Header from "../components/header/header";
import Chart from "../components/statistics/chart";
import getStatisticsData, { getLifeWillStatisticsData } from "../services/statistics-data-service";
import { defaultPalette } from "../util/defaultPalette";
import formatCurrency from "../util/format-currency";

const colorPallet = ["#39134c", "#653c78", "#190024"];
const secColor = ["#aecda8", "#e0ffda", "#7e9c79"];
const redColor = ["#8c1c2b", "#c14e54", "#590000"];
const lightColor = ["#ffe1b7", "#ffffe9", "#cbaf87"];

const testamentSeries = [
  { name: "total", color: colorPallet[1] },
  { name: "gratis", color: lightColor[2] },
  { name: "betalt", color: redColor[1] },
  { name: "utkast", color: secColor[2] },
];

const lifeWillSeries = [
  { name: "total", color: colorPallet[1] },
  { name: "gratis", color: lightColor[2] },
  { name: "betalt", color: redColor[1] },
  { name: "utkast", color: secColor[2] },
];

export default function StatisticsPage() {
  const [stats, setStats] = useState();

  const [testamentData, setTestamentData] = useState([]);
  const [donationData, setDonationsChartData] = useState([]);
  const [donationSeries, setDonationsChartSeries] = useState([]);

  const [lifeWillStats, setLifeWillStats] = useState();
  const [lifeWillData, setLifeWillData] = useState([]);
  const [lifeWillDonationData, setLifeWillDonationData] = useState([]);
  const [lifeWillDonationSeries, setLifeWillDonationSeries] = useState([]);

  useEffect(() => {
    getStatisticsData().then((data) => {
      setStats(data);

      if (data.testaments && data.testaments.length > 0) {
        //TODO: sort? ,rename shit in the backend
        setTestamentData(formatChartData(data.testaments));

        const sortedDonationData = transformAndSortDonationData(data.donations);
        const donationSeries = generateDonationsSeries(sortedDonationData);
        setDonationsChartSeries(donationSeries);
        setDonationsChartData(formatDonationsChartData(sortedDonationData, donationSeries));
      }
    });

    getLifeWillStatisticsData().then((data) => {
      setLifeWillStats(data);

      if (data.lifeWills && data.lifeWills.length > 0) {
        setLifeWillData(formatChartData(data.lifeWills));

        const sortedDonationData = transformAndSortDonationData(data.donations);
        const donationSeries = generateDonationsSeries(sortedDonationData);
        setLifeWillDonationSeries(donationSeries);
        setLifeWillDonationData(formatDonationsChartData(sortedDonationData, donationSeries));
      }
    });
  }, [setStats, setTestamentData]);

  const transformAndSortData = (data) => {
    return data
      .map((t) => {
        return { date: new Date(t.item1), value: t.item2 };
      })
      .sort((a, b) => (a.date > b.date ? 1 : -1));
  };

  const transformAndSortDonationData = (data) => {
    return data
      .map((t) => {
        return { date: parseDate(t.item1), name: t.item2, value: t.item3 };
      })
      .sort((a, b) => (a.date > b.date ? 1 : -1));
  };

  const formatChartData = (data) => {
    const formData = transformAndSortData(data);

    let count = 0;
    let payed = 0;
    let free = 0;
    let draft = 0;

    return formData.map((d) => {
      switch (d.value) {
        case 0:
          draft++;
          break;
        case 1:
          payed++;
          break;
        case 2:
          free++;
          break;
        default:
          console.warn("Value outside of paymentstatus range: " + d.item2);
      }
      count++;
      return { date: d.date, paymentStatus: d.value, total: count, betalt: payed, gratis: free, utkast: draft };
    });
  };

  const generateDonationsSeries = (data) => {
    const t = {};
    var serieNames = data.map((d) => d.name).filter((d) => !(t[d] = d in t));
    let i = 1;

    const series = serieNames.map((s) => {
      return { name: s, color: defaultPalette[500][(i = i + 2)] };
    });

    return [{ name: "total", color: defaultPalette[500][0] }, ...series];
  };

  const formatDonationsChartData = (data, series) => {
    const dontationsDict = {};
    series.forEach((s) => {
      dontationsDict[s.name] = 0;
    });
    return data.map((d) => {
      dontationsDict[d.name] = parseInt(dontationsDict[d.name]) + parseInt(d.value);
      dontationsDict["total"] = parseInt(dontationsDict["total"]) + parseInt(d.value);
      return { date: d.date, ...dontationsDict };
    });
  };

  const parseDate = (dateString) => {
    if (dateString.includes("/")) {
      return parse(dateString, "MM/dd/yyyy", new Date());
    } else {
      return parse(dateString, "dd.MM.yyyy", new Date());
    }
  };

  return (
    <main className="stats">
      <Header hasPreview={false} />
      <section className="section-content">
        <div className="section form">
          <h2>Testament statistikk</h2>
          {stats && (
            <>
              <table>
                <thead>
                  <tr>
                    <th width="75%"></th>
                    <th width="25%"></th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>Antall brukere</td>
                    <td>{stats.userCount}</td>
                  </tr>
                  <tr>
                    <td>Antall samtykker</td>
                    <td>{stats.researchConsent}</td>
                  </tr>
                  <tr>
                    <td>Antall nyhetsbrev</td>
                    <td>{stats.newsletterConsent}</td>
                  </tr>
                  <tr>
                    <td>Antall kvalitetssikring</td>
                    <td>{stats.qualityCheckEmailCount}</td>
                  </tr>
                  <tr>
                    <td>Antall leads</td>
                    <td>{stats.emailLeadsCount}</td>
                  </tr>
                </tbody>
              </table>
              <table>
                <thead>
                  <tr>
                    <th width="75%"></th>
                    <th width="25%"></th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>Antall testamenter</td>
                    <td>{stats.totalTestamentCount}</td>
                  </tr>
                  <tr>
                    <td className="indented">Normale</td>
                    <td>{stats.normalTestamentCount}</td>
                  </tr>
                  <tr>
                    <td className="indented">Gjensidige</td>
                    <td>{stats.recipricalTestamentCount}</td>
                  </tr>
                </tbody>
              </table>
              <table>
                <thead>
                  <tr>
                    <th width="75%"></th>
                    <th width="25%"></th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>Status på testamenter etter 22.02.22</td>
                    <td>{stats.unfinishedTestamentCount + stats.freeTestamentCount + stats.payedTestamentCount}</td>
                  </tr>
                  <tr>
                    <td className="indented">Utkast</td>
                    <td>{stats.unfinishedTestamentCount}</td>
                  </tr>
                  <tr>
                    <td className="indented">Gratis</td>
                    <td>{stats.freeTestamentCount}</td>
                  </tr>
                  <tr>
                    <td className="indented">Betalte</td>
                    <td>{stats.payedTestamentCount}</td>
                  </tr>
                </tbody>
              </table>
              <table>
                <thead>
                  <tr>
                    <th width="75%"></th>
                    <th width="25%"></th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>Testamenter med testamentarisk gave</td>
                    <td>{stats.donationsCount}</td>
                  </tr>
                  <tr>
                    <td className="indented">Andel som gave</td>
                    <td>{stats.donationsShareCount}</td>
                  </tr>
                  <tr>
                    <td className="indented">Beløp som gave</td>
                    <td>{stats.donationsAmountCount}</td>
                  </tr>
                  <tr>
                    <td>Sum beløp som gave</td>
                    <td>{formatCurrency(stats.totalDonationsSum)}</td>
                  </tr>
                  {stats.donationsSums &&
                    stats.donationsSums.map((d, i) => (
                      <tr key={i}>
                        <td className="indented">{d.item1}</td>
                        <td>{formatCurrency(d.item2)}</td>
                      </tr>
                    ))}
                </tbody>
              </table>
            </>
          )}
        </div>
        <Chart title={"Status på testamenter etter 22.02.22"} series={testamentSeries} data={testamentData}></Chart>
        <Chart title={"Status på donasjoner 2022"} series={donationSeries} data={donationData}></Chart>
      </section>
      <section className="section-content">
        <div className="section form">
          <h2>Fremtidsfullmakt statistikk</h2>
          {lifeWillStats && (
            <>
              <table>
                <thead>
                  <tr>
                    <th width="75%"></th>
                    <th width="25%"></th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>Antall fremtidsfullmakt brukere</td>
                    <td>{lifeWillStats.userCount}</td>
                  </tr>
                </tbody>
              </table>
              <table>
                <thead>
                  <tr>
                    <th width="75%"></th>
                    <th width="25%"></th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>Antall fremtidsfullmakter</td>
                    <td>{lifeWillStats.totalCount}</td>
                  </tr>
                  <tr>
                    <td className="indented">Normale</td>
                    <td>{lifeWillStats.normalCount}</td>
                  </tr>
                  <tr>
                    <td className="indented">Gjensidige</td>
                    <td>{lifeWillStats.recipricalCount}</td>
                  </tr>
                </tbody>
              </table>
              <table>
                <thead>
                  <tr>
                    <th width="75%"></th>
                    <th width="25%"></th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>Status på fremtidsfullmakt</td>
                    <td>{lifeWillStats.unfinishedCount + lifeWillStats.payedCount}</td>
                  </tr>
                  <tr>
                    <td className="indented">Utkast</td>
                    <td>{lifeWillStats.unfinishedCount}</td>
                  </tr>
                  <tr>
                    <td className="indented">Gratis</td>
                    <td>{lifeWillStats.payedCount}</td>
                  </tr>
                  <tr>
                    <td className="indented">Betalte</td>
                    <td>{lifeWillStats.payedCount}</td>
                  </tr>
                </tbody>
              </table>
              <table>
                <thead>
                  <tr>
                    <th width="75%"></th>
                    <th width="25%"></th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>Fremtidsfullmakter med gave til godeformål</td>
                    <td>{lifeWillStats.donationsCount}</td>
                  </tr>
                  <tr>
                    <td className="indented">Antall engangsgaver</td>
                    <td>{lifeWillStats.donationsOnetimeCount}</td>
                  </tr>
                  <tr>
                    <td className="indented">Antall årlig gaver</td>
                    <td>{lifeWillStats.donationsRecurringCount}</td>
                  </tr>
                  <tr>
                    <td>Sum engangsgaver</td>
                    <td>{formatCurrency(lifeWillStats.donationsOnetimeSum)}</td>
                  </tr>
                  {lifeWillStats.donationsOnetimeSums &&
                    lifeWillStats.donationsOnetimeSums.map((d, i) => (
                      <tr key={i}>
                        <td className="indented">{d.item1}</td>
                        <td>{formatCurrency(d.item2)}</td>
                      </tr>
                    ))}
                  <tr>
                    <td>Sum årlig gaver</td>
                    <td>{formatCurrency(lifeWillStats.donationsRecurringSum)}</td>
                  </tr>
                  {lifeWillStats.donationsReccuringSums &&
                    lifeWillStats.donationsReccuringSums.map((d, i) => (
                      <tr key={i}>
                        <td className="indented">{d.item1}</td>
                        <td>{formatCurrency(d.item2)}</td>
                      </tr>
                    ))}
                </tbody>
              </table>
            </>
          )}
        </div>
        <Chart title={"Status på fremtidsfullmakter"} series={lifeWillSeries} data={lifeWillData}></Chart>
        <Chart title={"Status på donasjoner 2022"} series={lifeWillDonationSeries} data={lifeWillDonationData}></Chart>
      </section>
    </main>
  );
}
