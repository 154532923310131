import React, { useState } from "react";
import { withStyles } from "@material-ui/core/styles";
import Modal from "@material-ui/core/Modal";
import Button from "@material-ui/core/Button";
import { TextField } from "@material-ui/core";
import { postEmail } from "../../../services/email-data-service";

function getModalStyle() {
  const top = 50; // + rand();
  const left = 50; // + rand();

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}

const styles = (theme) => ({
  paper: {
    position: "absolute",
    width: "100%",
    maxWidth: theme.spacing(75),
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    outline: "none",
    maxHeight: "95vh",
    overflowY: "scroll",
  },
  head: {
    padding: theme.spacing(4, 4, 0, 4),
    backgroundColor: theme.palette.background.paper,
    textAlign: "left",
    fontFamily: "'Cabin', sans-serif",
  },
  body: {
    padding: theme.spacing(4),
    backgroundColor: "#f4f4f4",
  },
  foot: {
    padding: theme.spacing(4, 4, 4),
    backgroundColor: "white",
    display: "flex",
    flexDirection: "column",
    alignItems: "inherit",
  },

  email: {
    marginBottom: "10px",
    minWidth: "33%",
  },
  textBox: {
    width: "100%",
    marginBottom: "1rem",
    backgroundColor: "white",
  },
  cancel: {
    marginTop: "1rem",
  },
});

function LawyerModal(props) {
  const { classes, element, testamentId } = props;
  const { emailType, title, modalText } = element;
  const [open, setOpen] = useState(false);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [message, setMessage] = useState("");

  const handleSendEmail = () => {
    postEmail(emailType, name, email, phoneNumber, message, testamentId);
    setOpen(false);
  };

  const btn = {
    text: "",
    action: "",
    endpoint: "",
    size: "large",
    variant: "contained",
    className: "secondaryButton",
    color: "secondary",
  };

  return (
    <div className="formControl">
      <Button size={btn.size} variant="outlined" className={btn.className} color={btn.color} onClick={() => setOpen(true)}>
        {title}
      </Button>

      <Modal aria-labelledby="Kontakt Advokat" aria-describedby="description" open={open} onClose={() => setOpen(false)}>
        <div style={getModalStyle()} className={classes.paper}>
          <div className={classes.innerModal}>
            <div className={classes.head}>
              <h2>{title}</h2>
              {modalText.map((text, i) => (
                <p key={i}>{text}</p>
              ))}
            </div>
            <div className={classes.body}>
              <TextField
                variant="outlined"
                label="Navn"
                fullWidth
                className={classes.textBox}
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
              <TextField
                variant="outlined"
                label="Telefon"
                fullWidth
                className={classes.textBox}
                value={phoneNumber}
                onChange={(e) => setPhoneNumber(e.target.value)}
              />
              <TextField
                variant="outlined"
                label="Epost"
                fullWidth
                className={classes.textBox}
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
              <TextField
                multiline
                variant="outlined"
                label="Melding"
                rows="6"
                className={classes.textBox}
                value={message}
                onChange={(e) => setMessage(e.target.value)}
              />
            </div>
            <div className={classes.foot}>
              <Button size={btn.size} variant="contained" className="primaryButton" color="secondary" onClick={handleSendEmail}>
                Send
              </Button>
              <Button
                size={btn.size}
                variant="outlined"
                className={`${btn.secondaryButton} ${classes.cancel}`}
                color={btn.color}
                onClick={() => setOpen(false)}
              >
                Avbryt
              </Button>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
}

// // We need an intermediary variable for handling the recursive nesting.
export default withStyles(styles)(LawyerModal);
