import { deleteKey, get, set } from "./local-storage-service";
import getProfileData, { postBid } from "./user-data-service";

const LOCAL_BANKID_KEY = "testamentbid";
const LOCAL_BID = "bid";

export default function bankIdService() {
    getProfileData().then(user => {
        const localData = get(LOCAL_BANKID_KEY);
        const bidResult = window.location.search === "?bid=true";

        if (user?.bid) {
            set({
                Valid: true
            }, LOCAL_BID);

            if (window.location.href === window.location.origin + "/" && user.testaments?.length > 0) {
                window.location.replace('/minside');
            } else {
                window.location.href = window.location.href;
            }

            return;
        }

        if (!user?.bid && !bidResult) {
            set({
                Url: window.location.href
            }, LOCAL_BANKID_KEY);

            window.location.href = process.env.REACT_APP_BID_URL;
            return;
        }

        if (bidResult) {
            const url = localData.Url;
            postBid(true).then(() => {
                set({
                    Valid: true
                }, LOCAL_BID);

                deleteKey(LOCAL_BANKID_KEY);
                if (url === window.location.origin && user.testaments?.length > 0) {
                    window.location.replace('/minside');
                }
                window.location.href = url;
            });
        }
    });
}