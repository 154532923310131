import React from "react";

export default function ImageContent(props) {
  const { name, url } = props;
const baseUrl = "https://testamentertserver.azurewebsites.net"
  return (
    <div className="imageContent">
      <object className="logo" data={baseUrl + url + ".svg"} type="image/svg+xml">
        <img src={baseUrl + url + ".png"} alt={name} />
        {/* <p class="warning">Your browser does not support SVG!</p> */}
      </object>
    </div>
  );
}
