import React, { useEffect, useState } from "react";
import { get } from "../../services/local-storage-service";
import getProfileData from "../../services/user-data-service";
import LoaderSpinner from "../loader/loader";
import bankIdService from "../../services/bankid-service";

const LOCAL_BID = "bid";

export default function OIDCWrapper({ children }) {
  const [isLoading, setLoading] = useState(true);
  const localData = get(LOCAL_BID);

  useEffect(() => {
    if (!localData || !localData.Valid) {
      bankIdService();
    } else {
      if (window.location.href === window.location.origin + "/") {               
          getProfileData().then((user) => {
            if (
              window.location.href === window.location.origin + "/" &&
              user.testaments?.length > 0
            ) {
              window.location.replace("/minside");
            } else {
              setLoading(false);
            }
          });
      } else {
        setLoading(false);
      }
    }
  }, [localData?.Valid]);

  if (isLoading || (localData && !localData.Valid)) {
    return <LoaderSpinner />;
  }

  return <>{children}</>;
}
