import { FormControl, MenuItem, Select, TextField } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import ENUMS from "../../../enums";
import "./organisation-distribution.scss";

const styles = {
  width: "250px",
  labelWidth: 0,
  backgroundColor: "white",
};

export default function OrganisationDistribution(props) {
  const { id, type, organisations = [], answer, setStateValues } = props;
  const [valueType, setValueType] = useState(ENUMS.DONATIONTYPE.MONEY);
  const variant = "outlined";

  useEffect(() => {
    const contentValues = [];
    organisations.forEach((org) => {
      const organisationValues = org.value.map((v) => v.value[0].value);
      organisationValues
        .filter(({ value }) => !!value)
        .forEach((item) => {
          if (item !== "") {
            contentValues.push({
              id: item.value.Id,
              name: item.value.Name,
              organisationId: item.value.Value,
              value: "",
              type: ENUMS.DONATIONTYPE.NONE,
            });
          }
        });
    });

    if (answer && answer.value.length !== 0) {
      const answers = answer.value;

      if (answers.length !== contentValues.length) {
        let combinedAnswers = [];

        if (answers.length < contentValues.length) {
          const existingIds = answers.map((a) => a.id);
          const neworganisations = contentValues.filter((f) => !existingIds.includes(f.id));
          combinedAnswers = [...answers, ...neworganisations];
        } else {
          combinedAnswers = answers.filter((a) => contentValues.includes(a.id));
        }
        setStateValues(id, ENUMS.CONTENT_TYPE.ORGANISATION_DISTRIBUTION, combinedAnswers);
      } else {
        const newContentValues = contentValues.map((c, i) => {
          const currentAnswer = answers[i];
          return { ...c, value: currentAnswer.value, type: currentAnswer.type };
        });
        if (!answers.every((a, i) => JSON.stringify(a) === JSON.stringify(newContentValues[i]))) {
          setStateValues(id, ENUMS.CONTENT_TYPE.ORGANISATION_DISTRIBUTION, newContentValues);
        }
      }
    } else {
      if (contentValues.length !== 0) {
        setStateValues(id, ENUMS.CONTENT_TYPE.ORGANISATION_DISTRIBUTION, contentValues);
      }
    }
  }, [organisations, answer, id, setStateValues]);

  function onChange(subId) {
    return (e) => {
      let newAnswerValues = [];
      const validValue = (e.target.value ?? "").replace(/"/gi, "").replace(/'/gi, "");

      answer.value.forEach((a) => {
        newAnswerValues.push(a.id === subId ? { ...a, value: validValue } : a);
      });
      setStateValues(id, type, newAnswerValues);
    };
  }

  const handleDonationTypeChange = (subId) => {
    return ({ target: { value = null } }) => {
      let newAnswerValues = [];
      answer.value.forEach((a) => {
        newAnswerValues.push(a.id === subId ? { ...a, type: value } : a);
      });
      setStateValues(id, type, newAnswerValues);
    };
  };

  return (
    <div>
      {answer?.value?.map((item) => (
        <div className="list-item organisation-items" key={item.id}>
          <div className="organisation-item">
            <label className="questionLabel">Mottaker av gave</label>
            <TextField
              type="text"
              fullWidth
              InputProps={{
                readOnly: true,
              }}
              variant={variant}
              defaultValue={item.name}
            />
          </div>
          <div className="item">
            <label className="questionLabel">Skal motta</label>
            <FormControl className="dropdown" variant="outlined">
              <Select style={styles} value={item.type ?? ENUMS.DONATIONTYPE.NONE} onChange={handleDonationTypeChange(item.id)} placeholder="Velg ...">
                <MenuItem disabled value={ENUMS.DONATIONTYPE.NONE}>
                  <em>Velg ...</em>
                </MenuItem>
                <MenuItem value={ENUMS.DONATIONTYPE.MONEY} name="beløp">
                  Beløp
                </MenuItem>
                <MenuItem value={ENUMS.DONATIONTYPE.PROSENTAGE} name="andel">
                  Andel
                </MenuItem>
              </Select>
            </FormControl>
          </div>
          {item.type !== undefined && item.type !== null && item.type !== "" && item.type !== 0 && (
            <div>
              <label className="questionLabel">{item.type === ENUMS.DONATIONTYPE.MONEY ? "Beløp" : "Andel"}</label>
              <TextField
                type="text"
                onBlur={onChange(item.id)}
                defaultValue={item.value}
                required
                variant={variant}
                helperText={item.type === ENUMS.DONATIONTYPE.MONEY ? "Beløp i hele kroner" : "For eksempel 1/3 eller \"Resten\""}
              />
            </div>
          )}
        </div>
      ))}
    </div>
  );
}
