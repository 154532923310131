import React, { useRef, useState } from "react";
import { withStyles } from "@material-ui/core/styles";
import Modal from "@material-ui/core/Modal";
import Button from "@material-ui/core/Button";
import { TextField } from "@material-ui/core";
import { postFormPrint } from "../../../services/form-data-service";

function getModalStyle() {
    const top = 50; // + rand();
    const left = 50; // + rand();

    return {
        top: `${top}%`,
        left: `${left}%`,
        transform: `translate(-${top}%, -${left}%)`,
    };
}

const styles = (theme) => ({
    paper: {
        position: "absolute",
        width: "100%",
        maxWidth: theme.spacing(75),
        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.shadows[5],
        outline: "none",
        maxHeight: "95vh",
        overflowY: "scroll",
    },
    head: {
        padding: theme.spacing(4, 4, 0, 4),
        backgroundColor: theme.palette.background.paper,
        textAlign: "left",
        fontFamily: "'Cabin', sans-serif",
    },
    body: {
        padding: theme.spacing(4),
        backgroundColor: "#f4f4f4",
    },
    foot: {
        padding: theme.spacing(4, 4, 4),
        backgroundColor: "white",
        display: "flex",
        flexDirection: "column",
        alignItems: "inherit",
    },

    email: {
        marginBottom: "10px",
        minWidth: "33%",
    },
    textBox: {
        width: "100%",
        marginBottom: "1rem",
        backgroundColor: "white",
    },
    cancel: {
        marginTop: "1rem",
    },
});

function PrintModal(props) {
    const { classes, element, testamentId } = props;
    const { title, modalText, modalSentText } = element;
    const [open, setOpen] = useState(false);
    const [formValues, setFormValues] = useState({ name: '', email: '', address: '', postCode: '', postArea: '' });
    const [sent, setSent] = useState(false);
    const [errors, setErrors] = useState({});

    const handleChange = (event) => {
        setFormValues({ ...formValues, [event.target.name]: event.target.value });
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        let newErrors = {};

        // Validation: check for required fields
        if (!formValues.name) {
            newErrors.name = 'Navn er påkrevd';
        }
        if (!formValues.address) {
            newErrors.address = 'Adresse er påkrevd';
        }
        if (!formValues.postCode) {
            newErrors.postCode = 'Postnr er påkrevd';
        }
        if (!formValues.postArea) {
            newErrors.postArea = 'Poststed er påkrevd';
        }
        if (!formValues.email) {
            newErrors.email = 'Epost er påkrevd';
        }

        setErrors(newErrors);

        if (Object.keys(newErrors).length === 0) {
            postFormPrint(testamentId, formValues.name, formValues.address, formValues.postArea, formValues.postCode, formValues.email);
            setSent(true);
        }
    };

    const btn = {
        text: "",
        action: "",
        endpoint: "",
        size: "large",
        variant: "contained",
        className: "secondaryButton",
        color: "secondary",
    };

    return (
        <div className="formControl">
            <Button size={btn.size} variant="outlined" className={btn.className} color={btn.color} onClick={() => setOpen(true)}>
                {title}
            </Button>

            <Modal aria-labelledby={title} aria-describedby="description" open={open} onClose={() => setOpen(false)}>
                <div style={getModalStyle()} className={classes.paper}>
                    <div className={classes.innerModal}>
                        <div className={classes.head}>
                            <h2>{title}</h2>
                            {sent === false && modalText.map((text, i) => (
                                <p key={i}>{text}</p>
                            ))}
                            {sent === true && modalSentText.map((text, i) => (
                                <p key={i}>{text}</p>
                            ))}
                        </div>
                        {sent === false && <>
                            <form className={classes.body}>
                                <TextField
                                    name="name"
                                    variant="outlined"
                                    label="Navn"
                                    fullWidth
                                    className={classes.textBox}
                                    value={formValues.name}
                                    onChange={handleChange}
                                    required
                                    error={!!errors.name}
                                />
                                <TextField
                                    name="address"
                                    variant="outlined"
                                    label="Adresse"
                                    fullWidth
                                    className={classes.textBox}
                                    value={formValues.address}
                                    onChange={handleChange}
                                    required
                                    error={!!errors.address}
                                />
                                <TextField
                                    name="postCode"
                                    variant="outlined"
                                    label="Postnr"
                                    fullWidth
                                    className={classes.textBox}
                                    value={formValues.postCode}
                                    onChange={handleChange}
                                    required
                                    error={!!errors.postCode}
                                />
                                <TextField
                                    name="postArea"
                                    variant="outlined"
                                    label="Poststed"
                                    fullWidth
                                    className={classes.textBox}
                                    value={formValues.postArea}
                                    onChange={handleChange}
                                    required
                                    error={!!errors.postArea}
                                />
                                <TextField
                                    name="email"
                                    variant="outlined"
                                    label="Epost"
                                    fullWidth
                                    className={classes.textBox}
                                    value={formValues.email}
                                    onChange={handleChange}
                                    required
                                    error={!!errors.email}
                                />
                            </form>
                            <div className={classes.foot}>
                                <Button
                                    size={btn.size}
                                    variant="contained"
                                    className="primaryButton"
                                    color="secondary"
                                    onClick={handleSubmit}
                                >
                                    Send
                                </Button>
                                <Button
                                    size={btn.size}
                                    variant="outlined"
                                    className={`${btn.secondaryButton} ${classes.cancel}`}
                                    color={btn.color}
                                    onClick={() => setOpen(false)}
                                >
                                    Avbryt
                                </Button>
                            </div>
                        </>}
                        {sent && <div className={classes.foot}>
                            <Button size={btn.size} variant="contained" className="primaryButton" color="secondary" onClick={() => setOpen(false)}>
                                Lukk
                            </Button>
                        </div>}
                    </div>
                </div>
            </Modal >
        </div >
    );
}

// // We need an intermediary variable for handling the recursive nesting.
export default withStyles(styles)(PrintModal);
