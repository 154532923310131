import React from "react";
import Header from "../components/header/header";
import { Grid } from "@material-ui/core";
import { useLocation } from "react-router-dom";
import TestamentCardList from "../components/dashboard-elements/testament-cards/testament-card-list";
import ENUMS from "../enums";
import TestamentCard from "../components/dashboard-elements/testament-cards/testament-card";

export default function TestamentTypePage() {
  const { search } = useLocation();

  function onClick(type) {
    const url = search ? type + search : type;
    window.location.replace(url);
  }

  return (
    <main>
      <Header externalHome={false} />
      <section className="section-content">
        <div className="section form">
          <Grid container spacing={0} justify="center">
            <Grid item xs={12} sm={8}>
              <Grid container justify="center">
                <Grid item>
                  <h1>Lag arvedokument</h1>
                </Grid>
                <Grid item xs={12} sm={10}>
                  <p className="ingress">Velg mellom personlig testament, gjensidig testament, fremtidsfullmakt og gjensidig fremtidsfullmakt.</p>
                </Grid>
              </Grid>

              <Grid container justify="center">
                <Grid item>
                  <h3>Testament</h3>
                </Grid>
              </Grid>
              <div className="teasers">
                <TestamentCard isNew={true} formType={ENUMS.FORM_TYPE.NORMAL} />
                <TestamentCard isNew={true} formType={ENUMS.FORM_TYPE.RECIPROCAL} />
              </div>
              <Grid container justify="center">
                <Grid item>
                  <h3>Fremtidsfullmakt</h3>
                </Grid>
              </Grid>
              <div className="teasers">
                <TestamentCard isNew={true} formType={ENUMS.FORM_TYPE.LIFEWILL} />
                <TestamentCard isNew={true} formType={ENUMS.FORM_TYPE.RECIPROCAL_LIFEWILL} />
              </div>
            </Grid>
          </Grid>
        </div>
      </section>
    </main>
  );
}
