import React, { useContext } from "react";
import UIContext from "../../../../context/ui-context";
import ENUMS from "../../../../enums";
import { getAnswerById, isTrueByIdAndAnswerValue } from "../../../../util/answers/answers-helper";
import { LIFEWILL_QUESTION_IDS, LIFEWILL_VALUE_IDS } from "../QUESTION_MAP";
import formatCurrency from "../../../../util/format-currency";

export default function PreviewLifeWill({ answers }) {
  const {
    state: { mode },
  } = useContext(UIContext);
  const className = mode === ENUMS.UI_STATE.FORM ? "hidden-sm" : "";

  const { person = {} } = getAnswerById(LIFEWILL_QUESTION_IDS.USER, answers) || {};
  const { name = null, identification = "" } = person;

  const proxyAnswers = getAnswerById(LIFEWILL_QUESTION_IDS.PROXY_GROUP, answers) || [];
  const proxy = {
    name: getAnswerById(LIFEWILL_QUESTION_IDS.PROXY_NAME, proxyAnswers),
    identification: getAnswerById(LIFEWILL_QUESTION_IDS.PROXY_IDENTIFICATION, proxyAnswers),
    relation: getAnswerById(LIFEWILL_QUESTION_IDS.PROXY_RELATION, proxyAnswers),
  };

  const reverveProxyAnswers = getAnswerById(LIFEWILL_QUESTION_IDS.RESERVE_PROXY_GROUP, answers) || [];
  const reserveProxy = {
    yes: isTrueByIdAndAnswerValue(LIFEWILL_QUESTION_IDS.RESERVE_PROXY, LIFEWILL_VALUE_IDS.RESERVE_PROXY_YES, answers),
    name: getAnswerById(LIFEWILL_QUESTION_IDS.RESERVE_PROXY_NAME, reverveProxyAnswers),
    identification: getAnswerById(LIFEWILL_QUESTION_IDS.RESERVE_PROXY_IDENTIFICATION, reverveProxyAnswers),
    relation: getAnswerById(LIFEWILL_QUESTION_IDS.RESERVE_PROXY_RELATION, reverveProxyAnswers),
  };

  const input = {
    living: getAnswerById(LIFEWILL_QUESTION_IDS.LIVING_INPUT, answers),
    gifts: getAnswerById(LIFEWILL_QUESTION_IDS.GIFTS_INPUT, answers),
    inheritance: getAnswerById(LIFEWILL_QUESTION_IDS.INHERITANCE_INPUT, answers),
    some: isTrueByIdAndAnswerValue(LIFEWILL_QUESTION_IDS.SOSIAL_MEDIA, LIFEWILL_VALUE_IDS.SOSIAL_MEDIA_YES, answers),
    other: getAnswerById(LIFEWILL_QUESTION_IDS.OTHER_INPUT, answers),
  };

  const donations = getAnswerById(LIFEWILL_QUESTION_IDS.DONATIONS, answers);
  const relation = getAnswerById(LIFEWILL_QUESTION_IDS.SIVILSTATUS, answers);
  const hasChildern = getAnswerById(LIFEWILL_QUESTION_IDS.HAS_CHILDREN, answers);

  return (
    <div className={`section testament-page ${className}`}>
      <div className="sectionContent" onmousedown='return false;' onselectstart='return false;'>
        <div className="will life-will-draft">
          <div className="preview-logo">
            <div className="will-identity">
              <img src="/images/arv-logo.svg" alt="arv.no" className="logo" />
              <div className="will-logo-text">arv.no</div>
            </div>
          </div>
          <h2>{name}s fremtidsfullmakt</h2>
          <p>
            Jeg, {name} ({identification}), gir herved {proxy.name} ({proxy.identification}), heretter kalt fullmektigen, fullmakt til å handle
            på mine vegne og ta beslutninger i mitt navn når jeg ikke lenger er i stand til å gjøre det selv.
          </p>
          {reserveProxy.yes && (
            <p>
              {reserveProxy.name} ({reserveProxy.identification}) skal være fullmektig dersom {proxy.name} ikke lenger kan utføre sine oppgaver.
            </p>
          )}

          <h3>Fullmakten trer i kraft</h3>
          <p>
            Fullmakten gjelder fra det tidspunktet jeg ikke lenger kan ivareta mine egne interesser. Det skal være bekreftet med erklæring fra
            lege. Fullmektigen har rett til å be om erklæring fra lege angående min helsetilstand.
          </p>

          <h3>Informasjon til meg og mine nære</h3>
          <p>
            Når fremtidsfullmakten slår inn, skal fullmektigen informere meg og mine nære, dvs.{" "}
            {getRelationText(relation, hasChildern === LIFEWILL_VALUE_IDS.HAS_CHILDREN)}. Vi skal sammen gå gjennom innholdet i denne fullmakten
            og hva det betyr i praksis.
          </p>

          <h3>Fullmektigens oppgaver</h3>
          <p>Fullmektigen skal handle i min beste interesse, og i samsvar med mine ønsker og verdier så langt som mulig.</p>
          <p>Fullmektigen skal så langt det lar seg gjøre høre med meg før det tas viktige beslutninger på mine vegne.</p>

          <h4>Mine økonomiske forhold</h4>
          <p>Fullmektigen skal ivareta mine økonomiske interesser. Det inkluderer, men er ikke begrenset til følgende:</p>
          <ul>
            <li>Betale mine utgifter</li>
            <li>Håndtere gjeld</li>
            {isTrueByIdAndAnswerValue(LIFEWILL_QUESTION_IDS.ADMIN_INSURANCE, true, answers) && <li>Administrere mine forsikringsavtaler</li>}
            {isTrueByIdAndAnswerValue(LIFEWILL_QUESTION_IDS.ADMIN_ECONOMIC_PROPERTY, true, answers) && (
              <li>Håndtere andre finansielle eiendeler</li>
            )}
            {isTrueByIdAndAnswerValue(LIFEWILL_QUESTION_IDS.ADMIN_SELL, true, answers) && <li>Selge løsøre jeg ikke lenger trenger</li>}
            {isTrueByIdAndAnswerValue(LIFEWILL_QUESTION_IDS.ADMIN_SPENDING, true, answers) && (
              <li>Sørge for at jeg har nødvendige penger til mitt personlige forbruk</li>
            )}
          </ul>

          <p>{getAnswerById(LIFEWILL_QUESTION_IDS.ECONOMIC_INPUT, answers)}</p>

          {input.inheritance && (
            <>
              <h5>Forskudd på arv</h5>
              <p>
                Jeg ønsker at mine arvinger skal få forskudd på arv. Fordelingen av forskuddet følger det som står i mitt testament. Har jeg ikke
                testament så følger fordelingen arveloven.
              </p>
              <p>Jeg skal sitte igjen med {formatCurrency(input.inheritance)} kr på min konto etter at forskuddet er utbetalt.</p>
            </>
          )}
          <h4>Min bolig og annen eiendom</h4>
          <p>
            Fullmektigen har fullmakt til å ta beslutninger om min bolig. Det inkluderer salg eller utleie av min bolig dersom det er
            hensiktsmessig. Fullmakten gjelder alle sider ved evt. salg, og omfatter blant annet retten til å aksepter bud og skrive under på
            kontrakt og skjøte.
          </p>
          <p>{getAnswerById(LIFEWILL_QUESTION_IDS.HOME_PROPERTIES_INPUT, answers)}</p>
          <h4>Disposisjonsrett overfor bank og offentlige instanser</h4>
          <p>
            Fullmektigen har rett til å disponere mine bankkontoer. Det inkluderer å inngå og signere avtaler om bruk av nettbank og andre
            betalingstjenester knyttet til kontoene. Fullmektigen har rett til å representere meg overfor offentlige instanser.
          </p>
          <p>{getAnswerById(LIFEWILL_QUESTION_IDS.BANK_INPUT, answers)}</p>

          <h4>Personlige forhold </h4>
          <p>
            Fullmektigen kan handle på mine vegne i alle personlige saker som gjelder ivaretakelse av mine rettigheter og interesser utover
            økonomiske forhold. Det inkluderer, men er ikke begrenset til følgende:
          </p>
          <h5>Helse</h5>
          <p>Fullmektigen kan blant annet:</p>
          <ul>
            <li>Inngå avtaler med helse- og omsorgstjenester</li>
            <li>Delta i råd og beslutninger som vedrører min helse</li>
          </ul>
          <p>{getAnswerById(LIFEWILL_QUESTION_IDS.HEALTH_INPUT, answers)}</p>
          {input.living && (
            <>
              <h5>Boforhold</h5>
              <p>{input.living}</p>
            </>
          )}

          {donations && donations.length > 0 && (
            <>
              <h5>Gaver til gode formål</h5>
              {donations.map((d) => getDonationFromValue(d.value))}
            </>
          )}

          {input.gifts && (
            <>
              <h5>Gaver til nære</h5>
              <p>{input.gifts}</p>
            </>
          )}

          {input.some && (
            <>
              <h5>Sosiale medier </h5>
              <p>Fullmektigen gis fullmakt til å administrere mine kontoer/brukere på sosiale medier.</p>
            </>
          )}

          {input.other && (
            <>
              <h5>Andre ønsker</h5>
              <p>{input.other}</p>
            </>
          )}

          <h3>Nødvendige utgifter</h3>
          {getAnswerById(LIFEWILL_QUESTION_IDS.PAYED_PROXY, answers) === LIFEWILL_VALUE_IDS.PAYED_PROXY_NO ? (
            <p>
              Fullmektigen har krav på å få dekket sine nødvendige utgifter i forbindelse med utøvelse av fullmakten, men fullmektigen har ikke
              krav på å beregne seg et vederlag for sitt arbeid som fullmektig
            </p>
          ) : (
            <p>
              Min fullmektig har krav på å få dekket sine nødvendige utgifter i forbindelse med ivaretakelse av mine interesser av mine midler
              {getAnswerById(LIFEWILL_QUESTION_IDS.PAYED_PROXY, answers) && (
                <>
                  , og skal hvert år kunne beregne seg et rimelig vederlag for sitt arbeid som fullmektig, begrenset oppad til kr{" "}
                  {getAnswerById(LIFEWILL_QUESTION_IDS.PAYED_PROXY_AMOUNT, answers)} per år.
                </>
              )}
            </p>
          )}
          <div className="grid-x grid-padding-x">
            <div className="cell small-5 signaturePlace">{getSignature(answers, name)}</div>
          </div>
          <div className="grid-x grid-padding-x">
            <div className="cell small-5 left">
              <div className="signatures">
                {getWitnessSignature(answers, 0)}
                {getWitnessSignature(answers, 1)}
              </div>
            </div>
          </div>
          <div className="will-footer">
            <div className="copyright">
              <img alt="Copyright" src="/images/arv-logo.png" />
              <span>arv.no</span>
            </div>
            <div className="appname">
              <a href="https://www.sealedit.no" target="_blank" rel="noopener noreferrer">
                Sealedit
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

function renderInput(input, isAnswered = false) {
  const className = isAnswered ? "input-answered" : "input-placeholder";
  return <span className={className}>{input}</span>;
}

function getPlaceData(answers) {
  const place = getAnswerById(LIFEWILL_QUESTION_IDS.SIGNING_PLACE, answers);
  if (!place) {
    return null;
  }
  const placeCity = getAnswerById(LIFEWILL_QUESTION_IDS.PLACE_CITY, place);
  const placeDate = getAnswerById(LIFEWILL_QUESTION_IDS.PLACE_DATE, place);
  return {
    placeCity,
    placeDate,
  };
}

function getSignature(answers, name) {
  const place = getPlaceData(answers) ?? {};
  const { placeCity, placeDate } = place;
  const nameUppercase = name ? name.toUpperCase() : "";
  return (
    <div className="signature">
      <dl>
        {placeCity && placeDate ? (
          <dd>
            {placeCity}, {placeDate}
          </dd>
        ) : (
          <>
            <dd>Sted/dato:</dd>
            <dd className="empty"></dd>
          </>
        )}
        <dd className="empty"></dd>
        <dd>{renderInput(name ? nameUppercase : "[NAVN]", !!name)}</dd>
      </dl>
    </div>
  );
}

function getWitnessSignature(answers, witnessIndex) {
  const witness = getAnswerById(witnessIndex === 0 ? LIFEWILL_QUESTION_IDS.WITNESS_1 : LIFEWILL_QUESTION_IDS.WITNESS_2, answers);
  let nameAnswer = "",
    addressAnswer = {},
    professionAnswer = "";
  if (witness) {
    if (witnessIndex === 0) {
      nameAnswer = getAnswerById(LIFEWILL_QUESTION_IDS.WITNESS_NAME_1, witness);
      addressAnswer = getAnswerById(LIFEWILL_QUESTION_IDS.WITNESS_ADDRESS_1, witness) || {};
      professionAnswer = getAnswerById(LIFEWILL_QUESTION_IDS.WITNESS_PROFESSION_1, witness);
    } else {
      nameAnswer = getAnswerById(LIFEWILL_QUESTION_IDS.WITNESS_NAME_2, witness);
      addressAnswer = getAnswerById(LIFEWILL_QUESTION_IDS.WITNESS_ADDRESS_2, witness) || {};
      professionAnswer = getAnswerById(LIFEWILL_QUESTION_IDS.WITNESS_PROFESSION_2, witness);
    }
  }
  const { street, postalCode, city } = addressAnswer;
  const nameStr = nameAnswer ? nameAnswer.toUpperCase() : "";
  return (
    <div className="signature">
      <dl>
        <dd>Sted/dato:</dd>
        <dd className="empty"></dd>
        <dd>Underskrift:</dd>
        <dd className="empty"></dd>
        {nameStr ? (
          <dd>{nameStr}</dd>
        ) : (
          <>
            <dt>Navn med blokkbokstaver:</dt>
            <dd className="empty"></dd>
          </>
        )}
        {professionAnswer ? (
          <dd>{professionAnswer}</dd>
        ) : (
          <>
            <dt>Yrke/stilling:</dt>
            <dd className="empty"></dd>
          </>
        )}
        {street || postalCode || city ? (
          <dd>
            {street ?? ""},{" "}
            <span>
              {postalCode ?? ""} {city ?? ""}
            </span>
          </dd>
        ) : (
          <>
            <dt>Adresse:</dt>
            <dd className="empty"></dd>
          </>
        )}
      </dl>
    </div>
  );
}

function getDonationFromValue(donationValue) {
  if (donationValue) {
    var donation = {
      orgName: donationValue[0]?.value?.value?.Name,
      orgNumber: donationValue[0]?.value?.value?.Value,
      type: donationValue[1]?.value.id,
      sum: donationValue[2]?.value,
    };
  } else {
    return;
  }

  if (donation.type === LIFEWILL_VALUE_IDS.DONATION_TYPE_ONCE) {
    return (
      <p key={donation.orgNumber}>
        {donation.orgName} ({donation.orgNumber}) skal motta et engangsbeløp på {formatCurrency(donation.sum)}. Beløpet utbetales når
        fremtidsfullmakten trer i kraft.
      </p>
    );
  }
  if (donation.type === LIFEWILL_VALUE_IDS.DONATION_TYPE_YEARLY)
    return (
      <p key={donation.orgNumber}>
        {donation.orgName} ({donation.orgNumber}) skal motta {formatCurrency(donation.sum)}. Beløpet utbetales hvert år og første gang når
        fremtidsfullmakten trer i kraft.
      </p>
    );

  return (
    <p key={donation.orgNumber}>
      {donation.orgName} ({donation.orgNumber}) skal motta {formatCurrency(donation.sum)}.
    </p>
  );
}

function getRelationText(sivilStatus, hasChildren) {
  let text = "";

  if (hasChildren && sivilStatus !== LIFEWILL_VALUE_IDS.PARTNER && sivilStatus !== LIFEWILL_VALUE_IDS.MARRIED) {
    return "mine barn";
  }

  if (sivilStatus === LIFEWILL_VALUE_IDS.PARTNER) {
    text = "min samboer";
  }

  if (sivilStatus === LIFEWILL_VALUE_IDS.MARRIED) {
    text = "min ektefelle";
  }

  if (hasChildren) {
    text = text + " og barn";
  }
  return text;
}
