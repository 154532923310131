function reverseString(str) {
  var newString = "";
  for (var i = str.length - 1; i >= 0; i--) {
    newString += str[i];
  }
  return newString;
}

export default function formatCurrency(value) {
  if (!value && value !== 0) {
    return "";
  }

  const formattedNumber = reverseString(value.toString().replace(" ", "")).match(/.{0,3}/g);
  return reverseString(formattedNumber.join(" ")).trim() + " kr";
}
