import { getToken } from "./authProvider";

export function createUrl(endpoint = "") {
  const { REACT_APP_API_URL: API_URL = "https://testamentertserver.azurewebsites.net/api" } = process.env;
  return `${API_URL}${endpoint}`;
}

export async function createRequest(options = {}, secure = true) {
  const token = secure ? await getToken() : null;
  const bearer = token  ? "Bearer " + token : null;
  return {
    method: "GET", // GET, POST, PUT, DELETE, etc.
    mode: "cors", // no-cors, cors, *same-origin
    cache: "no-cache", // default, no-cache, reload, force-cache, only-if-cached
    headers: {
      "Content-Type": "application/json",
      Authorization: bearer,
    },
    referrer: "no-referrer", // no-referrer, client
    ...options,
  };
}

// Todo: Remove try/catch. Should handle errors in a different way.
export default async function http(url, request, isJson = true, ignoreResponse = false) {
  try {
    const response = await fetch(url, await request) ;
    if (ignoreResponse) {
      if (!response.status === 200) {
        throw new Error(`Request to url ${url} failed`);
      }
      return true;
    }
    if (isJson) {
      return await response.json();
    }
    return await response.text();
  } catch (error) {
    console && console.error && console.error(error);
  }
}
