import React from "react";
import "./preview-section.scss";
import { FormContextConsumer } from "../../../context/form-context";
import ENUMS from "../../../enums";
import PreviewWill from "./preview/preview-will";
import PreviewWillReciprocale from "./preview/preview-will-reciprocal";
import PreviewLifeWill from "./preview/preview-lifewill";
import PreviewReciprocalLifeWill from "./preview/preview-reciprocal-lifewill";

export default function PreviewSection() {
  return <FormContextConsumer>{({ answers = [], type }) => renderPreview(answers, type)}</FormContextConsumer>;
}

function renderPreview(answers, type) {
  switch (type) {
    case ENUMS.FORM_TYPE.RECIPROCAL:
    case ENUMS.FORM_TYPE.RECIPROCAL_SUMMARY:
      return <PreviewWillReciprocale answers={answers} />;
    case ENUMS.FORM_TYPE.LIFEWILL:
    case ENUMS.FORM_TYPE.LIFEWILL_SUMMARY:
      return <PreviewLifeWill answers={answers} />;
    case ENUMS.FORM_TYPE.RECIPROCAL_LIFEWILL:
    case ENUMS.FORM_TYPE.RECIPROCAL_LIFEWILL_SUMMARY:
      return <PreviewReciprocalLifeWill answers={answers} />;
    default:
      return <PreviewWill answers={answers} />;
  }
}
