import React from "react";
import "../../App.scss";

export default function TextElement(props) {
  const { title, text, links, headingSize } = props;

  function getTitle() {
    switch (headingSize) {
      case "h1":
        return <h1 className="title">{title}</h1>;
      case "h2":
        return <h2 className="title">{title}</h2>;
      case "h3":
        return <h3 className="title">{title}</h3>;
      case "h4":
        return <h4 className="title">{title}</h4>;
      default:
        return <h3>{title}</h3>;
    }
  }

  function insertLinks(text) {
    let newText = text;

    if (links) {
      links.forEach((link) => {
        newText = newText.replace(link.token, `<a href=${link.href} target="_blank" rel="noreferrer noopener">${link.text}</a>`);
      });
    }

    return newText;
  }
  return (
    <div className="textFormControl">
      {title && getTitle()}
      {text.map(({ id, value }) => value !== "" && <p key={id} dangerouslySetInnerHTML={{ __html: insertLinks(value) }} />)}
    </div>
  );
}
