import React, { useContext } from 'react';
import { Checkbox, FormControlLabel, FormGroup } from "@material-ui/core";
import { postProfileData } from "../../services/user-data-service";
import ENUMS from "../../enums";
import ProfileContext from "../../context/profile-context";

export default function UserSettings() {
    const { state, dispatch } = useContext(ProfileContext);
    const { newsletter = false, canBeContacted = false } = state;

    return (
        <div className="receipt-confirmation">
            <div className="profile-data">
                <div className="formControl">
                    <FormGroup>
                        <FormControlLabel
                            key={'2'}
                            value={`${1}`}
                            control={<Checkbox
                                checked={newsletter}
                                color="secondary"
                                name="newsletter"
                                onChange={onChange}
                                value={1} />
                            }
                            label={<span className="alternative-container">Jeg ønsker å motta nyhetsbrev med informasjon og aktuelle nyheter om arv, testament og fremtidsfullmakt fra Arv.no.</span>}
                        />
                    </FormGroup>
                </div>
            </div>
        </div>
    );

    function onChange({ target: { name, checked } }) {
        const newProfile = {
            ...state,
            [name]: checked
        };

        postProfileData(newProfile).then(() => {
            dispatch({
                type: ENUMS.PROFILE_ACTIONS.UPDATE,
                data: newProfile
            });
        }).catch((error) =>
            console && console.error && console.error(error));
    }

}
