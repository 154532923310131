import ENUMS from "../../../../enums";
import { ANSWERS_RECIPROCAL, INHERITENCE_TYPE } from "../ANSWERS_MAP";
import { getLimitingDisplayText, getNonLimitedLabel } from "../text-helper";
import formatCurrency from "../../../../util/format-currency";
import React from "react";
import { getRelationText } from "./preview-will";
import { getAnswerById, getFamilyState } from "../../../../util/answers/answers-helper";
import QUESTIONS, { RECIPROCAL_QUESTION_IDS } from "../QUESTION_MAP";
import QUESTION_MAP from "../QUESTION_MAP";

export default function InheritanceTable({ inheritance = [], answers, label = '', isPartner = false }) {
    if (!inheritance.length) {
        return null;
    }

    return (
        <>
            <p>{label}</p>
            <table>
                <thead>
                <tr>
                    <th width="35%">Arving</th>
                    <th width="15%">Relasjon</th>
                    <th width="50%">Eiendeler</th>
                </tr>
                </thead>
                <tbody>
                {inheritance.map(({
                                      name,
                                      relation,
                                      identification,
                                      identificationId,
                                      inheritance,
                                      address
                                  }, index, persons) => (
                    <tr key={`${identification ?? identificationId ?? relation}-${relation}-${index}`}>
                        <td>
                            <span className="name">{name}</span>
                            {relation === ENUMS.RELATION.OTHER && (
                                <>
                                    <br/>
                                    <span>
                                {address?.street ?? null}, {address?.postalCode ?? null} {address?.city ?? null}
                 </span>
                                </>
                            )}
                            {relation === ENUMS.RELATION.ORGANISATION && (
                                <>
                                    <br/>
                                    {
                                        (identificationId || identification) && 'Org.nr. '
                                    }
                                    {
                                        identificationId ?? identification ?? ""
                                    }{" "}
                                </>
                            )}
                        </td>
                        <td>
                            <span className="relation">{getRelationText(relation)}</span>
                        </td>
                        <td>
                            <div className="belongings">

                                {
                                    inheritance &&
                                    inheritance.map(({
                                                         inheritanceType,
                                                         heritage = "",
                                                         inheritsDebt,
                                                         isLimited,
                                                         separateProperty,
                                                         hasZeroVestige
                                                     }, index) => (
                                        <div className="heritage-item"
                                             key={`vestige-item_${relation}_${inheritanceType}_${index}`}>
                                            {
                                                inheritanceType === INHERITENCE_TYPE.PROPERTY && (
                                                    heritage
                                                )
                                            }
                                            {
                                                inheritanceType === INHERITENCE_TYPE.VESTIGE && isLimited && (
                                                    getLimitingDisplayText(relation, persons, answers, isPartner, true)
                                                )
                                            }
                                            {
                                                inheritanceType === INHERITENCE_TYPE.VESTIGE && !isLimited && !hasZeroVestige && (
                                                    getNonLimitedText(relation, heritage, persons, answers, isPartner)
                                                )
                                            }
                                            {
                                                inheritanceType === INHERITENCE_TYPE.MONEY && (
                                                    formatCurrency(heritage) + "."
                                                )
                                            }
                                            {
                                                inheritanceType === INHERITENCE_TYPE.PROPERTY && inheritsDebt ? " (inkl. evt. gjeld)." : ""
                                            }
                                            {
                                                separateProperty ? " Arves som særeie." : ""
                                            }
                                        </div>
                                    ))
                                }
                            </div>
                            {index === answers.length - 1 && <br/>}
                        </td>
                    </tr>
                ))}
                </tbody>

                <tfoot>
                <tr>
                    <td colSpan={3}>
                        {
                            hasLegalHeirs(answers, true) ? 
                                <>Grunnbeløpet (G) justeres hver år med
                                    virkning fra 1. mai.</>
                            : <></>
                        }
                    </td>
                </tr>
                </tfoot>

            </table>
        </>
    );
}

function hasLegalHeirs(answers) {
    const { hasAnyChildren } = getFamilyState(answers, true);
    const relationshipStatus = getAnswerById(RECIPROCAL_QUESTION_IDS.RELATIONSHIP_STATUS, answers, false);
    const isMarried = relationshipStatus === ANSWERS_RECIPROCAL.MARRIED;

    if (isMarried) {
        return true;
    }

    return hasAnyChildren;
}

function getNonLimitedText(relation, heritage, persons, answers, isPartner) {
    return `${heritage} ${getNonLimitedLabel(relation, answers, isPartner, true)}`;
}
