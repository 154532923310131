import React from "react";
import formatCurrency from "../../util/format-currency";
import ENUMS from "../../enums";

export default function KeyValues({data}){
    return <>
        {data && data.length > 0 && data.map((d, i) => 
            <div className="box" key={i}>
                <span className="number">{d.type === ENUMS.DONATIONTYPE.PROSENTAGE ? d.value + " stk." : formatCurrency(d.value) }</span> 
                <span className="text">{d.name}</span>
            </div>
        )}
    </>
}