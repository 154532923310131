import React from "react";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import "../../App.scss";
import HelpText from "./helptext";

export default function FormRadio(props) {
  const { id, type, question, alternatives, helpText, setStateValues, answer } = props;

  function onValueChange({ target: { value } }) {
    const { value: alternativeValue = '' } = alternatives.find(a => a.id == value);
    setStateValues(id, type, parseInt(value), alternativeValue);
  }

  return (
    <div className="formControl">
      <label className="questionLabel">
        {question}
        {helpText && <HelpText text={helpText} />}
      </label>

      <RadioGroup key={id} value={`${answer && answer.value }`} onChange={onValueChange}>
        {alternatives.map(({ id, value, helpText }) => (
          <div className="formControlRadioLabel" key={id}>
          <FormControlLabel
            value={`${id}`}
            control={<Radio color="secondary" />}
            label={
              <span className="alternative-container">
                {value}
              </span>
            }
          />
          {helpText && <HelpText text={helpText}></HelpText>}
          </div>
        ))}
      </RadioGroup>
    </div>
  );
}
