import React, { useContext } from "react";
import ProfileContext from "../../../context/profile-context";
import ENUMS from "../../../enums";
import TestamentCard from "./testament-card";

export default function TestamentCardList(props) {
  const { formType } = props;
  const { state } = useContext(ProfileContext);
  const { testaments } = state;

  const forms = testaments.filter((t) => isLifeWillType(t.formType) === isLifeWillType(formType));

  function isLifeWillType(type) {
    return type === ENUMS.FORM_TYPE.LIFEWILL || type === ENUMS.FORM_TYPE.RECIPROCAL_LIFEWILL;
  }

  return (
    <>
      {forms.map(({ name, id, createDate, status, formType }) => (
        <TestamentCard key={id} id={id} name={name} createdDate={createDate} state={status} formType={formType} />
      ))}
      <TestamentCard isNew={true} formType={formType} />
      {formType === ENUMS.FORM_TYPE.NORMAL && <TestamentCard isNew={true} formType={ENUMS.FORM_TYPE.RECIPROCAL} />}
      {formType !== ENUMS.FORM_TYPE.NORMAL && <TestamentCard isNew={true} formType={ENUMS.FORM_TYPE.RECIPROCAL_LIFEWILL} />}
    </>
  );
}
