import { useEffect, useState } from 'react';
import { MsalAuthProvider, LoginType } from 'react-aad-msal';

const tenant = "testamentert.onmicrosoft.com";
const signInPolicy = process.env.REACT_APP_SIP;
const instance = `https://testamentert.b2clogin.com/tfp`;
const signInAuthority = `${instance}/${tenant}/${signInPolicy}`;
const resetPasswordAuthority = `${instance}/${tenant}/B2C_1_Reset`;
const TOKEN_KEY = "seal-access-token";

// Msal Configurations
const config = {
  auth: {
    authority: signInAuthority,
    clientId: process.env.REACT_APP_APPID,
    redirectUri: process.env.REACT_APP_REDIRECT_URI,
    validateAuthority: false,
    postLogoutRedirectUri: 'https://arv.no/?user-sign-off=1'
  },
  cache: {
    cacheLocation: "sessionStorage",
    storeAuthStateInCookie: true
  }
};

// Authentication Parameters
const authenticationParameters = {
  scopes: ["openid", "https://testamentert.onmicrosoft.com/68a6aae7-3818-48f1-8e57-8ad3f40fcfff/Read"]
}

// Options
const options = {
  loginType: LoginType.Redirect,
  tokenRefreshUri: window.location.origin + '/auth.html'
}

const accountInfoHandler = (accountInfo) => {
  if (accountInfo?.jwtAccessToken == null) {
    sessionStorage.removeItem(TOKEN_KEY);
  } else {
    sessionStorage.setItem(TOKEN_KEY, accountInfo?.jwtAccessToken);
  }
};

const refreshToken = async () => {
  try {
    return (await authProvider.getAccessToken()).accessToken;
  } catch (e) {
    console.error("Failed to fetch token", e)
    return null;
  }
}

export const useAccessToken = () => {
  const [token, setToken] = useState(null);
  useEffect(() => authProvider.registerAcountInfoHandler(accountInfoHandler), [])
  useEffect(() => {
    if (token == null) {
      refreshToken().then(setToken)
    }
  }, [token])
  return token;
};

export const getToken = () => {
  const accessToken = sessionStorage.getItem(TOKEN_KEY);
  return accessToken == null ? refreshToken() : Promise.resolve(accessToken);
}

export const logout = () => {
  authProvider.logout();
  window.location.href = 'https://arv.no';
}

export function handleForgotPassword(error) {
  if (error && error.errorMessage.indexOf('AADB2C9011') > -1) {
    authProvider.setAuthenticationParameters ({authority: resetPasswordAuthority})
    authProvider.login()
  }
}

export const authProvider = new MsalAuthProvider(config, authenticationParameters, options);
