import React from "react";
import FormElement from "../../form-element";
import "./static-list.css";

export default function StaticList(props) {
  const { element = {}, setStateValues, answers } = props;
  const { id, type, content, orientation } = element;
  const orientationClass = orientation === 0 ? "horizontal" : "vertical";
  const typeClass = type === 0 ? "normal" : "primary-action";
  const classNames = `list static-list ${orientationClass} ${typeClass}`;
  const answer = { id: id, type: type, value: answers?.value ? Array.isArray(answers.value) ? answers.value : [answers.value] : [] };
  
  const onSetState = (subId, subType, subValue, alternativeText) => {
    const value =  answer.value.filter((v) => v.id !== subId);
    value.push({ id: subId, type: subType, value: subValue, alternativeText: alternativeText });
    setStateValues(id, type, value);
  };

  return (
    <div className={classNames}>
      {content.map((contentItem) => (
        <div className="list-item" key={contentItem.id}>
          <FormElement element={contentItem} onSetState={onSetState} answer={answer.value?.find((v) => v.id === contentItem.id)} />
        </div>
      ))}
    </div>
  );
}
