import React from "react";
import "../../App.scss";
import { TextField } from "@material-ui/core";
import ENUMS from "../../enums";

export default function FormInput(props) {
  const { id, type, question, captionText = "", value, setStateValues, isMultiline, inputType, inputProps } = props;
  const defaultValue = value && value.value ? value.value : undefined;
  const variant = "outlined";

  function onChange(event) {
    const validValue = (event.target.value ?? "").replace(/"/gi, "").replace(/'/gi, "");
    setStateValues(id, type, validValue);
  }

  function getInputType(inputType) {
    switch (inputType) {
      case undefined:
      case null:
      case ENUMS.INPUT_TYPE.TEXT:
        return "text";
      case ENUMS.INPUT_TYPE.NUMBER:
        return "number";
      case ENUMS.INPUT_TYPE.PASSWORD:
        return "password";
      default:
        console.error("Could not find inputType: " + inputType);
        return "text";
    }
  }
  return (
    <div className="formControl">
      <label className="questionLabel">{question}</label>
      <TextField
        fullWidth
        multiline={isMultiline}
        onBlur={onChange}
        variant={variant}
        defaultValue={defaultValue}
        required
        helperText={captionText}
        type={getInputType(inputType)}
        inputProps={{ ...inputProps }}
      />
    </div>
  );
}

// inputProps={{ maxLength: 12 }}
