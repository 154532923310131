import React from 'react';
import ENUMS from "../enums";

export const profileState = {
  id: null,
  name: '',
  email: '',
  phoneNumber: '',
  newsletter: false,
  canBeContacted: false,
  testaments: [],
  isLoading: true
};

export function profileReducer(state, { type, data }) {
  switch(type) {
    case ENUMS.PROFILE_ACTIONS.UPDATE:
      return {
        ...state,
        ...data
      };

    default:
      return profileState;
  }
}

const ProfileContext = React.createContext();
export default ProfileContext;
