import React, { useContext, useEffect, useState } from "react";
import Header from "../components/header/header";
import { getForm } from "../services/form-data-service";
import PreviewSection from "../components/section/preview-section/preview-section";
import { FormContextProvider } from "../context/form-context";
import UIContext from "../context/ui-context";
import { useLocation, useParams } from "react-router-dom";

/***
 * Map for url path to API id:
 * @type {{path: id}}
 */
const PATH_ID_MAP = {
  "/kvalitetssikring": 801,
}

export default function AuditPage(props) {
  const [form, setForm] = useState(null);
  const [preset, setPreset] = useState(null)
  const { state: { mode: uiMode = 'none' } } = useContext(UIContext);
  const { testamentId } = useParams();
  const { pathname, search } = useLocation();

  useEffect(() => {
    getFirstForm(testamentId);
  }, []);

  return (
    <main>
      <Header hasPreview={true} />
      {
        form && (
          <FormContextProvider form={form} preset={preset} testamentId={testamentId}>
            <div className={`app container ui-mode--${uiMode}`}>
              <PreviewSection collapsable="true" />
            </div>
          </FormContextProvider>
        )
      }
    </main>
  );

  async function getFirstForm(testamentId) {
    const id = PATH_ID_MAP[pathname.match(/[^\/]*\/[^\/]*/)[0]] ?? 2;
    const form = await getForm(testamentId ? `/FormPage/${id}/${testamentId}` : `/FormPage/${id}`);
    if (search) {
      const query = JSON.parse('{"' + search.substring(1).replace(/&/g, '","').replace(/=/g, '":"') + '"}', function (key, value) { return key === "" ? value : decodeURIComponent(value) });
      setPreset(query)
    }
    form.type = form.answerType;
    setForm(form);
  }
}