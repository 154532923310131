import { add, differenceInCalendarDays, differenceInCalendarMonths, format } from "date-fns";
import { useEffect, useState } from "react";
import { LineChart, CartesianGrid, Legend, Line, Tooltip, XAxis, YAxis } from "recharts";

export default function Chart({ title, series, data }) {
  const [ticks, setTicks] = useState([]);
  const [domain, setDomain] = useState();
  const [chartData, setChartData] = useState();

  useEffect(() => {
    if (data && data.length > 0) {
      populateChart(data);
    }
  }, [data]);

  function populateChart(cData) {
    const startDate = new Date(cData[0].date);
    const endDate = new Date(cData[cData.length - 1].date);

    setDomain([(dataMin) => dataMin, (endDate) => endDate]);
    setTicks(getTicks(startDate, endDate, differenceInCalendarMonths(startDate, endDate)));
    setChartData(fillTicksData(ticks, cData));
  }

  const getTicks = (startDate, endDate, num) => {
    const diffDays = differenceInCalendarDays(startDate, endDate);
    const ticks = [startDate.getTime()];
    let current = startDate;
    let velocity = Math.round(diffDays / (num - 1));

    for (let i = 1; i < num - 1; i++) {
      ticks.push(add(current, { days: i * velocity }).getTime());
    }

    ticks.push(endDate.getTime());
    return ticks;
  };

  const fillTicksData = (_ticks, cData) => {
    const ticks = [..._ticks];
    const filled = [];
    let currentTick = ticks.shift();
    let lastData = null;
    for (const it of cData) {
      if (ticks.length && it.date > currentTick && lastData) {
        filled.push({ ...lastData, ...{ date: currentTick } });
        currentTick = ticks.shift();
      } else if (ticks.length && it.date === currentTick) {
        currentTick = ticks.shift();
      }

      filled.push(it);
      lastData = it;
    }

    return filled;
  };

  const dateFormatter = (date) => {
    return format(new Date(date), "dd.MM.yy");
  };

  return (
    <div className="section form">
      {chartData && (
        <>
          <h3>{title}</h3>
          <LineChart width={730} height={450} data={chartData} margin={{ top: 5, right: 30, left: 20, bottom: 5 }}>
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="date" scale="time" hasTick type="number" tickFormatter={dateFormatter} ticks={ticks} domain={domain} />
            <YAxis tickCount={ticks.count} hasTicks />
            <Tooltip />
            <Legend />
            {data &&
              series &&
              series.map((s) => <Line dot={false} key={s.name} type="monotone" dataKey={s.name} stroke={s.color} strokeWidth={2} />)}
          </LineChart>
        </>
      )}
    </div>
  );
}
