import React, { useState } from "react";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { withStyles } from "@material-ui/core/styles";
import Modal from "@material-ui/core/Modal";
import Button from "@material-ui/core/Button";
import PaymentCheckout from "./payment-checkout";

function getModalStyle() {
  const top = 50; // + rand();
  const left = 50; // + rand();

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}

const styles = (theme) => ({
  paper: {
    position: "absolute",
    width: "100%",
    maxWidth: theme.spacing(75),
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    outline: "none",
    maxHeight: "95vh",
    // overflowY: "scroll",
  },
  head: {
    padding: theme.spacing(4, 4, 0, 4),
    backgroundColor: theme.palette.background.paper,
    textAlign: "left",
    fontFamily: "'Cabin', sans-serif",
  },
  body: {
    padding: theme.spacing(4),
    backgroundColor: "#f4f4f4",
  },
  foot: {
    padding: theme.spacing(4, 4, 4),
    backgroundColor: "white",
    display: "flex",
    flexDirection: "column",
    alignItems: "inherit",
  },
  cancel: {
    marginTop: "1rem",
  },
});

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_APIKEY, { locale: "nb" });

function PaymentModal(props) {
  const { classes, element, testamentId } = props;
  const [open, setOpen] = useState(false);

  const btn = {
    text: "",
    action: "",
    endpoint: "",
    size: "large",
    variant: "contained",
    className: "primaryButton",
    color: "primary",
  };

  const closeModal = () => {
    setOpen(false);
  };

  return (
    <div className="formControl">
      <Button size={btn.size} variant="contained" className={btn.className} color={btn.color} onClick={() => setOpen(true)}>
        {element.text}
      </Button>

      <Modal aria-labelledby="Betalings informasjon" aria-describedby="description" open={open} onClose={() => setOpen(false)}>
        <div style={getModalStyle()} className={classes.paper}>
          <div className={classes.innerModal}>
            <div className={classes.head}>
              <h2>Betal</h2>
            </div>

            <div className={classes.body}>
              <Elements stripe={stripePromise}>
                <PaymentCheckout testamentId={testamentId} closeModal={closeModal} />
              </Elements>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
}

// // We need an intermediary variable for handling the recursive nesting.
export default withStyles(styles)(PaymentModal);
