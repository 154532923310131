import React from "react";
import { FormControl, Select, MenuItem, Typography } from "@material-ui/core";

const styles = {
  width: "250px",
  labelWidth: 0,
  backgroundColor: "white",
};

const wrapperStyles = {
  minHeight: "64px"
};

export default function FormDropdown(props) {
  const { id, type, question, alternatives, answer = {}, setStateValues, captionText } = props;

  const handleChange = ({ target: { value = null } }) => {
    const alternative = alternatives.find(({ id: alternativeId }) => alternativeId === value);
    setStateValues(id, type, alternative, alternative.value);
  };

  const selectedValue = answer?.value?.id ?? "";

  return (
    <div className="formControl">
      <label className="questionLabel">{question}</label>
      <FormControl className="dropdown" variant="outlined">
        <Select style={styles} value={selectedValue} onChange={handleChange} placeholder="Velg ...">
          {alternatives.map((v, i) => (
            <MenuItem key={i} value={v.id} name={v.value}>
              {v.value}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      {captionText && (
        <Typography variant="caption" display="block" gutterBottom>
          {captionText}
        </Typography>
      )}
    </div>
  );
}
