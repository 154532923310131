import http, { createRequest, createUrl } from '../util/http';

const PAYMENT_ENDPOINT = '/payment';

export default function getPaymentIntentId(testamentId) {
  return http(createUrl(`${PAYMENT_ENDPOINT}/${testamentId}`), createRequest({
    method: 'GET'
  }), false);
}

export function getPaymentMethods() {
  return http(createUrl(PAYMENT_ENDPOINT + '/methods'), createRequest({
    method: 'GET'
  }));
}

export function deleteCard(paymentMethodId) {
  return http(createUrl(`${PAYMENT_ENDPOINT}/delete/${paymentMethodId}`), createRequest({
    method: 'DELETE'
  }));
}

export async function getIsPayedOrFree(testamentId) {
  return http(
    createUrl(`${PAYMENT_ENDPOINT}/IsPayedOrFree/${testamentId}`),
    await createRequest()
  )
}

export async function completed(paymentIntentId) {
  return http(
    createUrl(`${PAYMENT_ENDPOINT}/completed/${paymentIntentId}`),
    createRequest({ method: "POST" })
  )
}