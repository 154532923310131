import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import ENUMS from "../enums";
import { getForm, postForm, downloadForm, deleteForm } from "../services/form-data-service";
import storeAnswers, { get } from "../services/local-storage-service";
import mergeAnswers from "../util/merge-answers";
import { debounce } from "@material-ui/core";
import { getIsPayedOrFree } from "../services/payment-data-service";

const FormContext = React.createContext({
  isFormValid: false,
});

export class FormContextProvider extends Component {
  constructor(props) {
    super(props);
    const { preset, testamentId, isPayedOrFree } = props;
    const { id, content: questions, type, answers = [], text } = props.form;

    this.addPresetAnswers = this.addPresetAnswers.bind(this);
    this.setStateValues = this.setStateValues.bind(this);
    this.getSubmitFn = this.getSubmitFn.bind(this);
    this.saveForm = this.saveForm.bind(this);
    this.getLiveQuestions = this.getLiveQuestions.bind(this);
    this.checkContentRequirements = this.checkContentRequirements.bind(this);
    this.checkRequirement = this.checkRequirement.bind(this);
    this.getActiveAnswers = this.getActiveAnswers.bind(this);
    this.getValuesFromAnswers = this.getValuesFromAnswers.bind(this);
    this.getConvertedAnswersForSave = this.getConvertedAnswersForSave.bind(this);
    this.doSetState = this.doSetState.bind(this);
    this.getDebouncedSaveFn = this.getDebouncedSaveFn.bind(this);
    this.debouncedSaveFn = this.getDebouncedSaveFn();

    const parsedAnsweres = answers && JSON.parse(answers).answers;
    const answersArray = parsedAnsweres || [];
    let presetAnswers = [];

    if (preset && answersArray && answersArray.length === 0) {
      if (id === ENUMS.FORM_TYPE.RECIPROCAL) {
        presetAnswers.push({ contentId: ENUMS.RECIPROCAL_ORGIDS.PERSON, id: preset.id });
        presetAnswers.push({ contentId: ENUMS.RECIPROCAL_ORGIDS.PARTNER, id: preset.id });
        presetAnswers.push({ contentId: ENUMS.RECIPROCAL_ORGIDS.SHARED, id: preset.id });
      } else {
        this.addPresetAnswers(119, preset.id);
      }
    }
    this.state = { testamentId, isPayedOrFree };
    const activeQuestions = this.getLiveQuestions(questions, answersArray);
    this.state = {
      id,
      testamentId,
      questions,
      liveQuestions: activeQuestions,
      presetAnswers: presetAnswers.length > 0 ? presetAnswers : null,
      answers: answersArray,
      type,
      text,
      redirect: "",
      isFormValid: false,
      isPayedOrFree
    };
  }

  saveForm() {
    const { answers, questions, id, type, testamentId } = this.state;

    return postForm(
      {
        content: questions,
        id,
        testamentId,
        type,
      },
      this.getConvertedAnswersForSave(answers)
    );
  }

  getConvertedAnswersForSave(answers = []) {
    const convertedAnswers = [];
    answers.forEach((a) => {
      if (a.value && typeof a.value !== "string") {
        convertedAnswers.push({ ...a, value: JSON.stringify(a.value) });
      } else {
        convertedAnswers.push(a);
      }
    });
    return convertedAnswers;
  }

  getSubmitFn(action, endpoint) {
    return async () => {
      const { testamentId, type : formType} = this.state;
      // TODO: Validate form here, just not done with the validation code
      // if (!isFormValid) {
      //   return;
      // }

      if (action === ENUMS.ACTION.NEXT) {
        window.location.replace(endpoint.replace(":id", testamentId));
        return;
      }

      if (action === ENUMS.ACTION.DELETE) {
        deleteForm(testamentId);
        setTimeout(() => { window.location.replace("/minside") }, 200);
        return;
      }

      if (action === ENUMS.ACTION.SUBMIT) {
        const formResponse = await this.saveForm();
        const isPayedOrFree = await getIsPayedOrFree(formResponse.id);
        this.setState({ testamentId: formResponse.id, isPayedOrFree: isPayedOrFree });
        console.log("submit", formResponse.formId);
        let redirectUrl = "";
        switch (formResponse.formId) {
          case ENUMS.FORM_TYPE.SINGLE:
            redirectUrl = `/testament/${formResponse.id}`;
            break;
          case ENUMS.FORM_TYPE.RECIPROCAL:
            redirectUrl = `/gjensidig-testament/${formResponse.id}`;
            break;
          case ENUMS.FORM_TYPE.LIFEWILL:
            redirectUrl = `/fremtidsfullmakt/${formResponse.id}`;
            break;
          case ENUMS.FORM_TYPE.RECIPROCAL_LIFEWILL:
            redirectUrl = `/gjensidig-fremtidsfullmakt/${formResponse.id}`;
            break;
          default:
            console.error("Did not find any match for: " + formResponse.formId);
            redirectUrl = `/testament/${formResponse.id}`;
            break;
        }
        window.location.replace(redirectUrl);
        return;
      }

      if (action === ENUMS.ACTION.DOWNLOAD) {
        let fileName = "";
        switch (formType) {
          case ENUMS.FORM_TYPE.SUMMARY:
            fileName = `testament.pdf`;
            break;
          case ENUMS.FORM_TYPE.RECIPROCAL_SUMMARY:
            fileName = `gjensidig-testament.pdf`;
            break;
          case ENUMS.FORM_TYPE.LIFEWILL_SUMMARY:
            fileName = `fremtidsfullmakt.pdf`;
            break;
          case ENUMS.FORM_TYPE.RECIPROCAL_LIFEWILL_SUMMARY:
            fileName = `gjensidig-fremtidsfullmakt.pdf`;
            break;
          default:
            fileName = `export.pdf`;
            break;
        }
        await downloadForm(testamentId, fileName);
        return;
      }

      if (action === ENUMS.ACTION.REDIRECT) {
        window.location.replace(endpoint);
        return;
      }

      if (action === ENUMS.ACTION.REDIRECT_NEW_TAB) {
        window.open(endpoint, "_blank");
        return;
      }

      //NOT NEEDED ??
      const { content: questions, id, type, text, answers: updatedAnswers = [] } = await getForm(endpoint);
      const localAnswers = mergeAnswers(get(), updatedAnswers || []);
      this.doSetState({
        questions,
        id,
        type,
        text,
        answers: [...localAnswers],
      });
      window.scrollTo(0, 0);
    };
  }

  getDebouncedSaveFn() {
    return debounce(async () => {
      const formResponse = await this.saveForm();
      this.setState({ testamentId: formResponse.id });
    }, 2500);
  }

  doSetState(state) {
    const { id, answers } = state;
    storeAnswers(answers);

    if (id !== 0 && id !== 1) {
      this.debouncedSaveFn && this.debouncedSaveFn();
    }
    this.setState(state);
  }

  setStateValues(key, contentType, value, alternativeText) {
    const { id, answers, questions } = this.state;
    const questionValue = answers.find((v) => v.id === key);

    if (!questionValue) {
      answers.push({ id: key, value, alternativeText, contentType });
    } else {
      questionValue.value = value;
      questionValue.alternativeText = alternativeText;
    }

    const activeQuestions = this.getLiveQuestions(questions);

    const { presetAnswers } = this.state;
    if (presetAnswers && presetAnswers.length > 0) {
      activeQuestions.forEach(question => {
        if (question.page === 3) {
          question.content.forEach(c => {
            if (presetAnswers.find(p => p.contentId === c.id)) {
              const preset = presetAnswers.find(p => p.contentId === c.id);
              this.addPresetAnswers(preset.contentId, preset.id)
              this.setState({ presetAnswers: presetAnswers.filter(p => p.contentId !== c.id) })
            }
          })
        }
      })
    }

    const activeAnswers = this.getActiveAnswers(answers, activeQuestions);

    this.doSetState({
      id,
      answers: activeAnswers,
      liveQuestions: activeQuestions,
      isFormValid: this.validateForm(activeAnswers),
    });
  }

  // Returns only answers that have an active corresponding question
  getActiveAnswers(answers, liveQuestions) {
    const questionIds = liveQuestions
      .map(({ content }) => content?.map(({ id }) => id))
      .flat();

    return answers.filter(({ id }) => questionIds.indexOf(id) > -1);
  }

  getLiveQuestions(questions, answers) {
    return this.checkContentRequirements(questions, null, answers).map(({ content, ...question }) => {
      return {
        ...question,
        content: this.checkContentRequirements(content, null, answers),
      };
    });
  }

  checkContentRequirements(content, contentIndex, answers) {
    return content && content.filter(({ requirements }) => !requirements || requirements.every((r) => this.checkRequirement(r, contentIndex, answers)));
  }

  checkRequirement(requirement, contentIndex, answers) {
    const { type, requirements = [], contentId, operatorType, comparer, datetime } = requirement;
    const answer = this.getValuesFromAnswers(contentId, answers);

    switch (type) {
      case ENUMS.REQUIREMENT_TYPE.GROUP:
        return operatorType === ENUMS.OPERATOR_TYPE.AND
          ? requirements.every((r) => this.checkRequirement(r, contentIndex, answers))
          : requirements.some((r) => this.checkRequirement(r, contentIndex, answers));

      case ENUMS.REQUIREMENT_TYPE.VALUE:
        return this.checkValueRequirement(answer?.value, requirement);
      case ENUMS.REQUIREMENT_TYPE.BOOLEAN:
        return this.checkBooleanRequirement(answer?.value, requirement);
      case ENUMS.REQUIREMENT_TYPE.DATE:
        return answer && this.compareDate(answer.value, datetime, comparer);

      case ENUMS.REQUIREMENT_TYPE.DYNAMICVALUE:
        const content = this.getValuesFromAnswers(contentId, answers);
        return content && this.checkDynamicRequiremets(content, contentIndex, requirement);
      case ENUMS.REQUIREMENT_TYPE.ANY:
        return this.checkAnyRequirement(answer?.value);
      case ENUMS.REQUIREMENT_TYPE.COMPARE:
        return this.checkCompareRequirement(answer, requirement);
      case ENUMS.REQUIREMENT_TYPE.IS_PAYED_OR_FREE:
        return this.checkIsPayedOrFreeRequirement(requirement);
      case ENUMS.REQUIREMENT_TYPE.CAN_ALTER:
        return this.checkCanAlterRequirement(requirement);
      case ENUMS.REQUIREMENT_TYPE.NONE:
        return this.checkNoneRequirement(answer?.value);
      default:
        console.log("Not supported requirement type " + type);
        return true;
    }
  }

  getValuesFromAnswers(contentId, answers) {
    if (!answers) {
      answers = this.state.answers;
    }

    if (Array.isArray(contentId)) {
      return answers.filter((value) => contentId.includes(value.id));
    } else {
      return answers.find((value) => contentId === value.id);
    }

  }

  checkValueRequirement = (value, requirement) => (value && requirement.valueIds.includes(value)) || requirement.valueIds.includes(parseInt(value));
  checkBooleanRequirement = (value, requirement) => value && value.find((v) => v.checked === requirement.value);
  checkAnyRequirement = (value) => value && !Array.isArray(value) || Array.isArray(value) && value.length > 0 && value.some(v => Array.isArray(v.value) && v.value.length > 0 && v.value.some(nv => nv.value) || !Array.isArray(v.value) && v.value);
  checkNoneRequirement = (value) => !value || !(value && !Array.isArray(value) || Array.isArray(value) && value.length > 0 && value.some(v => Array.isArray(v.value) && v.value.length > 0 && v.value.some(nv => nv.value) || !Array.isArray(v.value) && v.value));
  checkCompareRequirement = (answers, requirement) => {
    let sumOfValues = 0;

    if (!answers || answers.length === 0) {
      return false;
    }

    const values = answers.map((a) => this.getNestedValues(a.value)).flat();

    if (values && values.length > 0) {
      values.forEach(v => {
        const parsed = parseInt(v.replace(" ", "").trim());
        if (Number.isInteger(parsed)) {
          sumOfValues += parsed;
        }
      });
    }

    return this.compareValue(sumOfValues, requirement.value, requirement.operator);
  }

  checkIsPayedOrFreeRequirement = (requirement) => {
    const { isPayedOrFree } = this.state;
    return isPayedOrFree === requirement.value;
  }

  checkCanAlterRequirement = (requirement) => {
    const { canAlter } = this.state;
    return canAlter === requirement.value;
  }

  getNestedValues = (value) => {
    let values = [];
    if (!value) {
      return null;
    }

    if (!Array.isArray(value)) {
      values.push(value);
    }
    else {
      value.forEach(v => {
        let nestedValues = this.getNestedValues(v.value);
        if (nestedValues) {
          values = [...values, ...nestedValues];
        }
      });
    }

    if (values.length > 0) {
      return values;
    }
    else {
      return null;
    }
  }

  checkDynamicRequiremets = (content, contentIndex, requirement) => {
    const contentSubValue = content.value[contentIndex]?.value[requirement.contentIndex];

    if (requirement.propertyName === "value") {
      const answerValueId = contentSubValue?.value.id;
      return requirement.valueIds.includes(answerValueId);
    } else {
      const fullValue = contentSubValue?.value.fullValue;
      return fullValue && requirement.valueIds.includes(fullValue[requirement.propertyName]);
    }
  };

  compareValue(value, requirementValue, operator) {
    switch (operator) {
      case ENUMS.COMPARE_TYPE.EQUAL:
        return value === requirementValue;
      case ENUMS.COMPARE_TYPE.GREATER:
        return value > requirementValue;
      case ENUMS.COMPARE_TYPE.LESS:
        return value < requirementValue;
      case ENUMS.COMPARE_TYPE.GREATER_OR_EQUAL:
        return value >= requirementValue;
      case ENUMS.COMPARE_TYPE.LESS_OR_EQUAL:
        return value <= requirementValue;
      default:
        console.log("Not supported CompareType: " + operator);
        return false;
    }
  }

  validateForm() {
    const { answers, liveQuestions } = this.state;
    const activeQuestions = liveQuestions.filter((q) => q.type !== ENUMS.CONTENT_TYPE.ACTION);
    return activeQuestions.every(({ id: questionId }) => answers.find(({ id }) => id === questionId));
  }

  addPresetAnswers(contentId, presetId) {
    if (presetId) {
      fetch("https://api.organisasjon.arv.no/simpleorganizations?category=2")
        .then((response) => response.json())
        .then((data) => {
          this.fillOrgState(contentId, parseInt(presetId), data);
        });
    }
  }

  fillOrgState(contentId, presetId, data) {
    const org = data.find((d) => d.Id === presetId);
    if (org) {
      this.setStateValues(contentId, ENUMS.CONTENT_TYPE.DYNAMIC_LIST, [
        {
          id: 1,
          value: [
            {
              id: "1",
              value: {
                id: "1",
                value: { Id: presetId, Name: org.Name, Value: org.Value },
              },
              contentType: 20,
            },
          ],
        },
      ])
    };
  }

  render() {
    const { children } = this.props;
    const { questions, answers, liveQuestions, activeGroup, text, testamentId, type } = this.state;

    return (
      <>
        {this.state.redirect && <Redirect to={this.state.redirect} />}
        <FormContext.Provider
          value={{
            questions,
            answers,
            liveQuestions,
            text,
            type,
            testamentId,
            activeGroup: activeGroup,
            setStateValues: this.setStateValues,
            getSubmitFn: this.getSubmitFn,
            isFormValid: this.state.isFormValid,
            getLiveQuestions: this.getLiveQuestions,
            checkContentRequirements: this.checkContentRequirements,
          }}
        >
          {children}
        </FormContext.Provider>
      </>
    );
  }
}

export const FormContextConsumer = FormContext.Consumer;
