import http, { createRequest, createUrl } from "../util/http";

const USER_ENDPOINT = "/sendmail";

export function postEmail(emailType, name, email, phoneNumber, message, testamentId) {
  return http(
    createUrl(USER_ENDPOINT),
    createRequest({
      method: 'POST',
      body: JSON.stringify({
        emailType,
        phoneNumber,
        name,
        emailAddress: email,
        message,
        testamentId
      })
    }),
    false,
    true
  )
}