import React from "react";
import UserSettings from "../user-settings/user-settings";

export default function Settings() {
  return (
    <section id="user-settings">
      <div className="textFormControl">
        <h3>Nyhetsbrev og samtykker</h3>
        <p>
        Vi vil gjerne holde deg oppdatert innen arv, testament og fremtidsfullmakt. 
        Vi sender vanligvis ut et nyhetsbrev per måned. 
        Du kan når som helst melde deg av nyhetsbrevet, eller endre dine samtykker.
        </p>
        <div className="formControls">
          <UserSettings />
        </div>
      </div>
    </section>
  );
}
