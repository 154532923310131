import React from "react";
import { ComposedChart, Line, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from "recharts";
import ENUMS from "../../enums";

export default function ComposedBarLineChart({ title, series, chartData }) {
  return (
    <>
      {chartData && (
        <div>
          <h3>{title}</h3>
          <ResponsiveContainer aspect={1.2} maxHeight={600}>
            <ComposedChart data={chartData} margin={{ top: 5, right: 30, left: 20, bottom: 50 }}>
              <CartesianGrid stroke="#f5f5f5" />
              <XAxis dataKey="name" />
              <YAxis />
              <Legend />
              <Tooltip />
              {series.map((s, i) => {
                if (s.type === ENUMS.CHARTSERIESTYPE.BAR) 
                  return <Bar key={i} dataKey={s.name} stackId={s.stackId} barSize={60} fill={s.color} />;
                else
                  return <Line key={i} type="monotone" dataKey={s.name} stroke={s.color} strokeWidth={2} dot={{ strokeWidth: 2, r: 5, strokeDasharray: "" }} />;
              })}
            </ComposedChart>
          </ResponsiveContainer>
        </div>
      )}
    </>
  );
}
