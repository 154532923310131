import React, { useContext, useEffect, useState } from "react";
import Header from "../components/header/header";
import FormSection from "../components/section/form-section/form-section";
import { getForm } from "../services/form-data-service";
import PreviewSection from "../components/section/preview-section/preview-section";
import { FormContextProvider } from "../context/form-context";
import UIContext from "../context/ui-context";
import { useLocation, useParams } from "react-router-dom";
import LoaderSpinner from "../components/loader/loader";
import { getIsPayedOrFree } from "../services/payment-data-service";

/***
 * Map for url path to API id:
 * @type {{path: id}}
 */
const PATH_ID_MAP = {
  "/form": 2,
  "/skriv": 2,
  "/testament": 3,
  "/gjensidig": 4,
  "/gjensidig-testament": 5,
  "/skriv-fremtidsfullmakt": 6,
  "/fremtidsfullmakt": 7,
  "/skriv-gjensidig-fremtidsfullmakt": 8,
  "/gjensidig-fremtidsfullmakt": 9,
  
  "/demo": 999,
};

export default function FormPage(props) {
  const [form, setForm] = useState(null);
  const [preset, setPreset] = useState(null);
  const [isPayedOrFree, setIsPayedOrFree] = useState(false);
  const {
    state: { mode: uiMode = "none" },
  } = useContext(UIContext);
  const { testamentId } = useParams();
  const { pathname, search } = useLocation();

  useEffect(() => {
    getFirstForm(testamentId);
  }, []);

  return (
    <main>
      <Header hasPreview={true} />
      {form ? (
        <FormContextProvider form={form} preset={preset} testamentId={testamentId} isPayedOrFree={isPayedOrFree}>
          <div className={`app container ui-mode--${uiMode}`}>
            <FormSection />
            <PreviewSection />
          </div>
        </FormContextProvider>
      ) : (
        <>
          <LoaderSpinner />
        </>
      )}
    </main>
  );

  async function getFirstForm(testamentId) {
    const id = PATH_ID_MAP[pathname.match(/[^\/]*\/[^\/]*/)[0]] ?? 2;

    const form = await getForm(testamentId ? `/FormPage/${id}/${testamentId}` : `/FormPage/${id}`);
    if (testamentId) {
      const payedOrFree = await getIsPayedOrFree(testamentId);
      setIsPayedOrFree(payedOrFree);
    }
    if (search) {
      const query = JSON.parse('{"' + search.substring(1).replace(/&/g, '","').replace(/=/g, '":"') + '"}', function (key, value) {
        return key === "" ? value : decodeURIComponent(value);
      });
      setPreset(query);
    }

    setForm(form);
  }
}
