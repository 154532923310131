import "whatwg-fetch";
import download from "downloadjs";
import http, { createRequest, createUrl } from "../util/http";

export async function getForm(path, secure = true) {
  const req = await createRequest({}, secure);
  return fetch(createUrl(`${path}`), req)
    .then((data) => data.json())
    .catch((e) => {
      console && console.error && console.error("Request for form failed") && console.error(e);
    });
}

export function postForm(form, values) {
  const body = {
    answers: values,
  };

  return http(
    createUrl(form.testamentId ? `/FormPage/${form.id}/${form.testamentId}` : `/FormPage/${form.id}`),
    createRequest({
      body: JSON.stringify(body),
      method: "POST",
    })
  );
}

export async function downloadForm(id, fileName) {
  return fetch(createUrl(`/ExportPdf/${id}`), await createRequest())
    .then(function (resp) {
      return resp.blob();
    })
    .then(function (blob) {
      return download(blob, fileName);
    })
    .catch((e) => {
      console && console.error && console.error(e);
    });
}

export function deleteForm(testamentId) {
  return http(createUrl(`/FormPage/${testamentId}`), createRequest({ method: "DELETE" }));
}

export function getCanAlter(testamentId) {
  return http(createUrl(`/FormPage/canAlter/${testamentId}`), createRequest());
}

export function postFormPrint(formId, name, address, postArea, postCode, email) {
  const body = {
    name,
    address,
    postArea,
    postCode,
    email,
    formId
  };

  return http(
    createUrl(`/Print`),
    createRequest({
      body: JSON.stringify(body),
      method: "POST",
    })
  );
}

export function getFormPrintInfo(id) {
  return http(createUrl(`/Print/${id}`), createRequest());
}
