import QUESTIONS from "../../components/section/preview-section/QUESTION_MAP";
import ENUMS from "../../enums";

export function getAnswerById(id, answers = [], returnFull = false) {
  const item = answers.find(({ id: answerId }) => id === answerId) || {};
  if (returnFull) {
    return item;
  }
  const { value } = item;
  return value;
}

export function isTrueByIdAndAnswerValue(id, answerValue, answers = []) {
  const { contentType, value } = answers.find(({ id: answerId }) => id === answerId) || {};
  if (value && contentType === ENUMS.CONTENT_TYPE.CHECKBOX) {
    return value[0].checked === answerValue;
  }

  return value === answerValue;
}

export function hasChildren(answers) {
  const childrenIds = [QUESTIONS.HAS_COMMON_CHILDREN, QUESTIONS.HAS_CHILDREN_2, QUESTIONS.HAS_SAERKULL_CHILDREN];
  const childrenAnswers = childrenIds.map((questionId) => getAnswerById(questionId, answers));

  return childrenAnswers.some((value) => ENUMS.HAS_CHILDREN_IDS.YES.indexOf(parseInt(value, 10)) > -1);
}

export function getRelationshipStatus(answers) {
  let relationshipStatus = getAnswerById(QUESTIONS.RELATIONSHIP_STATUS, answers);
  if (relationshipStatus && typeof relationshipStatus === "string") {
    relationshipStatus = parseInt(relationshipStatus);
  }
  return relationshipStatus;
}

/**
 * Helper method that returns booleans for children/partner state.
 *
 * @param answers
 * @param isReciprocal - boolean indicating reciprocal testament or not.
 *
 * @returns {
 * {hasOwnChildren: *,
 * partnerOverFiveYears: *,
 * hasOnlyOwn: (boolean|*),
 * hasAnyChildren: ((function(): *)|*),
 * hasCommonChildren: (function(): *)}}
 */
export function getFamilyState(answers, isReciprocal) {
  const hasCommonChildren = answers.some((a) => compareToCommonChildrenIds(a.id, a.value, isReciprocal));

  const hasOwnChildren = answers.some((a) => compareToOwnChildrenIds(a.id, a.value, isReciprocal));

  const hasPartnerChildren = isReciprocal && answers.some((a) => compareToPartnerChildrenIds(a.id, a.value, isReciprocal));

  const partnerOverFiveYears = answers.some((a) => comparePartnerOverFiveYearsIds(a.id, a.value, isReciprocal));

  const hasAnyChildren = hasCommonChildren || hasOwnChildren;
  const hasOnlyOwn = !hasCommonChildren && hasOwnChildren;

  return {
    hasCommonChildren,
    hasOwnChildren,
    hasPartnerChildren,
    partnerOverFiveYears,
    hasAnyChildren,
    hasOnlyOwn,
  };
}

function compareToCommonChildrenIds(id, value, isReciprocal) {
  if (isReciprocal) {
    return (
      (typeof id === "string" ? parseInt(id) : id) === ENUMS.HAS_COMMON_CHILDREN.RECIPROCAL.QUESTIONID &&
      (typeof value === "string" ? parseInt(value) : value) === ENUMS.HAS_COMMON_CHILDREN.RECIPROCAL.YES
    );
  } else {
    return id == ENUMS.HAS_COMMON_CHILDREN.NORMAL.QUESTIONID && value == ENUMS.HAS_COMMON_CHILDREN.NORMAL.YES;
  }
}

function compareToOwnChildrenIds(id, value, isReciprocal) {
  if (isReciprocal) {
    return (
      (typeof id === "string" ? parseInt(id) : id) === ENUMS.HAS_OWN_CHILDREN.RECIPROCAL.QUESTIONID &&
      (typeof value === "string" ? parseInt(value) : value) === ENUMS.HAS_OWN_CHILDREN.RECIPROCAL.YES
    );
  } else {
    return id == ENUMS.HAS_OWN_CHILDREN.NORMAL.QUESTIONID && value == ENUMS.HAS_OWN_CHILDREN.NORMAL.YES;
  }
}

function comparePartnerOverFiveYearsIds(id, value, isReciprocal) {
  if (isReciprocal) {
    return (
      (typeof id === "string" ? parseInt(id) : id) === ENUMS.HAS_LIVED_TOGHETER_OVER_FIVE_YEARS.RECIPROCAL.QUESTIONID &&
      (typeof value === "string" ? parseInt(value) : value) === ENUMS.HAS_LIVED_TOGHETER_OVER_FIVE_YEARS.RECIPROCAL.YES
    );
  } else {
    return id == ENUMS.HAS_LIVED_TOGHETER_OVER_FIVE_YEARS.NORMAL.QUESTIONID && value == ENUMS.HAS_LIVED_TOGHETER_OVER_FIVE_YEARS.NORMAL.YES;
  }
}

function compareToPartnerChildrenIds(id, value) {
  return (
    (typeof id === "string" ? parseInt(id) : id) === ENUMS.HAS_PARTNER_CHILDREN.RECIPROCAL.QUESTIONID &&
    (typeof value === "string" ? parseInt(value) : value) === ENUMS.HAS_PARTNER_CHILDREN.RECIPROCAL.YES
  );
}

/***
 * Get all heirs for normal will, always returns an array.
 * @param answers
 * @returns {unknown[]}
 */
export function getHeirs(answers) {
  const personIds = [QUESTIONS.PEOPLE, QUESTIONS.PEOPLE2, QUESTIONS.PEOPLE3, QUESTIONS.PEOPLE4, QUESTIONS.PEOPLE5, QUESTIONS.PEOPLE6];
  return createHeirsList(answers, personIds);
}

export function createHeirsList(answers, personIds) {
  return personIds
    .map((personId) => getAnswerById(personId, answers) || [])
    .flat()
    .map((heir) => ({
      ...heir,
      inheritance: heir.inheritance || [],
    }));
}
