import React from "react";
import "../../App.scss";

export default function TitleElement(props) {
  const { title, size } = props;

  function getTitle() {
    switch (size) {
      case "h1":
        return <h1 className="title">{title}</h1>;
      case "h2":
        return <h2 className="title">{title}</h2>;
      case "h3":
        return <h3 className="title">{title}</h3>;
      case "h4":
        return <h4 className="title">{title}</h4>;
      default:
        return <h3 className="title">{title}</h3>;
    }
  }

  return <div className="textFormControl">{getTitle()}</div>;
}
