import React from "react";
import FormElement from "../form-element";
import ENUMS from "../../enums";

export default function LinkedContent(props) {
  const { id, linkIds, linkType, linkPropertyName, element, answers, setStateValues, answer } = props;
  if (id === 134) {
    linkIds.push(119);
  }
  const linkedValues = answers.filter((v) => linkIds.includes(v.id));

  if (!linkedValues) return "";

  switch (linkType) {
    case ENUMS.LINKTYPE.TEXT:
      element.text = linkedValues[0].alternativeText;
      break;
    case ENUMS.LINKTYPE.VALUES:
      element.values = linkedValues.map((a) => a.alternativeText);
      break;
    case ENUMS.LINKTYPE.DYNAMICVALUE:
      let values = linkedValues.map(({ value: linkedValue }) => linkedValue.map((v) => getValue(v))).flat();

      if (values.some(v => Array.isArray(v))) {
        values = values.flat();
      }

      values = values.filter(v => v.value)
      element.id = id;
      element.values = values.filter(Boolean);

      if (!element.values) {
        return;
      }
      break;
    default:
      console.log("Did not find linktype");
  }

  function getValue(item) {
    if (item.value && typeof item.value !== 'string') {
      if (Array.isArray(item.value)) {
        return item.value.map(i => getValue(i));
      } else {
        return getValue(item.value);
      }
    } else {
      if (item[linkPropertyName]) {
        return { id: item.id, value: item[linkPropertyName], fullValue: item }
      }
      else {
        return { id: item.Id, value: item[capitalize(linkPropertyName)], fullValue: item }
      }
    }
  }

  function capitalize(s) {
    if (typeof s !== 'string') return ''
    return s.charAt(0).toUpperCase() + s.slice(1)
  }

  return <FormElement element={element} key={id} onSetState={setStateValues} answer={answer} />;
}
