import React from 'react';
import ENUMS from "../enums";

const smallWidth = 1280;

export const uiState = {
  isSmallScreen: getScreenWidth() < smallWidth,
  mode: ENUMS.UI_STATE.FORM
};

function getScreenWidth() {
  return Math.max(
    document.body.scrollWidth,
    document.documentElement.scrollWidth,
    document.body.offsetWidth,
    document.documentElement.offsetWidth,
    document.documentElement.clientWidth
  );
}

export function uiReducer(state, { type }) {
  switch(type) {
    case ENUMS.UI_ACTIONS.SWAP_MODE:
      return {
        ...state,
        mode: state?.mode === ENUMS.UI_STATE.FORM ? ENUMS.UI_STATE.PREVIEW : ENUMS.UI_STATE.FORM
      };

    default:
      return uiState;
  }
}

const UIContext = React.createContext();
export default UIContext;
