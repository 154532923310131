import React from "react";
import { AzureAD, AuthenticationState } from "react-aad-msal";
import { authProvider, handleForgotPassword } from "../../util/authProvider";
import OIDCWrapper from "./OIDCWrapper";

export default function AuthenticatedRoute({ children }) {
  return (
    <AzureAD provider={authProvider} forceLogin={true}>
      {({ authenticationState, error }) => {
        switch (authenticationState) {
          case AuthenticationState.Authenticated:
            return <OIDCWrapper>{children}</OIDCWrapper>;
          case AuthenticationState.Unauthenticated:
            handleForgotPassword(error);
          // return;
          case AuthenticationState.InProgress:
            return <p>...</p>;
          default:
            return <p>..</p>;
        }
      }}
    </AzureAD>
  );
}
