const QUESTIONS = {
  LIVES_ABROAD: 101,
  RELATIONSHIP_STATUS: 103,

  USER: 100, // Data om personen som lager testament eller den personen testamentet er for.
  HAS_COMMON_CHILDREN: 104, // Felles
  HAS_CHILDREN_2: 105, // HAr du barn
  HAS_SAERKULL_CHILDREN: 106, // Særkullsbarn

  PEOPLE: 113,
  PEOPLE2: 114,
  PEOPLE3: 115,
  PEOPLE4: 116,
  PEOPLE5: 117,
  PEOPLE6: 120,

  INHERITANCE: 129,

  ORGANISATION_DONATION: 121, // OrganizationDonation
  DISTRIBUTE_SELF: 130, // Velg selv fordeling.

  PARTNER_USKIFTET_BO: 135,

  DISTRIBUTE_ALL_VESTIGE: 132,
  ALL_VESTIGE: 134,

  VESTIGE: 131,
  // ALL_VESTIGE: 113,
  HAS_COMMEN_CHILDREN: 104,
  SAERKULL_CHILD: 106,
  SAMBOER_TIME: 107,

  HAS_PREVIOUS_WILL: 111,

  ARVEPAKT: 112,
  EIENDELER: 122,
  FORDELING: 121,
  FORDELING_AV_REST: 133,
  USKIFTET_BO: 109,

  GRAVLAT: 145,

  HAS_FUNERAL_WISHES: 136,
  FUNERAL_MONEY: 137,
  FUNERAL_WISHES: 138,
  WILL_RESPONSIBLE: 141,

  RETRACT_PREVIOUS_WILL: 145,
  SIGNING_PLACE: 148,

  PLACE_CITY: 146,
  PLACE_DATE: 147,

  WITNESS_1: 152,
  WITNESS_2: 156,

  WITNESS_NAME_1: 149,
  WITNESS_PROFESSION_1: 150,
  WITNESS_ADDRESS_1: 151,

  WITNESS_NAME_2: 153,
  WITNESS_PROFESSION_2: 154,
  WITNESS_ADDRESS_2: 155,
};

export const RECIPROCAL_QUESTION_IDS = {
  USER: 100,
  LIVES_ABROAD: 101,

  RELATIONSHIP_STATUS: 103,

  //--//

  PEOPLE_OWN_CHILDREN: 114,
  PEOPLE_OWN_OTHER: 119, // Bruker - andre relasjoner / selvvalgte arvinger

  PEOPLE_PARTNER_CHILDREN: 115,
  PEOPLE_PARTNER_OTHER: 122, // Partner - andre relasjoner / selvvalgte arvinger

  PEOPLE_COMMON_CHILDREN: 116,
  PEOPLE_COMMON_OTHERS: 125,

  //--//

  HAS_COMMON_CHILDREN: 104, // Felles
  HAS_OWN_CHILDREN: 105, // Særkull bruker
  HAS_PARTNER_CHILDREN: 106, // Særkull barn partner

  PARTNER_TIME: 107, // lived together

  OWN_ORGANIZATION_DONATION: 129,
  PARTNER_ORGANIZATION_DONATION: 131,
  COMMON_ORGANIZATION_DONATION: 127,

  OWN_INHERITANCE: 128,
  PARTNER_INHERITANCE: 130,
  COMMON_INHERITANCE: 126,

  //--//

  RECEIVE_AFTER_DEATH: 139,

  LONGEST_LIVING: 132,
  LONGEST_LIVING_DEAD: 133,
  USKIFTE: 134,

  RETRACT_PREVIOUS_WILL: 136,

  RETRACT_PREVIOUS_WILL_OWN: 137,
  RETRACT_PREVIOUS_WILL_PARTNER: 138,

  SIGNING_PLACE: 141,
  PLACE_CITY: 139,
  PLACE_DATE: 140,

  WITNESS_1: 145,
  WITNESS_2: 149,

  WITNESS_NAME_1: 142,
  WITNESS_PROFESSION_1: 143,
  WITNESS_ADDRESS_1: 144,

  WITNESS_NAME_2: 146,
  WITNESS_PROFESSION_2: 147,
  WITNESS_ADDRESS_2: 148,

  OTHER_WISES: 152,

  // HAS_PREVIOUS_WILL: 108,
  // PARTNER_HAS_PREVIOUS_WILL: 111,
  //
  // INHERITANCE_PACT: 109,
  // PARTNER_INHERITANCE_PACT: 112,
  //
  // DONT_EXIST: 9999
};

export const LIFEWILL_QUESTION_IDS = {
  USER: 100, // Data om personen som lager testament eller den personen testamentet er for.
  SIVILSTATUS: 101,
  HAS_CHILDREN: 102, // Felles

  PROXY_GROUP: 106,
  PROXY_NAME: 103,
  PROXY_IDENTIFICATION: 104,
  PROXY_RELATION: 105,

  RESERVE_PROXY: 107,

  RESERVE_PROXY_GROUP: 111,
  RESERVE_PROXY_NAME: 108,
  RESERVE_PROXY_IDENTIFICATION: 109,
  RESERVE_PROXY_RELATION: 110,

  ADMIN_INSURANCE: 112,
  ADMIN_ECONOMIC_PROPERTY: 113,
  ADMIN_SELL: 114,
  ADMIN_SPENDING: 115,

  ECONOMIC_INPUT: 116,
  INHERITANCE_INPUT: 118,
  HOME_PROPERTIES_INPUT: 119,
  BANK_INPUT: 120,
  HEALTH_INPUT: 121,
  LIVING_INPUT: 122,

  DONATIONS: 126,

  GIFTS_INPUT: 127,
  SOSIAL_MEDIA: 128,
  OTHER_INPUT: 129,

  PAYED_PROXY: 130,
  PAYED_PROXY_AMOUNT: 131,

  SIGNING_PLACE: 134,
  PLACE_CITY: 132,
  PLACE_DATE: 133,

  WITNESS_1: 138,
  WITNESS_2: 142,

  WITNESS_NAME_1: 135,
  WITNESS_PROFESSION_1: 136,
  WITNESS_ADDRESS_1: 137,

  WITNESS_NAME_2: 139,
  WITNESS_PROFESSION_2: 140,
  WITNESS_ADDRESS_2: 141,
};

export const LIFEWILL_VALUE_IDS = {
  PARTNER: 1001,
  MARRIED: 1002,
  HAS_CHILDREN: 1005,
  RESERVE_PROXY_YES: 1013,
  DONATION_TYPE_ONCE: 1027,
  DONATION_TYPE_YEARLY: 1028,
  SOSIAL_MEDIA_YES: 1029,
  PAYED_PROXY_NO: 1032
};

export const RECIPROCAL_LIFEWILL_QUESTION_IDS = {
  USER: 100, // Data om personen som lager testament eller den personen testamentet er for.
  SIVILSTATUS: 101,
  HAS_CHILDREN: 102, // Felles

  RESERVE_PROXY: 107,

  RESERVE_PROXY_GROUP: 107,
  RESERVE_PROXY_NAME: 104,
  RESERVE_PROXY_IDENTIFICATION: 105,
  RESERVE_PROXY_RELATION: 106,

  ADMIN_INSURANCE: 108,
  ADMIN_ECONOMIC_PROPERTY: 109,
  ADMIN_SELL: 110,
  ADMIN_SPENDING: 111,

  ECONOMIC_INPUT: 112,
  INHERITANCE_INPUT: 114,
  HOME_PROPERTIES_INPUT: 115,
  BANK_INPUT: 116,
  HEALTH_INPUT: 117,
  LIVING_INPUT: 118,

  DONATIONS: 122,

  GIFTS_INPUT: 123,
  SOSIAL_MEDIA: 124,
  OTHER_INPUT: 125,

  PAYED_PROXY: 126,
  PAYED_PROXY_AMOUNT: 127,

  SIGNING_PLACE: 130,
  PLACE_CITY: 128,
  PLACE_DATE: 129,

  WITNESS_1: 134,
  WITNESS_2: 138,

  WITNESS_NAME_1: 131,
  WITNESS_PROFESSION_1: 132,
  WITNESS_ADDRESS_1: 133,

  WITNESS_NAME_2: 135,
  WITNESS_PROFESSION_2: 136,
  WITNESS_ADDRESS_2: 137,
};

export const RECIPROCAL_LIFEWILL_VALUE_IDS = {
  PARTNER: 1000,
  MARRIED: 1001,
  HAS_CHILDREN: 1002,
  DONATION_TYPE_ONCE: 1016,
  DONATION_TYPE_YEARLY: 1017,
  SOSIAL_MEDIA_YES: 1018,
  PAYED_PROXY_NO: 1021
};


export default QUESTIONS;
