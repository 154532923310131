const ENUMS = {
  FORM_TYPE: {
    NORMAL: 2,
    WILL: 3,
    SINGLE: 2,
    SUMMARY: 3,
    RECIPROCAL: 4,
    RECIPROCAL_SUMMARY: 5,
    LIFEWILL: 6,
    LIFEWILL_SUMMARY: 7,
    RECIPROCAL_LIFEWILL: 8,
    RECIPROCAL_LIFEWILL_SUMMARY: 9,
  },
  CONTENT_TYPE: {
    RADIO: 0,
    INPUT: 1,
    DATESELECTOR: 2,
    TEXT: 3,
    STATIC_LIST: 4,
    DYNAMIC_LIST: 5,
    ACTION: 6,
    FORM_GROUP: 7,
    PERSON_LIST: 8,
    ADDRESS: 9,
    LAWYERMODAL: 10,
    PERSON_INFORMATION: 11,
    DISTRIBUTION_LIST: 12,
    CHECKBOX: 13,
    DROPDOWN: 14,
    LINKEDGROUP: 15,
    LINKEDVALUE: 16,
    IMAGE: 17,
    TITLE: 18,
    GROUP: 19,
    AUTOCOMPLETE_DROPDOWN: 20,
    ORGANISATION_DISTRIBUTION: 21,
    HORIZONTALLINE: 22,
    PARTNERS_INFORMATION: 23,
    PAYMENT_MODAL: 24,
    PRINT_MODAL: 25,
    RECEIPT_PAGE: 1337,
  },
  TEXT_TYPE: {
    TEXT: 0,
    UNORDERDLIST: 1,
    ORDEREDLIST: 2,
    LIST: 3,
  },
  ACTION: {
    REDIRECT: 0,
    SUBMIT: 1,
    NEXT: 2,
    DOWNLOAD: 3,
    DELETE: 4,
    REDIRECT_NEW_TAB: 5,
  },
  UI_ACTIONS: {
    SWAP_MODE: "swap-mode",
  },
  UI_STATE: {
    FORM: "form",
    PREVIEW: "preview",
  },
  LINKTYPE: {
    TEXT: 0,
    VALUES: 1,
    DYNAMICVALUE: 2,
  },
  REQUIREMENT_TYPE: {
    GROUP: 0,
    VALUE: 1,
    DATE: 2,
    DYNAMICVALUE: 4,
    BOOLEAN: 5,
    ANY: 6,
    NONE: 7,
    COMPARE: 8,
    IS_PAYED_OR_FREE: 9,
    CAN_ALTER: 10,
  },
  COMPARE_TYPE: {
    EQUAL: 0,
    GREATER: 1,
    LESS: 2,
    LESS_OR_EQUAL: 3,
    GREATER_OR_EQUAL: 4,
  },
  OPERATOR_TYPE: {
    AND: 0,
    OR: 1,
  },
  RELATION: {
    CHILD: 0,
    MARRIED: 1,
    PARTNER: 2,
    PARENT: 3,
    SIBLING: 4,
    OTHER: 5,
    ORGANISATION: 6,
    GRANDCHILDREN: 7,
    GREATGRANDCHILDREN: 8,
    OTHERFAMILY: 9,
  },
  HERITAGE: {
    PERCENTAGE: 0,
    AMOUNT: 1,
    ASSETS: 2,
    VESTIGE: 3,
    OBLIGATION: 4,
  },
  TESTAMENT_STATUS: {
    DRAFT: 0,
    ACTIVE: 1,
    INACTIVE: 2,
  },
  PROFILE_ACTIONS: {
    UPDATE: "update",
  },
  RELATIONSHIP_STATUS_CONTENTID: 103,
  RELATIONSHIP_STATUS: {
    UNMARRIED: 0,
    PARTNER: 1,
    MARRIED: 2,
    DIVORCED: 3,
    WIDOWER: 4,
  },
  RELATIONSHIP_STATUS_IDS: {
    UNMARRIED: 1001,
    PARTNER: 1002,
    MARRIED: 1003,
    DIVORCED: 1004,
    WIDOWER: 1005,
  },
  HAS_CHILDREN_IDS: {
    YES: [1006, 1008, 1010],
    NO: 1010,
  },
  HAS_OWN_CHILDREN: {
    RECIPROCAL: {
      QUESTIONID: 105,
      YES: 1005,
      NO: 1006,
    },
    NORMAL: {
      QUESTIONID: 106,
      YES: 1010,
      NO: 1011,
    },
  },
  HAS_PARTNER_CHILDREN: {
    RECIPROCAL: {
      QUESTIONID: 106,
      YES: 1007,
      NO: 1008,
    },
  },
  HAS_COMMON_CHILDREN: {
    RECIPROCAL: {
      QUESTIONID: 104,
      YES: 1003,
      NO: 1004,
    },
    NORMAL: {
      QUESTIONID: 104,
      YES: 1006,
      NO: 1007,
    },
  },
  HAS_LIVED_TOGHETER_OVER_FIVE_YEARS: {
    RECIPROCAL: {
      QUESTIONID: 107,
      YES: 1010,
      NO: 1011,
    },
    NORMAL: {
      QUESTIONID: 107,
      YES: 1013,
      NO: 1012,
    },
  },
  INPUT_PROPERTIES: {
    ADDRESS: 0,
    BIRTH_DATE: 1,
    RELATION: 2,
  },
  INPUT_TYPE: {
    TEXT: 0,
    NUMBER: 1,
    PASSWORD: 2
  },
  STRIPE_STATUS: {
    SUCCEEDED: "succeeded",
  },
  PEOPLEIDS: {
    WIFE: 113,
    PARTNER: 114,
    CHILDREN_1: 115,
    CHILDREN_2: 116,
    CHILDREN_3: 117,
    OTHER: 120,
  },
  DISTIBUTIONOWNER: {
    SHARED: 126,
    PERSON: 128,
    PARTNER: 130,
  },
  RECIPROCAL_ORGIDS: {
    PERSON: 118,
    PARTNER: 121,
    SHARED: 124,
  },
  DONATIONTYPE: {
    NONE: 0,
    MONEY: 1,
    PROSENTAGE: 2,
  },
  CHARTSERIESTYPE: {
    BAR: 0,
    LINE: 1,
  },
  STATSDONATIONTYPE: {
    NONE: 0,
    MONEY: 1,
    PROSENTAGE: 2,
    ONCE: 3,
    RECURRING: 4
  }
};

export const VESTIGE_RELATIONS = [ENUMS.RELATION.CHILD, ENUMS.RELATION.MARRIED, ENUMS.RELATION.PARTNER, ENUMS.RELATION.ORGANISATION];

export default ENUMS;
