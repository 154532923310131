import React, { useContext } from "react";
import Button from "@material-ui/core/Button";
import Icon from "@material-ui/core/Icon";
import "./header.scss";
import UIContext from "../../context/ui-context";
import ENUMS from "../../enums";
import { logout } from "../../util/authProvider";

const MODE_TEXT_MAP = {
  [ENUMS.UI_STATE.FORM]: "Vis",
  [ENUMS.UI_STATE.PREVIEW]: "Skjul",
};

export default function Header({
  externalHome = false,
  hasPreview = false,
  isPublic = false, // Are we rendering a public viewable header or no?
}) {
  const {
    state: { mode, isSmallScreen },
    dispatch,
  } = useContext(UIContext);

  return (
    <header className="frontHeader">
      <div className="frontHeaderSection">
        {externalHome ? (
          <>
            <span className="align-center no-link logo-wrap verticalCenter">
              <img src="/images/arv-logo.svg" alt="arv.no" className="logo" />{" "}
              <span className="textLarge">arv.no</span>
            </span>
            {isPublic === false && (
              <Button className="align-center no-link right" onClick={logout}>
                Logg ut
              </Button>
            )}
          </>
        ) : (
          <>
            <a
              className="align-center no-link verticalCenter dashboard-link"
              href="/minside"
            >
              <Icon>arrow_back</Icon>{" "}
              <span className="hidden-sm hidden-xs minside-txt">Min side</span>
            </a>
            <span className="align-center no-link logo-wrap verticalCenter logo-right">
              <img src="/images/arv-logo.svg" alt="arv.no" className="logo" />{" "}
              <span className="textLarge">arv.no</span>
            </span>
          </>
        )}
        {isSmallScreen && hasPreview && (
          <Button className="align-center no-link right" onClick={toggleMode}>
            {MODE_TEXT_MAP[mode]}
          </Button>
        )}
      </div>
    </header>
  );

  function toggleMode(e) {
    e.preventDefault();
    dispatch({
      type: ENUMS.UI_ACTIONS.SWAP_MODE,
      data: {},
    });
    return false;
  }
}
