import { Button } from "@material-ui/core";
import Header from "../components/header/header";
import { downloadForm, getFormPrintInfo } from "../services/form-data-service";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom/cjs/react-router-dom";

export default function PrintPage() {
    const { printId } = useParams();
    const [printForm, setPrintForm] = useState(null)

    useEffect(() => {
        if (printId) {
            getFormPrintInfo(printId).then((data) => {
                setPrintForm(data);
            });
        }
    }, [])

    return (
        <main className="print">
            <Header hasPreview={false} />
            <section className="section-content">
                <div className="section form">
                    <h2>Last ned og skriv ut</h2>
                    {printForm != null && <div>
                        <table>
                            <tbody>
                                <tr><td>Navn:</td><td>{printForm.name}</td></tr>
                                <tr><td>Adresse:</td><td>{printForm.address}</td></tr>
                                <tr><td>Postnr:</td><td>{printForm.postCode}</td></tr>
                                <tr><td>Poststed:</td><td>{printForm.postArea}</td></tr>
                                <tr><td>Epost:</td><td>{printForm.email}</td></tr>
                            </tbody>
                        </table>
                        <Button size="large" variant="contained" className="primaryButton" color="secondary"
                            onClick={() => downloadForm(printForm.formId, "")}
                        >
                            Last ned
                        </Button>
                    </div>
                    }
                </div>
            </section>
        </main>
    );
}