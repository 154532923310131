import formatCurrency from "./format-currency";

const gValue = 124028;
const gDate = "1.5.2024";

function getGValue(multiply) {
  const calcValue = gValue * multiply;

  return formatCurrency(calcValue);
}

export { getGValue, gValue, gDate };
