import React from 'react';
import { List, ListItem, ListItemIcon, ListItemText } from "@material-ui/core";
import Icon from "@material-ui/core/Icon";

function ListItemLink(props) {
  return <ListItem button component="a" {...props} />;
}

export default function MinsideMenu() {
  return (
    <div className="local-nav">
      <List component="nav" aria-label="Shortcuts to settings">
        <ListItemLink href="#user-profile">
          <ListItemIcon>
            <Icon>face</Icon>
          </ListItemIcon>
          <ListItemText primary="Din profil"/>
        </ListItemLink>

        <ListItemLink href="#user-settings">
          <ListItemIcon>
            <Icon>email</Icon>
          </ListItemIcon>
          <ListItemText primary="Nyhetsbrev"/>
        </ListItemLink>


        <ListItemLink href="#user-account">
          <ListItemIcon>
            <Icon>settings</Icon>
          </ListItemIcon>
          <ListItemText primary="Kontoinnstillinger"/>
        </ListItemLink>

      </List>
    </div>
  );
}
