export default function mergeAnswers(existingData, updatedAnswers) {
  updatedAnswers.forEach((answer) =>  {
    const common = existingData.findIndex(item => item.id === answer.id);
    if (common > -1) {
      existingData[common] = answer;
      return;
    }
    existingData.push(answer);
  });
  return existingData;
}