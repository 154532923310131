import React from "react";
import "./form-group.scss";
import FormElement from "../form-element";

export default function FormGroup(props) {
  const { element = {}, checkContentRequirements } = props;
  const { content = [], text = "" } = element;

  function getContent() {
    return checkContentRequirements(content);
  }

  return (
    <div className="form-group">
      <div className="row header-row">
        <div className="col col-page">
          <div className="page-number">
            <span>{element.page}</span>
          </div>
        </div>
        <div className="col form-title">
          <h2>{text}</h2>
        </div>
        <div className="col align-right form-status">
          <span className="page-status">
            {element.page}/{element.groupSize}
          </span>
        </div>
      </div>
      <div className="row content-row">
        <div className="col form-group-line col-page">
          <div className="vertical-line" />
        </div>
        <div className="col form-elements">
          {getContent().map(c => (
            <FormElement element={c} key={c.id} />
          ))}
        </div>
      </div>
    </div>
  );
}
