import http, { createRequest, createUrl } from "../util/http";

const STATS_ENDPOINT = "/statistics";

export default function getStatisticsData() {
  return http(createUrl(STATS_ENDPOINT), createRequest());
}

export function getOrganisationStatisticsData(orgId) {
  return http(createUrl(`${STATS_ENDPOINT}/${orgId}`), createRequest());
}

export function getLifeWillOrganisationStatisticsData(orgId) {
  return http(createUrl(`${STATS_ENDPOINT}/lifeWill/${orgId}`), createRequest());
}

export function getLifeWillStatisticsData() {
  return http(createUrl(STATS_ENDPOINT + "/lifeWill"), createRequest());
}
