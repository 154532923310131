import React from "react";
import { Card, CardActionArea, CardContent, Typography } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import ENUMS from "../../../enums";

export default function TestamentCard({ id, name, createdDate, isNew = false, formType = ENUMS.FORM_TYPE.NORMAL }) {
  const history = useHistory();

  function redirect(id, isNew, formType) {
    let url = "";
    let urlWithId = "";

    switch (formType) {
      case ENUMS.FORM_TYPE.NORMAL:
        url = "/skriv";
        urlWithId = `/testament/${id}`;
        break;
      case ENUMS.FORM_TYPE.RECIPROCAL:
        url = "/gjensidig";
        urlWithId = `/gjensidig-testament/${id}`;
        break;
      case ENUMS.FORM_TYPE.LIFEWILL:
        url = "/skriv-fremtidsfullmakt";
        urlWithId = `/fremtidsfullmakt/${id}`;
        break;
      case ENUMS.FORM_TYPE.RECIPROCAL_LIFEWILL:
        url = "/skriv-gjensidig-fremtidsfullmakt";
        urlWithId = `/gjensidig-fremtidsfullmakt/${id}`;
        break;
      default:
        break;
    }

    history.push(isNew ? url : urlWithId);
  }

  return (
    <Card className={`teaser-testament ${isNew ? "teaser-testament-new" : ""}`} onClick={() => redirect(id, isNew, formType)}>
      <CardActionArea>
        <CardContent>{isNew ? renderNewCard(formType) : renderTestamentContent({ name, createdDate })}</CardContent>
      </CardActionArea>
    </Card>
  );
}

function renderNewCard(formType) {
  let text = "";

  switch (formType) {
    case ENUMS.FORM_TYPE.NORMAL:
      text = "+ Skriv personlig testament";
      break;
    case ENUMS.FORM_TYPE.RECIPROCAL:
      text = "+ Skriv gjensidig testament";
      break;
    case ENUMS.FORM_TYPE.LIFEWILL:
      text = "+ Skriv fremtidsfullmakt";
      break;
    case ENUMS.FORM_TYPE.RECIPROCAL_LIFEWILL:
      text = "+ Skriv gjensidig fremtidsfullmakt";
      break;
    default:
      break;
  }

  return <span className="link">{text}</span>;
}

function renderTestamentContent({ name, createdDate }) {
  const created = new Date(createdDate);
  return (
    <>
      <h2>{name}</h2>
      <Typography variant="body2" color="textSecondary" component="span">
        Opprettet {formatNorwegianDate(created)}
      </Typography>
    </>
  );
}

function formatNorwegianDate(dateObject) {
  const dayNum = dateObject.getDate();
  const monthNum = dateObject.getMonth() + 1;
  const year = dateObject.getFullYear();
  return `${zero(dayNum)}.${zero(monthNum)}.${year}`;
}

function zero(dateNumber) {
  return ("00" + dateNumber).slice(-2);
}
