import React from "react";
import { Tooltip } from "@material-ui/core";

export default function HelpText(props) {
  const { text } = props;

  return (
    <Tooltip title={text} disableFocusListener disableTouchListener >
      <div className="help-text-icon">
        <span>?</span>
      </div>
    </Tooltip>
  );
}
