import React, { useEffect, useState } from 'react';
import { Autocomplete } from '@material-ui/lab';
import { TextField, makeStyles } from '@material-ui/core';
import ENUMS from '../../../enums';

const styles = {
  width: "360px",
  labelWidth: 0,
  backgroundColor: "white"
};

const useStyles = makeStyles((theme) => ({
  root: {
    '& .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"]': { padding: "3px" }
  }
}));

export default function AutoCompleteDropDown({ setStateValues, element: { id, endpoint }, answer = {}, label }) {
  const classes = useStyles();
  const { value: { value } } = answer;
  const [list, setList] = useState(null);
  useEffect(() => loadListData(endpoint), [endpoint]);

  if (!list) {
    return <>Laster data...</>;
  }
  const selectedOption = value ? getOptionById(value?.Id) : null;

  const htmlId = `autocomplete-dropdown-${id}`;
  return (
    <div className="autocomplete-dropdown formControl">
      <label className="questionLabel" htmlFor={htmlId}>
        {label}
      </label>
      <Autocomplete
        id={htmlId}
        options={list}
        value={selectedOption}
        label="flat"
        getOptionLabel={(option) => option.Name}
        style={styles}
        onChange={onChange}
        classes={classes}
        renderInput={(params) => <TextField {...params} placeholder="Søk..." variant="outlined" />}
      />
    </div>
  );

  function onChange(event, option) {
    setStateValues(id, ENUMS.CONTENT_TYPE.AUTOCOMPLETE_DROPDOWN, { id, value: option });
  }

  function getOptionById(id) {
    if (!id) {
      return null;
    }
    return list.find(({ Id: listId }) => listId === id) ?? null;
  }

  function loadListData(url) {
    // Async call to some API
    fetch(url)
      .then((response) => response.json())
      .then((data) => setList(data));
  }
}
