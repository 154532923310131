import mergeAnswers from "../util/merge-answers";

const LOCAL_KEY = 'testmentationdata';

export default function storeAnswers(answers) {
  const { localStorage } = window;
  if (!localStorage) {
    return;
  }
  const existingData = mergeAnswers(get(), answers);
  set([
    ...existingData
  ]);
}

export function set(data, key) {
  const { localStorage } = window;
  if (localStorage) {
    localStorage.setItem(key ? key : LOCAL_KEY, JSON.stringify(data));
  }
}

export function get(key) {
  const { localStorage } = window;
  if (localStorage) {
    const data = localStorage.getItem(key ? key : LOCAL_KEY);
    if (data) {
      return JSON.parse(data);
    }
  }
  return [];
}

export function deleteKey(key) {
  const { localStorage } = window;
  if (localStorage) {
    localStorage.removeItem(key);
  }
}