import React from "react";
import "../../App.scss";
import ENUMS from "../../enums";

export default function TextListElement(props) {
    const { title, text, links, textType, className } = props;

    function insertLinks(text) {
        let newText = text;

        if (links) {
            links.forEach((link) => {
                newText = newText.replace(link.token, `<a href=${link.href} target="_blank" rel="noreferrer noopener">${link.text}</a>`);
            });
        }

        return newText;
    }

    const getListHtml = (text) => {
        if (textType === ENUMS.TEXT_TYPE.ORDEREDLIST) {
            return <ol>
                {text.map(({ id, value }) => value !== "" && getLiHtml(id, value))}
            </ol>
        }
        else {
            return <ul style={textType === ENUMS.TEXT_TYPE.LIST ? { listStyleType: "none" } : null }>
                {text.map(({ id, value }) => value !== "" && getLiHtml(id, value))}
            </ul>
        }
    }

    const getLiHtml = (id, value) => {
        return <li key={id} dangerouslySetInnerHTML={{ __html: insertLinks(value) }} />
    }

    return (
        <div className={"textFormControl " + className}>
            {title && <h3>{title}</h3>}
            {text && getListHtml(text)}
    </div>
    );
}
