import React, { useEffect, useReducer, useState } from "react";
import Header from "../components/header/header";
import { Grid } from "@material-ui/core";
import Profile from "../components/dashboard-elements/profile";
import ProfileContext, { profileReducer, profileState } from "../context/profile-context";
import getProfileData from "../services/user-data-service";
import ENUMS from "../enums";
import TestamentCardList from "../components/dashboard-elements/testament-cards/testament-card-list";
import UserAccount from "../components/dashboard-elements/user-account";
import MinsideMenu from "../components/dashboard-elements/minside-menu";
import Settings from "../components/dashboard-elements/settings";
import LoaderSpinner from "../components/loader/loader";

export default function MinsidePage() {
  const [state, dispatch] = useReducer(profileReducer, profileState);
  const [isLoading, setIsLoading] = useState(true);
  useEffect(initialDataFetch, []); // Empty array makes this call only run on mount

  if (isLoading) {
    return renderLoadingScreen();
  }

  return (
    <main className="dashboard">
      <ProfileContext.Provider value={{ state, dispatch }}>
        <Header externalHome={true} />
        <section className="section-hero">
          <Grid container>
            <Grid item xs={12}>
              <h1>Dine arvedokumenter</h1>
            </Grid>
            <Grid item xs={12}>
              <h3>Testament</h3>
            </Grid>
          </Grid>
          <div className="teasers">
            <TestamentCardList formType={ENUMS.FORM_TYPE.NORMAL} />
          </div>
          <Grid container>
            <Grid item xs={12}>
              <h3>Fremtidsfullmakt</h3>
            </Grid>
          </Grid>
          <div className="teasers">
            <TestamentCardList formType={ENUMS.FORM_TYPE.LIFEWILL} />
          </div>
        </section>

        <section className="section-content">
          <div className="section form">
            <Grid container spacing={0}>
              <Grid item className="hidden-xs">
                <MinsideMenu />
              </Grid>
              <Grid item sm={4} md={1} lg={2} className="hidden-xs" />
              <Grid item xs={12} sm={8} md={6} lg={4}>
                <Profile />
                <Settings />
                <UserAccount />
              </Grid>
            </Grid>
          </div>
        </section>
      </ProfileContext.Provider>
    </main>
  );

  function initialDataFetch() {
    getProfileData().then((profileData) => {
      dispatch({
        type: ENUMS.PROFILE_ACTIONS.UPDATE,
        data: {
          ...profileData,
        },
      });
      setIsLoading(false);
    });
  }
}

function renderLoadingScreen() {
  return (
    <main className="dashboard">
      <Header externalHome={true} />
      <section className="section-hero is-loading">
        <Grid container>
          <Grid item xs={12}>
            <div className="dashboard-loading-data">
              <LoaderSpinner />
            </div>
          </Grid>
        </Grid>
      </section>
    </main>
  );
}
